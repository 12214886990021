import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Link as Link2, useHistory } from 'react-router-dom'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Hidden from '@material-ui/core/Hidden'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputBase from '@material-ui/core/InputBase'
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined'
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined'
import CartItem from './cartItem'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { GET_SHOPCONFIG } from '../graphql/config'
import { useMutation, useQuery } from '@apollo/react-hooks'

import ImageGallery from 'react-image-gallery'

import ReactImageMagnify from 'react-image-magnify'
import 'react-image-gallery/styles/css/image-gallery.css'
import Flickity from 'react-flickity-component'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import HomeIcon from '@material-ui/icons/Home'
import WhatshotIcon from '@material-ui/icons/Whatshot'
import GrainIcon from '@material-ui/icons/Grain'
import Cookies from 'js-cookie'
import { PRODUCT_IMAGES, GET_PRODUCT, GET_PRODUCTS } from '../graphql/product'
import {
  FacebookShareCount,
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  PinterestShareCount,
  WhatsappIcon,
  RedditShareCount,
  TumblrShareCount,
  VKShareCount,
} from 'react-share'
import {
  GET_CARTS,
  INSERT_CART,
  UPDATE_CART,
  REMOVE_CART,
} from '../graphql/cart'
import { GET_CATEGORIES } from '../graphql/category'
import Cart from './cart'
import Menu from './menu'
import MenuCategory from './menuCategory'
import Magnify from '../common/magnify'
import DesktopTopMenu from '../pages/desktoptopmenu'

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const restApi = process.env.REACT_APP_API
const useStyles = makeStyles((theme) => ({
  paperroot: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(2),
    border: '1px solid #b4b4b4',
    marginBottom: theme.spacing(1),
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  productDesc: {
    fontWeight: 'normal',
  },
}))

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.palette.grey[200],
    border: 1,
    borderColor: theme.palette.primary.main,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
  },
}))(InputBase)

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function ShopDetails(props) {
  const [images, setImages] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const { loading: loadingProducts, data: { products } = { products: [] } } =
    useQuery(GET_PRODUCTS)

  const {
    loading: loadingProduct,
    data: { product } = { product: {} },
    refetch: refetchProduct,
  } = useQuery(GET_PRODUCT, { variables: { id: props.match.params.id } })

  const {
    loading: loadingImages,
    data: { productimages } = { productimages: [] },
    refetch: refetchImages,
  } = useQuery(PRODUCT_IMAGES, { variables: { id: props.match.params.id } })

  const { data: { categories } = { categories: [] } } = useQuery(GET_CATEGORIES)

  const {
    loading: loadingCarts,
    data: { carts } = { carts: [] },
    refetch: refetchCart,
  } = useQuery(GET_CARTS, { skip: !Cookies.get('signedin') })

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })
    const latest = data.insertcart
    cache.writeQuery({
      query: GET_CARTS,
      data: { carts: [...old.carts, latest] },
    })
  }

  const [insertCart] = useMutation(INSERT_CART, {
    update: addCache,
  })

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })
    const latest = data.updatecart
    const foundIndex = old.carts.findIndex((item) => item.id === latest.id)
    old.carts.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_CARTS,
      data: { carts: [...old.carts] },
    })
  }

  const [updateCart] = useMutation(UPDATE_CART, { update: updateCache })

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })

    if (data.removecart) {
      const latest = old.carts.filter(
        (item) => item.PriceID != data.removecart.PriceID
      )
      cache.writeQuery({
        query: GET_CARTS,
        data: { carts: [...latest] },
      })
    }
  }

  const [removeCart] = useMutation(REMOVE_CART, { update: deleteCache })

  const [selectedPrice, setSelectedPrice] = useState()

  const classes = useStyles(shopconfig)
  const history = useHistory()

  const [dbhost, setDbhost] = useState('spa1')

  useEffect(() => {
    if (product.ProductPrices)
      setSelectedPrice(product.ProductPrices.split(',')[0].split('|')[2])
  }, [product])

  /*const [dbhost, setDbhost] = useState('shop')*/

  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
    setTimeout(() => {
      setOpen(false)
    }, 5000)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const myRenderItem = (item) => {
    return (
      <div key={Math.random()}>
        <ReactImageMagnify
          {...{
            smallImage: {
              alt: 'Wristwatch by Ted Baker London',
              isFluidWidth: true,
              src: item.original,
            },
            largeImage: {
              src: item.original,
              width: 1000,
              height: 775,
            },
            enlargedImagePortalId: 'zoomed',
          }}
        />
        {/*
         */}
      </div>
    )
  }

  useEffect(() => {
    const arr = []
    productimages.map((a) => {
      arr.push({
        original: `${restApi}/media/${dbhost}/uploads/invoice/${a.FileName}`,
        thumbnail: `${restApi}/media/${dbhost}/uploads/invoice/${a.FileName}`,
      })
    })

    setImages(arr)
  }, [productimages])

  useEffect(() => {
    const totalAmount = carts.reduce((a, b) => a + b.InvoiceAmount, 0)
    setTotalAmount(totalAmount)
  }, [carts])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (!product.id || productimages.length === 0)
    return <React.Fragment></React.Fragment>

  return (
    <React.Fragment>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          icon={<i class="fal fa-cart-plus"></i>}
          onClose={handleClose}
          severity="success"
        >
          Added to Cart!
        </Alert>
      </Snackbar>

      {/* <Container classes={{ root: classes.containerroot }} maxWidth="md">
        <DesktopTopMenu />
      </Container>*/}
      <br />
      <Container classes={{ root: classes.containerroot }} maxWidth="md">
        <Grid container spacing={3}>
          <Hidden only={['sm', 'xs']}>
            <Grid style={{ paddingTop: 20 }} item xs={12} sm={12} md={3}>
              <h3 style={{ marginBottom: 0 }}>Our Products</h3>
              <Divider style={{ marginTop: 20 }} />

              {products.length > 0 && (
                <MenuCategory
                  category={product.Category}
                  categories={products}
                />
              )}
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={12} md={5}>
            <ImageGallery
              disableSwipe={false}
              showThumbnails={false}
              disableThumbnailScroll={false}
              thumbnailPosition="bottom"
              showNav={false}
              showPlayButton={false}
              showFullscreenButton={false}
              useBrowserFullscreen={false}
              items={images}
              /* renderItem={myRenderItem.bind(this)} */
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} style={{ paddingLeft: 20 }}>
            <div
              style={{
                position: 'absolute',
                zIndex: 1000,
                background: 'white',
              }}
              id="zoomed"
            ></div>
            <h2 style={{ marginBottom: 0 }}>{product.ProductName}</h2>

            {/*  {product.Category && (
              <Typography variant="body2" color="textSecondary" component="p">
                {`${product.Category}`}
              </Typography>
            )} */}

            {/* <div
              className={classes.productDesc}
              dangerouslySetInnerHTML={{
                __html: product.ProductDesc,
              }}
            /> */}

            <Divider style={{ marginTop: 20 }} />

            <div style={{ paddingTop: 25, minHeight: 30 }}>
              {product.ProductPrices &&
                product.ProductPrices.split(',').length === 1 &&
                product.ProductPrices.split(',').map((price, i) => {
                  const priceitem = price.split('|')
                  return (
                    <div key={i}>
                      <span
                        style={{
                          fontWeight: 'bold',
                          fontSize: 17,
                          display: 'inline-block',
                          paddingRight: 10,
                        }}
                      >
                        {priceitem[0]}
                      </span>
                    </div>
                  )
                })}

              {selectedPrice &&
                product.ProductPrices &&
                product.ProductPrices.split(',').length > 1 && (
                  <React.Fragment>
                    <Select
                      defaultValue={selectedPrice}
                      value={selectedPrice}
                      onChange={(e) => {
                        setSelectedPrice(e.target.value)
                      }}
                      input={<BootstrapInput />}
                    >
                      {product.ProductPrices.split(',').map((price, i) => {
                        const priceitem = price.split('|')
                        return (
                          <MenuItem key={i} value={priceitem[2]}>
                            {priceitem[3]} - {priceitem[0]}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </React.Fragment>
                )}
            </div>

            {/* {product.UnitsInStock > 10 && (
              <div
                style={{ marginTop: 15, color: '#757575' }}
              >{`${product.UnitsInStock} piece available`}</div>
            )} */}

            {/* {product.UnitsInStock < 11 && product.UnitsInStock > 0 && (
              <div
                style={{ marginTop: 15, color: 'red' }}
              >{`Only ${product.UnitsInStock} piece left`}</div>
            )} */}

            {product.UnitsInStock == 0 && (
              <div
                style={{ marginTop: 15, color: 'red' }}
              >{`Out of stock`}</div>
            )}

            <div style={{ marginTop: 25 }}>
              {product.UnitsInStock > 0 &&
                !carts.find(
                  (a) => a.ProductID == product.id && a.PriceID == selectedPrice
                ) && (
                  <React.Fragment>
                    <Button
                      disableElevation
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => {
                        if (!Cookies.get('signedin')) {
                          history.push('/login')
                          return
                        }
                        insertCart({
                          variables: {
                            ProductID: product.id,
                            PriceID: selectedPrice,
                            Qty: 1,
                          },
                        })

                        handleClick()
                      }}
                    >
                      Add to cart
                    </Button>
                    <Button
                      variant="outlined"
                      size="large"
                      color="primary"
                      style={{ minWidth: 0, marginLeft: 10 }}
                      onClick={() => {
                        history.push('/checkout')
                      }}
                    >
                      View Cart
                    </Button>
                  </React.Fragment>
                )}
              {carts.find(
                (a) => a.ProductID == product.id && a.PriceID == selectedPrice
              ) && (
                <React.Fragment>
                  {product.UnitsInStock >
                    carts.find(
                      (a) =>
                        a.ProductID == product.id && a.PriceID == selectedPrice
                    ).Qty && (
                    <Button
                      variant="contained"
                      size="small"
                      disableElevation
                      color="primary"
                      style={{ minWidth: 0, marginRight: 10 }}
                      onClick={() => {
                        if (!Cookies.get('signedin')) {
                          history.push('/login')
                          return
                        }
                        updateCart({
                          variables: {
                            ProductID: product.id,
                            PriceID: selectedPrice,
                            Qty:
                              carts.find(
                                (a) =>
                                  a.ProductID == product.id &&
                                  a.PriceID == selectedPrice
                              ).Qty + 1,
                          },
                        })
                      }}
                    >
                      <AddIcon />
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    size="small"
                    disableElevation
                    color="primary"
                    style={{ minWidth: 0 }}
                    onClick={() => {
                      if (!Cookies.get('signedin')) {
                        history.push('/login')
                        return
                      }
                      if (
                        carts.find(
                          (a) =>
                            a.ProductID == product.id &&
                            a.PriceID == selectedPrice
                        ).Qty > 1
                      ) {
                        updateCart({
                          variables: {
                            ProductID: product.id,
                            PriceID: selectedPrice,
                            Qty:
                              carts.find(
                                (a) =>
                                  a.ProductID == product.id &&
                                  a.PriceID == selectedPrice
                              ).Qty - 1,
                          },
                        })
                      } else {
                        setTimeout(() => {
                          removeCart({
                            variables: {
                              id: product.id,
                              PriceID: selectedPrice,
                            },
                          })
                        }, 1)
                      }
                    }}
                  >
                    <RemoveIcon />
                  </Button>
                </React.Fragment>
              )}
            </div>

            <div
              style={{
                minHeight: carts.find((a) => a.ProductID == product.id)
                  ? 38
                  : 40,
                textAlign: 'left',
              }}
            >
              {carts.find(
                (a) => a.ProductID == product.id && a.PriceID == selectedPrice
              ) && (
                <h3
                  style={{
                    margin: 0,
                    paddingTop: 10,
                    color: shopconfig.COLORPRIMARY,
                  }}
                >
                  {
                    carts.find(
                      (a) =>
                        a.ProductID == product.id && a.PriceID == selectedPrice
                    ).Qty
                  }{' '}
                  in cart
                </h3>
              )}
            </div>

            {carts.find(
              (a) => a.ProductID == product.id && a.PriceID == selectedPrice
            ) && (
              <div style={{ marginTop: 10 }}>
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  style={{ minWidth: 0 }}
                  onClick={() => {
                    history.push('/checkout')
                  }}
                >
                  View Cart
                </Button>
              </div>
            )}

            <div style={{ marginTop: 20 }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: product.Youtube,
                }}
              />
            </div>

            {/* <div style={{ marginTop: 20 }}>
              <FacebookShareButton
                url={window.location.href}
                quote={product.ProductName}
              >
                <FacebookIcon size={32} round={false} />
              </FacebookShareButton>{' '}
              <WhatsappShareButton
                url={window.location.href}
                quote={product.ProductName}
              >
                <WhatsappIcon size={32} round={false} />
              </WhatsappShareButton>
            </div> */}
          </Grid>
        </Grid>

        <Divider style={{ marginTop: 20 }} />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <div
              className={classes.productDesc}
              dangerouslySetInnerHTML={{
                __html: product.ProductDesc,
              }}
            />
          </Grid>
          <Hidden only={['md', 'lg', 'xl']}>
            <Grid style={{ paddingTop: 20 }} item xs={12} sm={12} md={3}>
              <h3 style={{ marginBottom: 0 }}>Our Products</h3>
              <Divider style={{ marginTop: 20 }} />

              {products.length > 0 && (
                <MenuCategory
                  category={product.Category}
                  categories={products}
                />
              )}
            </Grid>
          </Hidden>
        </Grid>
        <br />
        <br />
        <br />
      </Container>
    </React.Fragment>
  )
}
