import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import { Link as Link2, useHistory } from 'react-router-dom'

const restApi = process.env.REACT_APP_API

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      '& > *': {
        margin: theme.spacing(4),
      },
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: 95,
    height: 95,
    [theme.breakpoints.up('md')]: {
      width: 350,
      height: 350,
    },
  },
}))

export default function SlideCircle(props) {
  const classes = useStyles()

  const [dbhost, setDbhost] = useState('spa1')

  return (
    <div className={classes.root}>
      {props.products.map(
        (product, i) =>
          product.Category == 2 && (
            <Link2
              style={{ textDecoration: 'none' }}
              to={`/service/${product.id}/${product.PrettyUrl}`}
            >
              <Avatar
                key={i}
                alt={product.ProductName}
                src={`${restApi}/media/${dbhost}/uploads/invoice/${product.ProductImage}`}
                className={classes.large}
              />
            </Link2>
          )
      )}
      <Avatar
        alt="Remy Sharp"
        src="/images/ezurose/02.jpg"
        className={classes.large}
      />
      <Avatar
        alt="Remy Sharp"
        src="/images/ezurose/03.jpg"
        className={classes.large}
      />
    </div>
  )
}
