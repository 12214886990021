import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import { Link as Link2, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/react-hooks'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Hidden from '@material-ui/core/Hidden'
import Container from '@material-ui/core/Container'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Link from '@material-ui/core/Link'
import Moment from 'moment-timezone'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import SingleTitle from '../common/singleTitle'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import Fade from '@material-ui/core/Fade'
import {
  GET_HEALTHREPORTS,
  GET_HEALTHREPORTDETAILS,
} from '../graphql/healthreport'

import ReportList from './reportList'
import DesktopTopMenu from './desktoptopmenu'

const restApi = process.env.REACT_APP_API

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  root: {
    padding: 20,
  },
  chiproot: {
    display: 'flex',
    justifyContent: 'left',
    paddingLeft: 20,
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  menuitem: {
    textAlign: 'left',
    display: 'block',
  },
}))

export default function Booking(props) {
  const classes = useStyles()
  const history = useHistory()

  const {
    data: { healthreports } = { healthreports: [] },
    refetch: refetchReports,
  } = useQuery(GET_HEALTHREPORTS)

  const {
    data: { healthreportdetails } = { healthreportdetails: [] },
    refetch: refetchReportsDetails,
  } = useQuery(GET_HEALTHREPORTDETAILS)

  useEffect(() => {
    if (healthreports.length > 0) {
      refetchReportsDetails({ id: healthreports[0].id })
    }
  }, [healthreports])

  useEffect(() => {
    if (!Cookies.get('signedin')) {
      history.push('/login')
    }
  }, [props])

  return (
    <React.Fragment>
      {/* <Hidden only={['sm', 'xs']}> */}
      <div>
        <img src="/images/report/1.jpg" style={{ width: '100%' }} />
      </div>
      <div>
        <Container maxWidth="md">
          {/* <DesktopTopMenu /> */}
          <div>
            <Grid container direction="row" alignItems="center">
              <Grid item style={{ textAlign: 'center' }} xs={12} md={12}>
                <h2>My Health</h2>
              </Grid>
              <Grid item style={{ textAlign: 'center' }} xs={12} md={12}>
                <h3>Testing Time</h3>
                {healthreportdetails && healthreportdetails.length > 0 && (
                  <h3>
                    {Moment(healthreportdetails[0].TestingTime)
                      .tz('UTC')
                      .format('L')}{' '}
                    {Moment(healthreportdetails[0].TestingTime)
                      .tz('UTC')
                      .format('LT')}
                  </h3>
                )}
              </Grid>
            </Grid>

            {/* <pre>{JSON.stringify(healthreportdetails, 0, 4)}</pre> */}
            <Grid
              style={{ fontWeight: 'bold', fontSize: 14 }}
              container
              spacing={2}
            >
              <Grid item xs={12} md={3}>
                <Grid
                  style={{ fontWeight: 'bold', fontSize: 14 }}
                  container
                  spacing={2}
                >
                  <Grid item xs={12} md={12}>
                    Testing Date & Time
                  </Grid>
                  {/* 
                <Grid item xs={6} md={6}></Grid> */}
                </Grid>

                <Divider style={{ marginBottom: 10, marginTop: 10 }} />

                {healthreports.map((report) => (
                  <Grid container spacing={2}>
                    {/* <Grid item xs={2} md={2}>
                      <Link
                        style={{
                          color: '#00acee',
                          fontWeight: 'bold',
                          fontSize: 15,
                          textDecoration: 'none',
                        }}
                        to={`/bookings/detail/${report.id}`}
                      >
                        {report.id}
                      </Link>
                    </Grid> */}
                    <Grid item xs={12} md={12}>
                      <Link
                        style={{ cursor: 'pointer', fontWeight: 'bold' }}
                        onClick={() => {
                          refetchReportsDetails({ id: report.id })
                        }}
                      >
                        {Moment(report.TestingTime).tz('UTC').format('L')}{' '}
                        {Moment(report.TestingTime).tz('UTC').format('LT')}
                      </Link>
                    </Grid>
                    {/*  <Grid item xs={6} md={6}></Grid> */}
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12} md={9}>
                <ReportList data={healthreportdetails} />
              </Grid>
            </Grid>
            <div style={{ marginTop: 50 }}>
              <br />
              <br />
            </div>
          </div>
        </Container>
      </div>
      {/* 
      </Hidden>

      <Hidden only={['md', 'lg', 'xl']}>
        <SingleTitle title="My Health" />
        <ReportList data={healthreportdetails} />
      </Hidden> */}
    </React.Fragment>
  )
}
