import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Link as Link2, useHistory } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined'
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined'
import CartItem from './cartItem'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputBase from '@material-ui/core/InputBase'

import ImageGallery from 'react-image-gallery'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import HomeIcon from '@material-ui/icons/Home'
import WhatshotIcon from '@material-ui/icons/Whatshot'
import GrainIcon from '@material-ui/icons/Grain'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'

import Cookies from 'js-cookie'
import {
  GET_CARTS,
  INSERT_CART,
  UPDATE_CART,
  REMOVE_CART,
} from '../graphql/cart'
import { PRODUCT_IMAGES } from '../graphql/product'
import { GET_SHOPCONFIG } from '../graphql/config'
import { useMutation, useQuery } from '@apollo/react-hooks'

const restApi = process.env.REACT_APP_API
const useStyles = makeStyles((theme) => ({
  paperroot: {
    padding: theme.spacing(2),
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  productDesc: {
    fontWeight: 'normal',
  },
  addRoot: {
    minWidth: 0,
    marginTop: 3,
    marginRight: 5,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 20,
    boxShadow: 'none',
  },
  paddingleft: {
    padding: 8,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
    },
  },
  iconbuttonroot: {
    padding: 0,
  },
}))

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.palette.grey[200],
    border: 1,
    borderColor: theme.palette.primary.main,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
  },
}))(InputBase)

export default function ShopDetails(props) {
  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const classes = useStyles(shopconfig)
  const history = useHistory()

  const [dbhost, setDbhost] = useState('spa1')
  /*const [dbhost, setDbhost] = useState('shop')*/

  const [selectedPrice, setSelectedPrice] = useState(
    props.data.ProductPrices.split(',')[0].split('|')[2]
  )
  const [images, setImages] = useState([])

  const {
    loading: loadingImages,
    data: { productimages } = { productimages: [] },
    refetch: refetchImages,
  } = useQuery(PRODUCT_IMAGES, { variables: { id: props.data.ProductID } })

  const {
    loading: loadingCarts,
    data: { carts } = { carts: [] },
    refetch: refetchCart,
  } = useQuery(GET_CARTS, { skip: !Cookies.get('signedin') })

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })
    const latest = data.insertcart
    cache.writeQuery({
      query: GET_CARTS,
      data: { carts: [...old.carts, latest] },
    })
  }

  const [insertCart] = useMutation(INSERT_CART, {
    update: addCache,
  })

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })
    const latest = data.updatecart
    const foundIndex = old.carts.findIndex((item) => item.id === latest.id)
    old.carts.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_CARTS,
      data: { carts: [...old.carts] },
    })
  }

  const [updateCart] = useMutation(UPDATE_CART, { update: updateCache })

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })

    if (data.removecart) {
      const latest = old.carts.filter(
        (item) => item.PriceID != data.removecart.PriceID
      )
      cache.writeQuery({
        query: GET_CARTS,
        data: { carts: [...latest] },
      })
    }

    props.setSelectedProductID()
  }

  const [removeCart] = useMutation(REMOVE_CART, { update: deleteCache })

  useEffect(() => {
    const arr = []
    productimages.map((a) => {
      arr.push({
        original: `${restApi}/media/${dbhost}/uploads/invoice/${a.FileName}`,
        thumbnail: `${restApi}/media/${dbhost}/uploads/invoice/${a.FileName}`,
      })
    })

    console.log(arr)

    setImages(arr)
  }, [productimages])

  if (!props.data.ProductID) return <div>no item</div>

  return (
    <React.Fragment>
      {/* <Breadcrumbs aria-label="breadcrumb">
        <Typography color="textPrimary">
          <HomeIcon className={classes.icon} />
        </Typography>
        <Typography color="textPrimary">{props.data.Category}</Typography>
      </Breadcrumbs> */}
      <div classes={{ root: classes.paperroot }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={5}>
            <ImageGallery
              showPlayButton={false}
              showFullscreenButton={true}
              useBrowserFullscreen={false}
              items={images}
            />
            {/* 
            <img
              style={{ width: '100%' }}
              src={`${restApi}/${dbhost}/uploads/media/invoice/${props.data.ProductImage}`}
            /> */}
          </Grid>
          <Grid item xs={12} sm={12} md={7} style={{ padding: 20 }}>
            {/* {props.data.UnitsInStock > 10 && (
              <div
                style={{ textAlign: 'center', marginTop: 0, color: '#757575' }}
              >{`${props.data.UnitsInStock} piece available`}</div>
            )}

            {props.data.UnitsInStock < 11 && props.data.UnitsInStock > 0 && (
              <div
                style={{ textAlign: 'center', marginTop: 0, color: 'red' }}
              >{`Only ${props.data.UnitsInStock} piece left`}</div>
            )}

            {props.data.UnitsInStock == 0 && (
              <div
                style={{ textAlign: 'center', marginTop: 0, color: 'red' }}
              >{`No stock`}</div>
            )}
 */}
            <div
              style={{
                textAlign: 'center',
                marginTop: 10,
                marginBottom: 15,
                paddingRight: 5,
                paddingBottom: 0,
              }}
            >
              {props.data.UnitsInStock > 0 &&
                !carts.find(
                  (a) =>
                    a.ProductID == props.data.ProductID &&
                    a.PriceID == selectedPrice
                ) && (
                  <Button
                    disableElevation
                    variant="contained"
                    disableElevation
                    size="dense"
                    color="primary"
                    style={{
                      marginRight: 0,
                    }}
                    classes={{ root: classes.addRoot }}
                    onClick={() => {
                      if (!Cookies.get('signedin')) {
                        history.push('/login')
                        return
                      }
                      props.setSelectedProductID(props.data.ProductID)
                      insertCart({
                        variables: {
                          ProductID: props.data.ProductID,
                          PriceID: selectedPrice,
                          Qty: 1,
                        },
                      })
                    }}
                  >
                    Add to cart
                  </Button>
                )}
              {carts.find(
                (a) =>
                  a.ProductID == props.data.ProductID &&
                  a.PriceID == selectedPrice
              ) && (
                <React.Fragment>
                  <IconButton
                    size="medium"
                    edge={false}
                    classes={{
                      root: classes.iconbuttonroot,
                    }}
                    onClick={() => {
                      if (!Cookies.get('signedin')) {
                        history.push('/login')
                        return
                      }
                      if (
                        carts.find(
                          (a) =>
                            a.ProductID == props.data.ProductID &&
                            a.PriceID == selectedPrice
                        ).Qty > 1
                      ) {
                        updateCart({
                          variables: {
                            ProductID: props.data.ProductID,
                            PriceID: selectedPrice,
                            Qty:
                              carts.find(
                                (a) =>
                                  a.ProductID == props.data.ProductID &&
                                  a.PriceID == selectedPrice
                              ).Qty - 1,
                          },
                        })
                      } else {
                        props.setSelectedProductID(props.data.ProductID)
                        setTimeout(() => {
                          removeCart({
                            variables: {
                              id: props.data.ProductID,
                              PriceID: selectedPrice,
                            },
                          })
                        }, 1)
                      }
                    }}
                  >
                    <RemoveCircleOutlineIcon
                      style={{
                        fontSize: 32,
                        color: shopconfig.COLORPRIMARY,
                      }}
                    />
                  </IconButton>

                  <span
                    style={{
                      width: 25,
                      textAlign: 'center',
                      display: 'inline-block',
                      fontSize: 16,
                      fontWeight: 'bold',
                    }}
                  >
                    {
                      carts.find(
                        (a) =>
                          a.ProductID == props.data.ProductID &&
                          a.PriceID == selectedPrice
                      ).Qty
                    }
                  </span>
                  {props.data.UnitsInStock >
                    carts.find(
                      (a) =>
                        a.ProductID == props.data.ProductID &&
                        a.PriceID == selectedPrice
                    ).Qty && (
                    <IconButton
                      size="medium"
                      edge={false}
                      classes={{
                        root: classes.iconbuttonroot,
                      }}
                      onClick={() => {
                        if (!Cookies.get('signedin')) {
                          history.push('/login')
                          return
                        }
                        updateCart({
                          variables: {
                            ProductID: props.data.ProductID,
                            PriceID: selectedPrice,
                            Qty:
                              carts.find(
                                (a) =>
                                  a.ProductID == props.data.ProductID &&
                                  a.PriceID == selectedPrice
                              ).Qty + 1,
                          },
                        })
                      }}
                    >
                      <AddCircleIcon
                        style={{
                          fontSize: 32,
                          color: shopconfig.COLORPRIMARY,
                        }}
                      />
                    </IconButton>
                  )}
                </React.Fragment>
              )}
            </div>

            <h2 style={{ marginTop: 0, marginBottom: 0 }}>
              {props.data.ProductName}
            </h2>

            {/* {props.data.Category && (
              <Typography variant="body2" color="textSecondary" component="p">
                {`${props.data.Category}`}
              </Typography>
            )} */}

            <div style={{ paddingTop: 5, minHeight: 30 }}>
              {props.data.ProductPrices &&
                props.data.ProductPrices.split(',').length === 1 &&
                props.data.ProductPrices.split(',').map((price, i) => {
                  const priceitem = price.split('|')
                  return (
                    <div key={i}>
                      <span
                        style={{
                          fontWeight: 'bold',
                          fontSize: 16,
                          display: 'inline-block',
                          paddingRight: 10,
                        }}
                      >
                        {priceitem[0]}
                      </span>
                    </div>
                  )
                })}

              {props.data.ProductPrices &&
                props.data.ProductPrices.split(',').length > 1 && (
                  <React.Fragment>
                    <Select
                      defaultValue={selectedPrice}
                      value={selectedPrice}
                      onChange={(e) => {
                        setSelectedPrice(e.target.value)
                      }}
                      input={<BootstrapInput />}
                    >
                      {props.data.ProductPrices.split(',').map((price, i) => {
                        const priceitem = price.split('|')
                        return (
                          <MenuItem key={i} value={priceitem[2]}>
                            {priceitem[3]} - {priceitem[0]}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </React.Fragment>
                )}
            </div>
            <div
              className={classes.productDesc}
              dangerouslySetInnerHTML={{ __html: props.data.ProductDesc }}
            />

            {/* <div
              style={{
                minHeight: carts.find(
                  (a) => a.ProductID == props.data.ProductID
                )
                  ? 38
                  : 40,
                textAlign: 'left',
              }}
            >
              {carts.find((a) => a.ProductID == props.data.ProductID) && (
                <h3
                  style={{
                    margin: 0,
                    paddingTop: 10,
                    color: shopconfig.COLORPRIMARY,
                  }}
                >
                  {carts.find((a) => a.ProductID == props.data.ProductID).Qty}{' '}
                  in trolley
                </h3>
              )}
            </div> */}
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}
