import { gql } from 'apollo-boost'

export const GET_BRANCHES = gql`
  {
    branches {
      BranchName
      BranchCode
      Address1
      Address2
      Address3
      City
      State
      Postcode
      Country
      GroupID
    }
  }
`

export const GET_BRANCH = gql`
  {
    branch {
      BranchName
      BranchCode
      Address1
      Address2
      Address3
      City
      State
      Postcode
      Country
      GroupID
    }
  }
`

export const GET_GROUPBRANCHES = gql`
  {
    groupbranches {
      BranchName
      BranchCode
    }
  }
`
