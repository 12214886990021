import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'

import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'

import { useMutation, useQuery } from '@apollo/react-hooks'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import IconButton from '@material-ui/core/IconButton'
import { SUBSCRIBEMAIL } from '../graphql/staff'
import { useSnackbar } from 'notistack'

const restApi = process.env.REACT_APP_API

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  listRoot: {
    width: '100%',
    maxWidth: '36ch',
    padding: 0,
    overflowY: 'scroll',
    flexGrow: 1,
    backgroundColor: '#ebebeb',
  },
  media: {
    height: 150,
    margin: theme.spacing(1),
  },
  card: {},
  buttonRoot: {
    minWidth: 0,
    marginRight: 5,
    padding: 7,
  },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  containerroot: {
    paddingTop: 20,
    paddingBottom: 50,
    paddingLeft: 0,
    paddingRight: 0,
  },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  rootBootstrapHelpertext: {
    color: 'white',
    marginTop: theme.spacing(2),
  },
  inputBootstrap: {
    borderRadius: 6,
    color: 'white',
    position: 'relative',
    backgroundColor: 'transparent',
    border: '3px solid #ced4da',
    width: '100%',
    padding: '13px 10px 10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderColor: (props) => `${props.COLORPRIMARY}`,
    },
  },
}))

export default function BrandStory(props) {
  const classes = useStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [subEmail, setSubEmail] = useState()
  const addCache = (cache, { data }) => {
    if (data.subscribemail?.Error) {
      enqueueSnackbar(data.subscribemail?.Error, {
        variant: 'error',
      })
    } else {
      enqueueSnackbar('Added to Subscription', {
        variant: 'success',
      })
    }

    setSubEmail('')
  }

  const [subscribeMail] = useMutation(SUBSCRIBEMAIL, {
    update: addCache,
  })

  return (
    <React.Fragment>
      <div>
        <img src="/images/about/bg/1.jpg" style={{ width: '100%' }} />
      </div>
      <div
        style={{
          background: 'url(/images/bg/brandstory.jpg) no-repeat bottom  right',
          backgroundSize: 'cover',
          minHeight: 150,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h1>Brand Story</h1>
      </div>

      <div
        style={{
          background: 'url(/images/about/bg/2.bg.jpg) no-repeat bottom  right',
          backgroundSize: 'cover',
          color: 'black',
        }}
      >
        <Container maxWidth="md">
          <Grid
            container
            spacing={2}
            style={{
              minHeight: 700,
            }}
          >
            <Grid item xs={12} md={6} style={{ display: 'flex' }}>
              <img
                src="/images/about/bg/2.human.png"
                style={{
                  width: '100%',
                  alignSelf: 'flex-end',
                  marginTop: 'auto',
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div>
                <h1>Nature Energy</h1>
                <p>
                  Sunshine, air and water are the three vital elements for human
                  survival. With the life-force given to us by cosmic energy,
                  the human body itself can be regarded as an independant,
                  self-contained "universe".
                </p>
                <p>
                  Due to its infinite size, it's inevitable for energy variances
                  to occur in the universe. However, the human body is finite,
                  and therefore, any changes in energy balance (whether
                  excessive or insufficient) can easily lead to diseases.
                </p>
                <p>
                  Similarly, for the human body, the crucial balance lies in the
                  amount of energy it contains.
                </p>

                <Hidden only={['lg', 'xl', 'md']}>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </Hidden>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          background: 'url(/images/about/bg/3bg.png) ',
          backgroundSize: 'cover',
          color: 'white',
          paddingTop: 50,
        }}
      >
        <h1
          style={{
            fontWeight: 'normal',
            textTransform: 'uppercase',
            textAlign: 'center',
            marginBottom: 0,
            marginTop: 0,
          }}
        >
          Derived from the concept of "Energy Balance",
        </h1>
        <h1
          style={{
            fontWeight: 'normal',
            textAlign: 'center',
            marginTop: 0,
            paddingBottom: 50,
          }}
        >
          The World's First <b>LIGHT ACTIVATED ENERGY</b>
        </h1>
        <Container maxWidth="md">
          <Grid container spacing={0} style={{}}>
            <Grid item xs={12} md={5} style={{ position: 'relative' }}>
              <img
                src="/images/about/bg/3a.jpg"
                style={{
                  width: '100%',
                  alignSelf: 'flex-end',
                  marginTop: 'auto',
                  height: '100%',
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: 20,
                  width: '100%',
                  height: '100%',
                }}
              >
                <div
                  style={{
                    display: 'block',
                    paddingLeft: 20,
                    paddingRight: 20,
                    backgroundColor: '#ddd',
                  }}
                >
                  <h1
                    style={{
                      textAlign: 'center',
                      color: 'white',
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    Core Technology
                  </h1>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              style={{
                background: 'url(/images/about/bg/3b.jpg) ',
                backgroundSize: 'cover',
                display: 'flex',
                alignItems: 'right',
                flexDirection: 'column',
                minHeight: 390,
              }}
            >
              <h2
                style={{
                  textAlign: 'right',
                  color: '#666',
                  paddingTop: 50,
                  paddingRight: 50,
                  margin: 0,
                }}
              >
                Cosmic Energy, The Source Of Life
              </h2>
            </Grid>
          </Grid>
          <p style={{ textAlign: 'center', paddingTop: 30, paddingBottom: 50 }}>
            It is the route we pursue towards a pure and healthy lifestyle and
            attitude. An inspiration from the mother nature, has been developed
            to now known as Light Activated Energy, which has the ability to
            improve our blood circulation. Experience 4.5 billion years of light
            together with the mother nature. Energy, harmony as well as
            prosperity, all given in one.
            <br />
            <br />
            936 wholeheartedly protects your health.
          </p>
        </Container>
      </div>

      <div
        style={{
          background: 'url(/images/about/bg/4bg.jpg) ',
          backgroundSize: 'cover',
          color: 'white',
          marginTop: '-20px',
          paddingTop: 50,
          paddingBottom: 150,
        }}
      >
        <h1
          style={{
            fontWeight: 'normal',
            textAlign: 'center',
            marginBottom: 0,
          }}
        >
          936 Wellness Scientific
          <br />
          Full-fledged patents and accreditations
          <br />
          on LAE Technology
        </h1>
        <Container maxWidth="md">
          <Grid container spacing={0} style={{}}>
            <Grid item xs={12} md={12}>
              <img
                src="/images/about/bg/4.png"
                style={{
                  width: '100%',
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0} style={{ marginTop: 50 }}>
            <Grid
              item
              xs={12}
              md={1}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            ></Grid>
            <Grid
              item
              xs={12}
              md={2}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <img
                src="/images/about/flag/flag/us.svg"
                style={{
                  maxWidth: 60,
                }}
              />
              <h3 style={{ fontWeight: 'normal' }}>US Patent</h3>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <img
                src="/images/about/flag/flag/japan.png"
                style={{
                  maxWidth: 60,
                }}
              />
              <h3 style={{ fontWeight: 'normal' }}>JAPAN Patent</h3>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <img
                src="/images/about/flag/flag/malaysia.jpg"
                style={{
                  maxWidth: 60,
                }}
              />
              <h3 style={{ fontWeight: 'normal' }}>MALAYSIA Patent</h3>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <img
                src="/images/about/flag/flag/taiwan.png"
                style={{
                  maxWidth: 60,
                }}
              />
              <h3 style={{ fontWeight: 'normal' }}>TAIWAN Patent</h3>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <img
                src="/images/about/flag/flag/china.jpg"
                style={{
                  maxWidth: 60,
                }}
              />
              <h3 style={{ fontWeight: 'normal' }}>CHINA Patent</h3>
            </Grid>
            <Grid
              item
              xs={12}
              md={1}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            ></Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          background: 'url(/images/about/bg/5bg.jpg) no-repeat bottom  right',
          backgroundSize: 'cover',
          color: 'white',
          paddingBottom: 100,
        }}
      >
        <Container maxWidth="md">
          <Grid
            container
            spacing={2}
            style={{
              minHeight: 700,
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div>
                <h1>
                  Enjoy the safe and hygienic
                  <br />
                  in 936 LAE Energy Room
                </h1>
                <h3 style={{ marginTop: 50, marginBottom: 0 }}>
                  Mechanism that destroys viruses.
                </h3>
                <img
                  src="/images/about/bg/5room.png"
                  style={{
                    width: '100%',
                    alignSelf: 'flex-end',
                    marginTop: 'auto',
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ display: 'flex' }}>
              <img
                src="/images/about/bg/5info.png"
                style={{
                  width: '100%',
                  alignSelf: 'flex-end',
                  marginTop: 'auto',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          background: 'url(/images/about/bg/6bg.jpg) no-repeat bottom  right',
          backgroundSize: 'cover',
          color: 'white',
          paddingBottom: 100,
          paddingTop: 50,
        }}
      >
        <h1
          style={{
            width: '100%',
            fontWeight: 'normal',
            textAlign: 'center',
            marginBottom: 0,
            marginTop: 0,
          }}
        >
          The safety and responsibility
          <br />
          of the products of 936
        </h1>
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} style={{ display: 'flex' }}>
              <img
                src="/images/about/bg/6patent.png"
                style={{
                  width: '100%',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
      <div
        style={{
          background: 'url(/images/bg/8.jpg) no-repeat center center',
          backgroundSize: 'cover',
          minHeight: 700,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Container classes={{ root: classes.containerroot }} maxWidth="md">
          <Grid style={{ marginTop: 0 }} container spacing={10}>
            <Grid item xs={12} style={{ textAlign: 'center' }} md={6}>
              <Hidden only={['sm', 'xs']}>
                <img
                  src="/images/icon/wellnessscientific.png"
                  style={{ maxHeight: 400 }}
                />
              </Hidden>

              <Hidden only={['md', 'lg', 'xl']}>
                <img
                  src="/images/icon/wellnessscientific.png"
                  style={{ maxHeight: 300 }}
                />
              </Hidden>

              <Grid style={{ marginTop: 0 }} container spacing={0}>
                <Grid item xs={12} md={4}>
                  <h3 style={{ marginTop: 0, color: 'white' }}>
                    Detoxification
                  </h3>
                </Grid>
                <Grid item xs={12} md={4}>
                  <h3 style={{ marginTop: 0, color: 'white' }}>
                    Deep Relaxation
                  </h3>
                </Grid>
                <Grid item xs={12} md={4}>
                  <h3 style={{ marginTop: 0, color: 'white' }}>Healthy Life</h3>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
              <div style={{ padding: 20 }}>
                <img
                  src="/images/icon/logowhite.png"
                  style={{ maxHeight: 100 }}
                />
                <h3
                  style={{
                    marginTop: 50,
                    letterSpacing: 10,
                    textTransform: 'uppercase',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  Subscribe us today
                </h3>
                <h1
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  Stay Up to Date
                </h1>

                <br />
                <br />
                <FormControl fullWidth>
                  <InputLabel shrink htmlFor="SubscribeEmail"></InputLabel>
                  <InputBase
                    classes={{
                      root: classes.rootBootstrap,
                      input: classes.inputBootstrap,
                    }}
                    id="SubscribeEmail"
                    name="SubscribeEmail"
                    label="SubscribeEmail"
                    fullWidth
                    value={subEmail}
                    margin="dense"
                    onChange={(e) => {
                      setSubEmail(e.target.value)
                    }}
                    autoComplete="off"
                    inputProps={{
                      maxLength: 200,
                      type: 'email',
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        {' '}
                        <IconButton
                          style={{
                            position: 'absolute',
                            right: 0,
                            marginRight: 10,
                          }}
                          aria-label="toggle password visibility"
                          onClick={() => {
                            const re =
                              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            if (re.test(subEmail)) {
                              subscribeMail({
                                variables: {
                                  Email: subEmail,
                                },
                              })
                            } else {
                              enqueueSnackbar(
                                'Please provide a valid email address',
                                {
                                  variant: 'error',
                                }
                              )
                            }
                          }}
                          /* onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword} */
                          edge="end"
                        >
                          <ArrowForwardIcon style={{ color: 'white' }} />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText
                    classes={{
                      root: classes.rootBootstrapHelpertext,
                    }}
                  >
                    <span>Get the latest news directly to your inbox</span>
                  </FormHelperText>
                </FormControl>
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  )
}
