import { gql } from 'apollo-boost'

export const GET_STAFFS = gql`
  {
    staffs {
      id
      StaffID
      StaffName
      Department
      Error
    }
  }
`

export const FORGETPASSWORD = gql`
  mutation ForgetPassword($Username: String, $Password: String, $Key: String) {
    forgetpassword(Username: $Username, Password: $Password, Key: $Key) {
      id
      StaffID
      StaffName
      Department
      Error
    }
  }
`

export const SUBSCRIBEMAIL = gql`
  mutation SubscribeMail($Email: String, $Active: String) {
    subscribemail(Email: $Email, Active: $Active) {
      id
      Email
      Active
      Error
    }
  }
`
