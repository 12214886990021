import React, { useState } from 'react'
import { Link as Link2, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button } from '@material-ui/core/'

import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import { GET_CARTS } from '../graphql/cart'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useEffect } from 'react'
import { GET_BUYER } from '../graphql/buyer'
import { GET_SHOPCONFIG } from '../graphql/config'
import Cookies from 'js-cookie'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import EditIcon from '@material-ui/icons/Edit'
import TrolleyItem from './trolleyItem'

const restApi = process.env.REACT_APP_API

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  paperroot: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    border: (props) => `1px solid ${props.COLORPRIMARY}`,
    borderBottom: (props) => `5px solid ${props.COLORPRIMARY}`,
  },
  summaryRoot: {
    padding: theme.spacing(2),
    border: '1px solid #b4b4b4',
  },
  dividerRoot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
  },
}))

export default function OrderSummary(props) {
  const history = useHistory()
  const [totalAmount, setTotalAmount] = useState(0)
  const [finalAmount, setFinalAmount] = useState(0)

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const classes = useStyles(shopconfig)

  const { loading: loadingBuyer, data: { buyer } = { buyer: { id: null } } } =
    useQuery(GET_BUYER, { skip: !Cookies.get('signedin') })

  const { loading: loadingCarts, data: { carts } = { carts: [] } } =
    useQuery(GET_CARTS)

  useEffect(() => {
    if (carts.length == 0) return

    const totalAmount = carts.reduce((a, b) => a + b.InvoiceAmount, 0)
    setTotalAmount(totalAmount)
  }, [carts])

  useEffect(() => {
    /* setFinalAmount(postcodeprice.id && postcodeprice.Price + totalAmount) */
    setFinalAmount(totalAmount)
  }, [totalAmount])

  return (
    <React.Fragment>
      <Grid style={{ marginTop: 0 }} container>
        <Hidden only={['sm', 'xs']}>
          <Grid item xs={12} md={1}></Grid>
        </Hidden>
        <Grid item xs={12} md={5}>
          <div style={{ padding: 0 }}>
            {/* <div>
              <i class="fas fa-map-marker-alt" style={{ color: '#00acee' }}></i>{' '}
              Pick up your order in our local store nearby. We are currently do
              not provide product delivery service, only Self Collection at our
              Health Center is available.
            </div> */}
            <Divider classes={{ root: classes.dividerRoot }} />
            <div>
              <List style={{ marginBottom: 10 }} className={classes.listRoot}>
                {carts.map((cart, i) => (
                  <React.Fragment key={i}>
                    <div style={{ padding: 20 }}>
                      <TrolleyItem data={cart} disableSelect={true} />
                    </div>
                    {i < carts.length - 1 && (
                      <Divider classes={{ root: classes.dividerRoot }} />
                    )}
                  </React.Fragment>
                ))}
              </List>
            </div>
            <Divider classes={{ root: classes.dividerRoot }} />
          </div>
        </Grid>

        <Grid item xs={12} md={1}></Grid>
        <Grid item xs={12} md={4} style={{ marginBottom: 10 }}>
          <Paper classes={{ root: classes.summaryRoot }} variant="outlined">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                Items in Cart
              </Grid>
              <Grid item xs={12}>
                <strong>{carts.length}</strong>
              </Grid>
            </Grid>
            <Divider classes={{ root: classes.dividerRoot }} />
            <Grid container spacing={0}>
              <Grid item xs={12}>
                Total Amount
              </Grid>
              <Grid item xs={12}>
                <strong>RM {parseFloat(totalAmount).toFixed(2)}</strong>
              </Grid>
            </Grid>
            {/* <Divider classes={{ root: classes.dividerRoot }} />
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  Delivery Charges
                </Grid>
                <Grid item xs={12}>
                  <strong>
                    RM {parseFloat(postcodeprice.Price).toFixed(2)}
                  </strong>
                </Grid>
              </Grid> */}
            <Divider classes={{ root: classes.dividerRoot }} />
            <Grid container spacing={2}>
              <Grid item xs={6} md={8} style={{ fontSize: 17 }}>
                <strong>Total</strong>
              </Grid>
              <Grid
                item
                xs={6}
                md={4}
                style={{ textAlign: 'right', fontSize: 17 }}
              >
                <strong>RM {parseFloat(finalAmount).toFixed(2)}</strong>
              </Grid>
            </Grid>
          </Paper>
          <Button
            style={{ marginTop: 10, marginRight: 5 }}
            variant="outlined"
            size="large"
            color="primary"
            onClick={() => {
              props.handleBack()
            }}
          >
            Back
          </Button>
          <Button
            style={{ marginTop: 10 }}
            disabled={carts.length === 0}
            variant="contained"
            disableElevation
            color="primary"
            onClick={props.handleNext}
          >
            Confirm
          </Button>
        </Grid>

        <Grid item sm={12} md={2}></Grid>
      </Grid>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </React.Fragment>
  )
}
