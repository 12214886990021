import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import { Link, useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { useMutation, useQuery } from '@apollo/react-hooks'
import InputLabel from '@material-ui/core/InputLabel'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { GET_MYPASS } from '../graphql/order'
import {
  INSERT_BOOKING,
  GET_BOOKINGS,
  REMOVE_BOOKING,
  BOOKING_SCHEDULE,
  GET_BOOKINGORDERS,
} from '../graphql/booking'
import { GET_BRANCH, GET_GROUPBRANCHES } from '../graphql/branch'
import Cookies from 'js-cookie'
import Moment from 'moment-timezone'

import Tabs from '@material-ui/core/Tabs'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Tab from '@material-ui/core/Tab'
import CssBaseline from '@material-ui/core/CssBaseline'
import QRCode from 'qrcode.react'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import SingleTitle from '../common/singleTitle'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import CheckIcon from '@material-ui/icons/Check'
import Fade from '@material-ui/core/Fade'
import DesktopTopMenu from '../pages/desktoptopmenu'
import TextFieldDisplay from '../common/textFieldDisplay'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import AutocompleteField from '../common/autocompleteField'
import { useSnackbar } from 'notistack'
import DatepickerField from '../common/datepickerField'
import TableHalf from '../common/tableHalf'

const restApi = process.env.REACT_APP_API

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  root: {
    padding: 0,
  },
  chiproot: {
    display: 'flex',
    justifyContent: 'left',
    paddingLeft: 20,
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  menuitem: {
    textAlign: 'left',
    display: 'block',
  },
  avatar: {
    color: 'white',
    backgroundColor: '#00acee',
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
  rootContainer: { marginBottom: theme.spacing(2) },
  rootInputlabel: { fontWeight: 'bold' },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
    },
  },
}))

const StyledTabs = withStyles((theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />)

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />)

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'Title',
    label: 'Package',
  },
  {
    id: 'ServiceTimes',
    label: 'Total Pass',
  },
  {
    id: 'TotalUsed',
    label: 'Used Pass',
  },
]

export default function Booking(props) {
  const classes = useStyles()
  const history = useHistory()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [selectedDate, setSelectedDate] = useState(
    Moment(new Date()).tz('Asia/Kuala_Lumpur')
  )
  const [showForm, setShowForm] = useState(true)
  const [showActive, setShowActive] = useState(false)
  const [time, setTime] = useState()
  const [totalHours, setTotalHours] = useState()
  const [booking, setBooking] = useState()
  const [confirm, setConfirm] = useState(false)
  const [totalPerson, setTotalPerson] = useState()
  const [branchCode, setBranchCode] = useState()
  const [disabled, setDisabled] = useState(false)
  const [packagePriceID, setPackagePriceID] = useState()
  const [totalPersonSelect, setTotalPersonSelect] = useState([])
  const [selected, setSelected] = useState([])

  const { data: { bookings } = { bookings: [] }, refetch: refetchBooking } =
    useQuery(GET_BOOKINGS)

  const {
    data: { groupbranches } = { groupbranches: [] },
    refetch: refetchBranches,
  } = useQuery(GET_GROUPBRANCHES)

  const { data: { branch } = { branch: {} }, refetch: refetchBranch } =
    useQuery(GET_BRANCH)

  const {
    loading: loadingMypass,
    data: { mypass } = { mypass: { ServiceTimes: 0 } },
    refetch: refetchMypass,
  } = useQuery(GET_MYPASS)

  const {
    loading: loadingSchedule,
    refetch: refetchBookingSchedules,
    data: { bookingschedules } = { bookingschedules: [] },
  } = useQuery(BOOKING_SCHEDULE, {
    variables: { BranchCode: branchCode, BookingDate: selectedDate },
    skip: !branchCode,
  })

  const {
    refetch: refetchBookingOrders,
    data: { bookingorders } = { bookingorders: [] },
  } = useQuery(GET_BOOKINGORDERS)

  const valueid = props.match?.params?.id || 0

  console.log('valueid', valueid)
  const [value, setValue] = useState(parseInt(valueid) || 0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function tabProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }

  const addCache = (cache, { data }) => {
    if (data.insertbooking.Error) {
      enqueueSnackbar(data.insertbooking.Error, {
        variant: 'error',
      })
    }

    enqueueSnackbar('Added to your Booking', {
      variant: 'success',
    })

    setTime()
    setTotalPerson()
    setPackagePriceID()
    refetchBooking()
    refetchMypass()
    refetchBookingSchedules({
      BookingDate: selectedDate,
      BranchCode: branchCode,
    })

    setDisabled(false)
  }

  const [insertBooking] = useMutation(INSERT_BOOKING, {
    update: addCache,
  })

  const removeCache = (cache, { data }) => {
    setTime()
    setTotalPerson()
    setPackagePriceID()
    refetchBooking()
    refetchBookingSchedules({
      BookingDate: selectedDate,
      BranchCode: branchCode,
    })

    setDisabled(false)
  }

  const [removeBooking] = useMutation(REMOVE_BOOKING, {
    update: removeCache,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!Cookies.get('signedin')) {
      history.push('/login')
    } else {
      refetchMypass()
    }
  }, [props])

  const handleDateChange = (value) => {
    setTime()
    setSelectedDate(Moment(value).tz('Asia/Kuala_Lumpur'))
    refetchBookingSchedules({
      BookingDate: Moment(value).tz('Asia/Kuala_Lumpur'),
      BranchCode: branchCode,
    })
  }

  useEffect(() => {
    setTime()
    refetchBookingSchedules({
      BookingDate: Moment(new Date()).tz('Asia/Kuala_Lumpur'),
      BranchCode: branchCode,
    })

    refetchMypass()

    refetchBranches()
    refetchBranch()
  }, [props])

  useEffect(() => {
    if (branch.BranchCode && !branchCode) setBranchCode(branch.BranchCode)
  }, [branch])

  /* useEffect(() => {
    setBooking()
    setShowForm(true)
    if (bookings.find((a) => !a.ReceivedBy)) {
      setShowForm(false)
      setBooking(bookings.find((a) => !a.ReceivedBy))
    }
  }, [bookings]) */

  const handleSubmit = () => {
    if (selectedDate && time && totalPerson && branch) {
      insertBooking({
        variables: {
          BranchCode: branchCode,
          BookDate: selectedDate,
          BookTime: time,
          TotalPerson: totalPerson,
          PackagePriceID: packagePriceID,
          /* TotalHours: parseFloat(totalHours), */
          TotalHours: 1,
        },
      })

      setDisabled(true)
    } else {
      enqueueSnackbar('Please fill in the form', {
        variant: 'error',
      })
    }
  }

  const handleClickDelete = () => {
    removeBooking({
      variables: {
        id: booking.id,
      },
    })
    setDisabled(true)
    setConfirm(false)
  }

  useEffect(() => {
    if (branch.BranchCode) setBranchCode(branch.BranchCode)
  }, [branch])

  return (
    <React.Fragment>
      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Are you sure to cancel Booking?"
        okButton="Yes"
        title=""
      />
      {/* <Container classes={{ root: classes.containerroot }} maxWidth="md">
        <DesktopTopMenu />
      </Container> */}
      <Container maxWidth="md">
        <br />
        <br />
        <StyledTabs
          className={classes.border}
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab label="Booking" {...tabProps(0)} />
          {/*  <StyledTab label="Collected" {...tabProps(1)} /> */}
          <StyledTab label="Completed Booking" {...tabProps(1)} />
        </StyledTabs>
        <TabPanel value={value} index={0}>
          <div className={classes.root}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div>
                <Button
                  variant="contained"
                  component={Link}
                  to="/service/38/936-lae-energy-room-treatment"
                  style={{
                    minWidth: 100,
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginBottom: 10,
                  }}
                  disableElevation
                  size="small"
                  color="primary"
                >
                  BUY PASS
                </Button>
              </div>
              <div
                style={{
                  marginBottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {loadingMypass ? (
                  <Loading />
                ) : (
                  <TableContainer component={Paper} elevation={0}>
                    <TableHalf
                      hideSearch={true}
                      disableSelected={true}
                      rowsPerPage={5}
                      clickOnSelect={false}
                      selected={selected}
                      setSelected={setSelected}
                      tableData={mypass}
                      tableHead={tableHead}
                    />
                  </TableContainer>
                )}
              </div>
              {/* <pre>here{JSON.stringify(booking, 0, 4)}</pre> */}

              <br />

              <br />
              <div>
                <Grid container>
                  <Grid item md={7} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel
                        classes={{ root: classes.rootInputlabel }}
                        shrink
                        htmlFor="Branch"
                      >
                        Select Branch
                      </InputLabel>
                      <Select
                        labelId="Branch"
                        id="Branch"
                        name="Branch"
                        value={branchCode}
                        onChange={(e) => {
                          console.log('date', selectedDate)
                          setBranchCode(e.target.value)
                          setTime('')
                        }}
                        input={
                          <InputBase
                            classes={{
                              root: classes.rootBootstrap,
                              input: classes.inputBootstrap,
                            }}
                          />
                        }
                      >
                        {groupbranches.map((a, i) => (
                          <MenuItem key={i} value={a.BranchCode}>
                            {a.BranchName}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* <Grid container>
                    <Grid item xs={7}>
                      <h3>Booking Outlet:</h3>
                      <div>
                        <b>{branch.BranchName}</b>
                      </div>
                      {branch.Address1 && <div>{branch.Address1}</div>}
                      {branch.Address2 && <div>{branch.Address2}</div>}
                      {branch.Address3 && <div>{branch.Address3}</div>}
                      {branch.Postcode && (
                        <div>
                          {branch.Postcode}, {branch.City}
                        </div>
                      )}
                      {branch.State && (
                        <div>
                          {branch.State}, {branch.Country}
                        </div>
                      )}
                    </Grid>
                  </Grid> */}

                <Grid container style={{ marginTop: 40 }}>
                  <Grid item md={2} xs={7}>
                    <DatepickerField
                      disablePast={true}
                      name="BookingDate"
                      value={selectedDate ? selectedDate : null}
                      label="Booking Date"
                      format={'MMM, DD YYYY'}
                      onChange={handleDateChange}
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ marginTop: 15 }}>
                  <Grid item md={2} xs={4}>
                    <FormControl className={classes.formControl}>
                      <InputLabel shrink id="localtion-label">
                        Booking Time
                      </InputLabel>

                      {bookingschedules.length > 0 && (
                        <Select
                          fullWidth
                          labelId="localtion-label"
                          value={time}
                          onChange={(e, value) => {
                            setTime(e.target.value)
                          }}
                        >
                          {bookingschedules &&
                            bookingschedules.map((a, i) => (
                              <MenuItem
                                key={i}
                                classes={{ root: classes.menuitem }}
                                value={a.value}
                              >
                                {a.title}
                              </MenuItem>
                            ))}
                        </Select>
                      )}

                      {bookingschedules.length == 0 && (
                        <div style={{ marginTop: 20 }}>
                          Sorry, this date is fully booked. Please select a
                          different date.
                        </div>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container style={{ marginTop: 15 }}>
                  <Grid item md={6} xs={6}>
                    <FormControl className={classes.formControl}>
                      <InputLabel shrink htmlFor="PackagePriceID">
                        Select Your Package
                      </InputLabel>
                      <Select
                        labelId="PackagePriceID"
                        id="PackagePriceID"
                        name="PackagePriceID"
                        value={packagePriceID}
                        onChange={(e) => {
                          setPackagePriceID(e.target.value)

                          const orders = bookingorders.find(
                            (a) => a.value == e.target.value
                          )

                          if (orders && orders.MinBooking == 2) {
                            setTotalPersonSelect([2])
                          } else {
                            setTotalPersonSelect([1, 2])
                          }
                        }}
                      >
                        {bookingorders.map((a, i) => (
                          <MenuItem key={i} value={a.value}>
                            {a.title}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>

                {totalPersonSelect && totalPersonSelect.length > 0 && (
                  <Grid container style={{ marginTop: 15 }}>
                    <Grid item md={2}>
                      <FormControl className={classes.formControl}>
                        <InputLabel shrink id="localtion-label">
                          Total Person
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="localtion-label"
                          value={totalPerson}
                          onChange={(e, value) => {
                            setTotalPerson(e.target.value)
                          }}
                        >
                          {totalPersonSelect.map((person, i) => (
                            <MenuItem
                              id={i}
                              classes={{ root: classes.menuitem }}
                              value={person}
                            >
                              {person}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>Requires Booking Pass</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                {/* <Fade in={branch}>
                    <Grid container style={{ marginTop: 25 }}>
                      <Grid item xs={5}>
                        <FormControl className={classes.formControl}>
                          <InputLabel shrink id="totalhours-label">
                            Duration
                          </InputLabel>
                          <Select
                            fullWidth
                            labelId="totalhours-label"
                            value={totalHours}
                            onChange={(e, value) => {
                              setTotalHours(e.target.value)
                            }}
                          >
                            <MenuItem value="1">1 hour</MenuItem>
                            <MenuItem value="1.5">1.5 hours</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Fade> */}

                <div style={{ marginTop: 50, marginBottom: 50 }}>
                  <Button
                    disabled={disabled}
                    disableElevation
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                      handleSubmit()
                    }}
                  >
                    Save Booking
                  </Button>
                </div>
              </div>
            </MuiPickersUtilsProvider>

            {/* {!showForm && (
              <Grid style={{ marginTop: 0 }} container spacing={10}>
                <Grid item sm={12} md={5}>
                  <div style={{ marginBottom: 20 }}>
                    <i
                      class="fad fa-check-circle"
                      style={{ color: '#00acee' }}
                    ></i>{' '}
                    One(1) Booking Pass has been deducted. <br />
                    <br />
                    Please present this code to our Customer Service counter to
                    Check In during your booking time.
                  </div>
                  {!booking.ReceivedBy && (
                    <div
                      style={{
                        backgroundColor: 'white',
                        padding: 80,
                      }}
                    >
                      <QRCode
                        style={{
                          backgroundColor: 'white',
                          width: '100%',
                        }}
                        value={`http://picturesofpeoplescanningqrcodes.tumblr.com/${booking.Token}`}
                        bgColor={'#ffffff'}
                        size={300}
                        fgColor={'#000000'}
                        level={'L'}
                        includeMargin={false}
                        renderAs={'svg'}
                        imageSettings={{
                          src: '/images/logo2.png',
                          x: null,
                          y: null,
                          height: 38,
                          width: 38,
                          excavate: true,
                        }}
                      />
                    </div>
                  )}

                  <br />
                  <br />
                  <Button
                    size="small"
                    variant="outlined"
                    disableElevation
                    color="primary"
                    onClick={() => {
                      //history.push('/booking/1')
                      setConfirm(true)
                    }}
                  >
                    Cancel Booking
                  </Button>
                </Grid>
                <Grid item sm={12} md={7}>
                  <Grid container spacing={3}>
                    <Grid style={{ paddingTop: 0 }} item md={6} xs={12}>
                      <TextFieldDisplay
                        label="Branch"
                        value={booking.BranchName}
                      />
                      <TextFieldDisplay
                        label="Booking Date & Time"
                        value={Moment(booking.BookFrom)
                          .tz('UTC')
                          .format('L - LT')}
                      />
                      <TextFieldDisplay
                        label="Duration"
                        value={`${booking.TotalHours} h`}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )} */}

            <h2>Active Booking</h2>
            <Button
              style={{ marginBottom: 10 }}
              size="small"
              variant="outlined"
              disableElevation
              color="primary"
              onClick={() => {
                refetchBooking()
              }}
            >
              Refresh
            </Button>

            <TableContainer component={Paper} elevation={0}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Booking&nbsp;No</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>
                      Time&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </TableCell>
                    <TableCell>Total Person&nbsp;&nbsp;&nbsp;&nbsp;</TableCell>
                    <TableCell>Duration&nbsp;&nbsp;&nbsp;&nbsp;</TableCell>
                    <TableCell>
                      Branch&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bookings
                    .filter((a) => !a.ReceivedBy)
                    .map((booking, i) => (
                      <TableRow key={i}>
                        <TableCell style={{ width: 200 }}>
                          <Link
                            style={{
                              color: '#00acee',
                              fontWeight: 'bold',
                              fontSize: 15,
                              textDecoration: 'none',
                            }}
                            to={`/bookings/detail/${booking.Token}`}
                          >
                            {booking.BookingNo}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {Moment(booking.BookFrom)
                            .tz('Asia/Kuala_Lumpur')
                            .format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell>
                          {Moment(booking.BookFrom)
                            .tz('Asia/Kuala_Lumpur')
                            .format('LT')}
                        </TableCell>
                        <TableCell>{booking.TotalPerson} Person</TableCell>
                        <TableCell>{booking.TotalHours} Hour</TableCell>
                        <TableCell>{booking.BranchName}</TableCell>
                      </TableRow>
                    ))}

                  {bookings &&
                    bookings.filter((a) => !a.ReceivedBy).length <= 0 && (
                      <TableRow>
                        <TableCell colSpan={6}>No Booking found.</TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Button
            style={{ marginBottom: 10 }}
            size="small"
            variant="outlined"
            disableElevation
            color="primary"
            onClick={() => {
              refetchBooking()
            }}
          >
            Refresh
          </Button>
          <TableContainer component={Paper} elevation={0}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Booking&nbsp;No</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>
                    Time&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </TableCell>
                  <TableCell>Total Person&nbsp;&nbsp;&nbsp;&nbsp;</TableCell>
                  <TableCell>Duration&nbsp;&nbsp;&nbsp;&nbsp;</TableCell>
                  <TableCell>
                    Branch&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bookings
                  .filter((a) => a.ReceivedBy)
                  .map((booking, i) => (
                    <TableRow key={i}>
                      <TableCell style={{ width: 200 }}>
                        <Link
                          style={{
                            color: '#00acee',
                            fontWeight: 'bold',
                            fontSize: 15,
                            textDecoration: 'none',
                          }}
                          to={`/bookings/detail/${booking.Token}`}
                        >
                          {booking.BookingNo}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {Moment(booking.BookFrom)
                          .tz('Asia/Kuala_Lumpur')
                          .format('DD-MM-YYYY')}
                      </TableCell>
                      <TableCell>
                        {Moment(booking.BookFrom)
                          .tz('Asia/Kuala_Lumpur')
                          .format('LT')}
                      </TableCell>
                      <TableCell>{booking.TotalPerson} Person</TableCell>
                      <TableCell>{booking.TotalHours} Hour</TableCell>
                      <TableCell>{booking.BranchName}</TableCell>
                    </TableRow>
                  ))}
                {bookings && bookings.filter((a) => a.ReceivedBy).length <= 0 && (
                  <TableRow>
                    <TableCell colSpan={6}>No Booking found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </Container>
    </React.Fragment>
  )
}
