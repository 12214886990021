import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Grid from '@material-ui/core/Grid'
import DialogContent from '@material-ui/core/DialogContent'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import { GET_CARTS } from '../graphql/cart'
import { GET_SHOPCONFIG } from '../graphql/config'
import Cookies from 'js-cookie'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import ReportChart from './reportChart'
import { useMutation, useQuery } from '@apollo/react-hooks'
import axios from 'axios'

const restApi = process.env.REACT_APP_API

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  toolbarroot: {
    minHeight: 48,
    paddingRight: 0,
    paddingLeft: 10,
    backgroundColor: '#fff',
    color: '#00acee',
  },
  dialogContentRoot: {
    backgroundColor: '#fff',
    padding: 0,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})

export default function ReportDialog(props) {
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()
  const [filename, setFilename] = React.useState()
  const tweetersIframe = `<iframe width="100%" style="height:calc(100vh - 200px); border:0" height="100%" border="0" src="${restApi}/media/spa1/uploads/healthreport/${
    props.data && props.data.Filename
  }" />`

  useEffect(() => {
    /* axios.interceptors.response.use(function (response) {
      const ctype = response.headers["content-type"];
      response.data = ctype.includes("charset=GB2312") ?
        iconv.decode(response.data, 'gb2312') :
        iconv.decode(response.data, 'utf-8');
      return response;
    })  */

    axios
      .get(
        `${restApi}/media/spa1/uploads/healthreport/${
          props.data && props.data.Filename
        }`,
        {
          headers: {
            'Content-Type': 'text/html;charset=gb2312',
          },
        }
      )
      .then((response) => {
        setFilename(response.data)
        console.log(response)
      })
      .catch((error) => console.log(error))
  }, [props])

  const handleClose = () => {
    props.setOpen(false)
  }

  return (
    <div>
      <Dialog
        maxWidth={props.fullScreen ? false : 'md'}
        fullWidth={props.fullScreen ? false : true}
        fullScreen={props.fullScreen}
        scroll="paper"
        open={props.open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar elevation={0} className={classes.appBar}>
          <Toolbar classes={{ root: classes.toolbarroot }}>
            <Grid container spacing={2} direction="row" alignItems="center">
              <Grid item></Grid>
              <Grid
                item
                style={{ paddingLeft: 0, fontWeight: 'bold', fontSize: 17 }}
              >
                {props.data && props.data.Title}
                {/* <pre> {JSON.stringify(props.data, 0, 4)}</pre>  */}
              </Grid>
            </Grid>

            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent classes={{ root: classes.dialogContentRoot }}>
          {/* <ReportChart /> */}
          <div dangerouslySetInnerHTML={{ __html: tweetersIframe }}></div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
