import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined'
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined'
import CartItem from './cartItem'

const useStyles = makeStyles((theme) => ({
  root: {},
  listitem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  inline: {
    display: 'inline',
  },
}))

export default function Cart(props) {
  const classes = useStyles()

  if (props.data.length === 0)
    return <h4 style={{ textAlign: 'center' }}>No item in Cart</h4>

  return props.data.map((cart, i) => (
    <React.Fragment key={i}>
      <CartItem data={cart} removeCart={props.removeCart} />
      <Divider />
    </React.Fragment>
  ))
}
