import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import DialogContent from '@material-ui/core/DialogContent'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import MobileCart from './mobiecart'
import { GET_CARTS } from '../graphql/cart'
import { GET_SHOPCONFIG } from '../graphql/config'
import Cookies from 'js-cookie'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import ProductDialog from './productDialog'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  button: {
    minWidth: 84,
    color: (props) => `${props.COLORPRIMARY}`,
    [theme.breakpoints.up('md')]: {
      color: 'white',
    },
  },
  toolbarroot: {
    minHeight: 48,
    paddingRight: 0,
    paddingLeft: 10,
  },
  dialogContentRoot: {
    backgroundColor: '#ebebeb',
    padding: 0,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})

export default function FullScreenDialog() {
  const [open, setOpen] = React.useState(false)

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const classes = useStyles(shopconfig)

  const [openProduct, setOpenProduct] = React.useState(false)
  const [selectedProductID, setSelectedProductID] = React.useState()
  const [product, setProduct] = React.useState()

  const {
    loading: loadingCarts,
    data: { carts } = { carts: [] },
    refetch: refetchCart,
  } = useQuery(GET_CARTS, { skip: !Cookies.get('signedin') })

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Hidden only={['sm', 'xs']}>
        <Button
          style={{
            minWidth: 34,
            paddingLeft: 10,
            paddingRight: 10,
            backgroundColor: 'transparent',
          }}
          disableRipple
          component={Link}
          to="/checkout"
          size="small"
          className={classes.button}
          endIcon={<ShoppingCartIcon />}
        >
          <span style={{ fontWeight: 'bold', fontSize: 15, paddingTop: 4 }}>
            {carts.length}
          </span>
        </Button>
      </Hidden>

      <Hidden only={['lg', 'xl', 'md']}>
        <Button
          disableRipple
          size="large"
          style={{ backgroundColor: 'transparent' }}
          className={classes.button}
          /* onClick={handleClickOpen} */
          component={Link}
          to="/checkout"
          endIcon={<ShoppingCartIcon />}
        >
          <span style={{ fontWeight: 'bold', fontSize: 15, paddingTop: 4 }}>
            {carts.length}
          </span>
        </Button>
      </Hidden>

      <Dialog
        fullScreen
        scroll="paper"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar classes={{ root: classes.toolbarroot }}>
            <Grid container spacing={2} direction="row" alignItems="center">
              <Grid item>
                <ShoppingCartIcon />
              </Grid>
              <Grid
                item
                style={{ paddingLeft: 0, fontWeight: 'bold', fontSize: 17 }}
              >
                {carts.length} items
              </Grid>
            </Grid>

            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent classes={{ root: classes.dialogContentRoot }}>
          <MobileCart
            setOpen={setOpen}
            setOpenProduct={setOpenProduct}
            setProduct={setProduct}
          />
        </DialogContent>
      </Dialog>
      <ProductDialog
        openProduct={openProduct}
        setOpenProduct={setOpenProduct}
        selectedProductID={selectedProductID}
        setSelectedProductID={setSelectedProductID}
        product={product}
        setProduct={setProduct}
      />
    </div>
  )
}
