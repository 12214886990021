import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Redirect,
  Route,
  BrowserRouter,
  Switch,
  withRouter,
  Link,
  Link as Link2,
  NavLink,
} from 'react-router-dom'

import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'

const restApi = process.env.REACT_APP_API

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  listRoot: {
    width: '100%',
    maxWidth: '36ch',
    padding: 0,
    overflowY: 'scroll',
    flexGrow: 1,
    backgroundColor: '#ebebeb',
  },
  media: {
    height: 150,
    margin: theme.spacing(1),
  },
  card: {},
  buttonRoot: {
    minWidth: 0,
    marginRight: 5,
    padding: 7,
  },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  containerroot: {
    paddingTop: 20,
    paddingBottom: 50,
    paddingLeft: 0,
    paddingRight: 0,
  },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  rootBootstrapHelpertext: {
    color: 'white',
    marginTop: theme.spacing(2),
  },
  inputBootstrap: {
    borderRadius: 6,
    color: 'white',
    position: 'relative',
    backgroundColor: 'transparent',
    border: '3px solid #ced4da',
    width: '100%',
    padding: '13px 10px 10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderColor: (props) => `${props.COLORPRIMARY}`,
    },
  },
  productImages: {
    height: '100%',
    maxHeight: 80,

    [theme.breakpoints.up('md')]: {
      height: '100%',
      maxHeight: 220,
    },
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export default function Product(props) {
  const classes = useStyles()
  const valueid = parseInt(props.match.params.id)
  const [valueProduct, setValueProduct] = useState(valueid || 0)

  const handleChangeProduct = (event, newValue) => {
    setValueProduct(newValue)
  }

  useEffect(() => {
    setValueProduct(parseInt(props.match.params.id) || 0)
  }, [props.match.params.id])

  return (
    <React.Fragment>
      <div
        style={{
          background: 'url(/images/bg/6.jpg) no-repeat center center',
          backgroundSize: 'cover',
          minHeight: 700,
          display: 'flex',
          alignItems: 'top',
        }}
      >
        <Container classes={{ root: classes.containerroot }} maxWidth="md">
          <Grid style={{ marginTop: 0 }} container spacing={0}>
            <Grid
              item
              xs={12}
              md={12}
              style={{ color: 'white', textAlign: 'center' }}
            >
              <h1
                style={{ fontWeight: 'normal', marginBottom: 0, marginTop: 30 }}
              >
                Product
              </h1>
              {/* <h3
                style={{
                  fontWeight: 'normal',
                  paddingTop: 0,
                  marginTop: 0,
                  marginBottom: 50,
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </h3> */}
            </Grid>
          </Grid>
          <Grid style={{ marginTop: 15 }} container spacing={0}>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                color: 'white',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Tabs
                value={valueProduct}
                onChange={handleChangeProduct}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab
                  style={{ color: 'white' }}
                  label="936 Wellness Retreat Mist"
                />
                <Tab style={{ color: 'white' }} label="936 Beauty Mist" />
                {/* <Tab style={{ color: 'white' }} label="ELIFE" /> */}
              </Tabs>
            </Grid>

            <br />
            <br />
            <div
              style={{
                minHeight: 500,
              }}
            >
              <TabPanel value={valueProduct} index={0}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    marginTop: 20,
                    color: 'white',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Grid style={{ marginTop: 0 }} container spacing={0}>
                    <Grid
                      item
                      xs={12}
                      md={5}
                      style={{ color: 'white', textAlign: 'center' }}
                    >
                      <Hidden only={['sm', 'xs']}>
                        <img
                          src="/images/products/b.png"
                          style={{ maxHeight: 308 }}
                        />
                      </Hidden>

                      <Hidden only={['md', 'lg', 'xl']}>
                        <img
                          src="/images/products/b.png"
                          style={{ maxHeight: 208 }}
                        />
                      </Hidden>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={7}
                      style={{ color: 'white', textAlign: 'left' }}
                    >
                      <h2
                        style={{
                          fontWeight: 'bold',
                          marginBottom: 20,
                          marginTop: 0,
                        }}
                      >
                        936 Wellness Retreat Mist
                      </h2>
                      <p style={{ marginBottom: 20 }}>
                        When 936 Wellness Retreat Mist is applied, the hydrogen
                        bond between water molecules will be broken due to
                        Resonance.
                        <br />
                        <br />
                        Big water molecular cluster are now broken down into
                        small water molecular cluster with a larger surface
                        area.
                        <br />
                        <br />
                        This increases the water absorption rate in our body.
                      </p>
                      <Button
                        component={Link2}
                        to="/shop/37/936-Wellness-Retreat-Mist"
                        style={{ marginBottom: 40 }}
                        variant="contained"
                        size="large"
                        color="primary"
                      >
                        Buy Now
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={valueProduct} index={1}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    marginTop: 20,
                    color: 'white',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Grid style={{ marginTop: 0 }} container spacing={0}>
                    <Grid
                      item
                      xs={12}
                      md={5}
                      style={{ color: 'white', textAlign: 'center' }}
                    >
                      <Hidden only={['sm', 'xs']}>
                        <img
                          src="/images/products/a.png"
                          style={{ maxHeight: 308 }}
                        />
                      </Hidden>

                      <Hidden only={['md', 'lg', 'xl']}>
                        <img
                          src="/images/products/a.png"
                          style={{ maxHeight: 208 }}
                        />
                      </Hidden>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={7}
                      style={{ color: 'white', textAlign: 'left' }}
                    >
                      <h2
                        style={{
                          fontWeight: 'bold',
                          marginBottom: 20,
                          marginTop: 0,
                        }}
                      >
                        936 Beauty Mist
                      </h2>
                      <p style={{ marginBottom: 20 }}>
                        936 Beauty Mist can form 9.36pm light waves similar to
                        the body cells and organs, which improves blood
                        circulation and vitality.
                        <br />
                        <br />
                        The 936 Beauty Mist has strong moisturizing nutrients
                        with powerful moisturizing effect, It has multi-effect
                        moisturizing factors and strong vitality.
                        <br />
                        <br />
                        It is very nutritious and also a rare anti-frustration
                        ingredient.
                        <br />
                        <br />
                        936 Beauty Mist can penetrate and moisturize from the
                        surface, middle and deep layers of the skin with its
                        magical moisturizing effect.
                      </p>
                      <Button
                        component={Link2}
                        to="/shop/34/936-Beauty-Mist"
                        style={{ marginBottom: 40 }}
                        variant="contained"
                        size="large"
                        color="primary"
                      >
                        Buy Now
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
              {/* <TabPanel value={valueProduct} index={2}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    marginTop: 20,
                    color: 'white',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Grid style={{ marginTop: 0 }} container spacing={0}>
                    <Grid
                      item
                      xs={12}
                      md={5}
                      style={{ color: 'white', textAlign: 'center' }}
                    >
                      <Hidden only={['sm', 'xs']}>
                        <img
                          src="/images/products/c.png"
                          style={{ maxHeight: 308 }}
                        />
                      </Hidden>

                      <Hidden only={['md', 'lg', 'xl']}>
                        <img
                          src="/images/products/c.png"
                          style={{ maxHeight: 208 }}
                        />
                      </Hidden>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={7}
                      style={{ color: 'white', textAlign: 'left' }}
                    >
                      <h2
                        style={{
                          fontWeight: 'bold',
                          marginBottom: 20,
                          marginTop: 0,
                        }}
                      >
                        ELIFE
                      </h2>
                      <p style={{ marginBottom: 20 }}>
                        Experience the finest skin care product, specially
                        developed based on our core technology LAE Light
                        Activated Energy.
                        <br />
                        <br />
                        A beauty secret finally revealed. The world's first
                        beauty and healthcare 2-in-1 product.
                        <br />
                        6 substantial efficacies and 4 unique elements are all
                        you nned to instantly restores skin elasticity.
                        <br />
                        <br />
                        ezurose, a rose hidden within the light.
                      </p>
                      <Button
                        component={Link2}
                        to="/shop/36/elife"
                        style={{ marginBottom: 40 }}
                        variant="contained"
                        size="large"
                        color="primary"
                      >
                        Buy Now
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel> */}
            </div>
          </Grid>
          <br />
          <br />
        </Container>
      </div>
    </React.Fragment>
  )
}
