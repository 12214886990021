import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'

import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Button from '@material-ui/core/Button'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import { useMutation } from '@apollo/react-hooks'
import { useEffect } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import TextFieldDisplay from '../common/textFieldDisplay'
import BookingDetailsItem from './bookingDetailsItem'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'

const restApi = process.env.REACT_APP_API

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%',
    maxWidth: '36ch',
  },
  listitem: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: '#ffffff',
  },
  inline: {
    display: 'inline',
  },
  buttonRoot: {
    minWidth: 0,
    marginRight: 5,
    padding: 7,
  },
  textfieldRoot: {
    marginTop: 0,
    marginRight: 5,
    width: 100,
  },
  image: {
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  paddingleft: {
    padding: 10,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
    },
  },
  iconbuttonroot: {
    padding: 0,
  },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

export default function BookingDetails(props) {
  const classes = useStyles()
  const history = useHistory()

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid style={{ paddingTop: 0 }} item md={4} xs={12}>
          <TextFieldDisplay
            label="Total"
            value={`RM ${parseFloat(props.totalAmount).toFixed(2)}`}
          />
        </Grid>
        <Grid style={{ paddingTop: 0 }} item md={4} xs={12}>
          {/* <TextFieldDisplay
            label="Delivery"
            value={`RM ${parseFloat(props.data.DeliveryCharges).toFixed(2)}`}
          /> */}
          {/*  <TextFieldDisplay
            label="Order Total"
            value={`RM ${parseFloat(props.finalAmount).toFixed(2)}`}
          /> */}
        </Grid>
        <Grid style={{ paddingTop: 0 }} item md={4} xs={12}>
          {/* <TextFieldDisplay
            label="Order Total"
            value={`RM ${parseFloat(props.finalAmount).toFixed(2)}`}
          /> */}
        </Grid>
      </Grid>

      {/* <Divider classes={{ root: classes.dividerroot }} /><br />
      <br />

      <Grid container spacing={3}>
        <Grid style={{ paddingTop: 0 }} item md={6} xs={12}>
          <TextFieldDisplay label="Address 1" value={props.data.Address1} />

          <TextFieldDisplay label="Address 2" value={props.data.Address2} />

          <TextFieldDisplay label="City" value={props.data.City} />

          <TextFieldDisplay label="Postcode" value={props.data.PostCode} />

          <TextFieldDisplay label="State" value={props.data.State} />
        </Grid>
      </Grid> */}

      {/* <pre>{JSON.stringify(props.data.OrderDetails, 0, 4)}</pre> */}

      <Grid container style={{ marginTop: 20 }} spacing={0}>
        <Grid item md={12} xs={12}>
          <Grid container spacing={0}>
            {props.data &&
              props.data.OrderDetails.map((order, i) => (
                <React.Fragment>
                  <Grid style={{ paddingTop: 0 }} item md={7} xs={12}>
                    <BookingDetailsItem key={i} data={order} />
                    {i < props.data.OrderDetails.length - 1 && (
                      <Divider classes={{ root: classes.dividerroot }} />
                    )}
                  </Grid>
                </React.Fragment>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
