import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  CardActions,
  Button,
  CardMedia,
  List,
  ListItem,
  ListItemText,
  Link,
} from '@material-ui/core/'

import Drawer from '@material-ui/core/Drawer'
import clsx from 'clsx'
import Divider from '@material-ui/core/Divider'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import { Link as Link2, useHistory } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden'
import Box from '@material-ui/core/Box'
import Pagination from '@material-ui/lab/Pagination'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Cookies from 'js-cookie'
import Chip from '@material-ui/core/Chip'

import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'
import CheckIcon from '@material-ui/icons/Check'
import EmailIcon from '@material-ui/icons/Email'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import Cart from './cart'
import { GET_PRODUCTS, TOTAL_PRODUCTS } from '../graphql/product'
import { GET_CATEGORIES } from '../graphql/category'

import {
  GET_CARTS,
  INSERT_CART,
  UPDATE_CART,
  REMOVE_CART,
} from '../graphql/cart'

import { GET_SHOPCONFIG } from '../graphql/config'
import { SUBSCRIBEMAIL } from '../graphql/staff'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { useEffect } from 'react'
import ShopDetails from './shopDetails'
import Loading from '../common/loading'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MenuCategory from './menuCategory'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import ProductDialog from './productDialog'
import PreloadImage from 'material-ui-image'
import Slides from './slides'
import SlideCircle from './slideCircle'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { useSnackbar } from 'notistack'

const restApi = process.env.REACT_APP_API

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  listRoot: {
    width: '100%',
    maxWidth: '36ch',
    padding: 0,
    overflowY: 'scroll',
    flexGrow: 1,
    backgroundColor: '#ebebeb',
  },
  listitemtext: {
    fontSize: 14,
    cursor: 'pointer',
    textDecoration: 'none!important',
  },

  menuItem: {
    paddingLeft: 0,
    paddingRight: 0,
    textDecoration: 'none',
    fontWeight: 'normal',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  media: {
    height: 150,
    margin: theme.spacing(1),
  },
  card: {},
  addRoot: {
    minWidth: 0,
    marginTop: 3,
    marginRight: 5,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 20,
    boxShadow: 'none',
  },
  buttonRoot: {
    minWidth: 0,
    marginRight: 5,
    padding: 7,
  },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  containerroot: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  paddingleft: {
    padding: 8,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
    },
  },
  iconbuttonroot: {
    padding: 0,
  },
  outVideo: {
    display: 'block',
    position: 'relative',
    minWidth: '100% !important',
    height: '82% !important',
    /* zIndex: '-1', */
    margin: 0,
    padding: 0,
    background: 'black',
    marginBottom: '-20px',
    marginTop: '-10px',
  },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  rootBootstrapHelpertext: {
    color: 'white',
    marginTop: theme.spacing(2),
  },
  inputBootstrap: {
    borderRadius: 6,
    color: 'white',
    position: 'relative',
    backgroundColor: 'transparent',
    border: '3px solid #ced4da',
    width: '100%',
    padding: '13px 10px 10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderColor: (props) => `${props.COLORPRIMARY}`,
    },
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

export default function Shop(props) {
  const classes = useStyles()
  const history = useHistory()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [selectedProductID, setSelectedProductID] = useState()
  const [product, setProduct] = useState()
  const [productMobile, setProductMobile] = useState()
  const [back, setBack] = useState()
  const [backProductID, setBackProductID] = useState()
  const [currPage, setCurrPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [maxPages, updateMaxPages] = useState(0)
  const [viewLocation, setViewLocation] = useState()
  const [subEmail, setSubEmail] = useState()

  const [catId, setCatId] = useState(
    (props.match && props.match.params && props.match.params.id) || 1
  )

  const [companyName, setCompanyName] = useState()
  const [companyLogo, setCompanyLogo] = useState()

  const [dbhost, setDbhost] = useState('spa1')

  /*const [dbhost, setDbhost] = useState('shop')*/

  const [openProduct, setOpenProduct] = React.useState(false)
  const [totalAmount, setTotalAmount] = useState(0)

  /*  const {
    loading: loadingTotal,
    data: { numberOfProducts } = { numberOfProducts: { total: 0 } },
  } = useQuery(TOTAL_PRODUCTS) */

  const {
    loading: loadingProducts,
    data: { products } = { products: [] },
    refetch,
  } = useQuery(GET_PRODUCTS)

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const onLoadMore = () => {
    refetch({
      offset: offset,
      limit: 12,
    })

    /* fetchMore({
      variables: {
        offset: currPage,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          products: [...fetchMoreResult.products],
        })
      },
    }) */
  }

  const getNextPage = () => {
    setCurrPage(currPage + 1)
  }

  const getPrevPage = () => {
    if (currPage > 0) setCurrPage(currPage - 1)
  }
  const handleChangePage = (event, value) => {
    scrollToTop()
    /* if (!products[0]) return
    setBackProductID(products[0].id)
    setBack(new Date() + Math.random()) */

    setCurrPage(value)
    setOffset(value - 1)
  }

  useEffect(() => {
    onLoadMore()
  }, [currPage])

  /* useEffect(() => {
    const onCompleted = (dTotal) => {}

    if (dTotal && dTotal.numberOfSuggestions) {
      const totalNumberOfPages = Math.ceil(dTotal.numberOfSuggestions / 10)
      updateMaxPages(totalNumberOfPages)
    }
  }, [dTotal]) */
  /* 
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      hi
    </div>
  )

  const { data: { categories } = { categories: [] } } = useQuery(GET_CATEGORIES)
 */
  const {
    loading: loadingCarts,
    data: { carts } = { carts: [] },
    refetch: refetchCart,
  } = useQuery(GET_CARTS, { skip: !Cookies.get('signedin') })

  const addCache = (cache, { data }) => {
    if (data.subscribemail?.Error) {
      enqueueSnackbar(data.subscribemail?.Error, {
        variant: 'error',
      })
    } else {
      enqueueSnackbar('Added to Subscription', {
        variant: 'success',
      })
    }

    setSubEmail('')
  }

  const [subscribeMail] = useMutation(SUBSCRIBEMAIL, {
    update: addCache,
  })

  /* 
  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })
    const latest = data.insertcart
    cache.writeQuery({
      query: GET_CARTS,
      data: { carts: [...old.carts, latest] },
    })
  }

  const [insertCart] = useMutation(INSERT_CART, {
    update: addCache,
  })

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })
    const latest = data.updatecart
    const foundIndex = old.carts.findIndex((item) => item.id === latest.id)
    old.carts.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_CARTS,
      data: { carts: [...old.carts] },
    })
  }

  const [updateCart] = useMutation(UPDATE_CART, { update: updateCache })

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })

    if (data.removecart) {
      const latest = old.carts.filter(
        (item) => item.PriceID != data.removecart.PriceID
      )
      cache.writeQuery({
        query: GET_CARTS,
        data: { carts: [...latest] },
      })
    }

    setSelectedProductID()
  }

  const [removeCart] = useMutation(REMOVE_CART, { update: deleteCache })

  const myRefs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ])

  const executeScroll = (a) => {
    //myRefs.current[5].scrollIntoView({ behavior: 'smooth', block: 'start' })
    //scrollToRef(myRefs.current[5])
  } */

  useEffect(() => {
    //console.log('viewLocation', viewLocation)
    setTimeout(() => {
      window.scrollTo(0, viewLocation)
    }, 1)
    /* myRefs.current[backProductID].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      }) */
  }, [back])

  useEffect(() => {
    setCatId((props.match && props.match.params && props.match.params.id) || 1)
    /* refetch({
      Category:
        (props.match && props.match.params && props.match.params.id) || 1,
    }) */
    refetchCart()
  }, [props])

  useEffect(() => {
    const totalAmount = carts.reduce((a, b) => a + b.InvoiceAmount, 0)
    setTotalAmount(totalAmount)
  }, [carts])

  const [valueProduct, setValueProduct] = useState(0)
  const handleChangeProduct = (event, newValue) => {
    setValueProduct(newValue)
  }

  return (
    <React.Fragment>
      <div className={classes.outVideo}>
        <video
          autoPlay
          loop
          width="100%"
          height="100%"
          style={{ display: 'block' }}
          muted
        >
          <source src="/images/video/home2.mp4" type="video/mp4" />
        </video>
      </div>

      <div
        style={{
          background: 'url(/images/bg/brandstory.jpg) no-repeat center center',
          backgroundSize: 'cover',
          height: 200,
          display: 'flex',
          alignItems: 'center',
          color: 'white',
        }}
      >
        <h2
          style={{
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
          }}
        >
          Brand Story
          <br />
          <Button
            component={Link2}
            style={{ marginTop: 20 }}
            variant="contained"
            size="large"
            color="primary"
            to="/brandstory"
          >
            Learn More
          </Button>
        </h2>
      </div>

      <Hidden only={['sm', 'xs']}>
        <div
          style={{
            background: 'url(/images/bg/1.jpg) no-repeat top center',
            backgroundSize: 'cover',
            height: 700,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Container classes={{ root: classes.containerroot }} maxWidth="md">
            <Grid style={{ marginTop: 0 }} container spacing={0}>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
                <img src="/images/icon/logo.png" style={{ maxHeight: 160 }} />
                <br />
                <br />
                <Button
                  component={Link2}
                  to="/brandstory"
                  variant="contained"
                  size="large"
                  color="primary"
                >
                  Learn More
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Hidden>

      <Hidden only={['md', 'lg', 'xl']}>
        <div
          style={{
            background: 'url(/images/bg/1-mobile.png) no-repeat top center',
            backgroundSize: 'cover',
            height: 700,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Container classes={{ root: classes.containerroot }} maxWidth="md">
            <Grid style={{ marginTop: 0 }} container spacing={0}>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
                <img
                  src="/images/icon/logo.png"
                  style={{ maxHeight: 160, maxWidth: 250, marginTop: 400 }}
                />
                <br />
                <br />
                <Button
                  component={Link2}
                  to="/brandstory"
                  variant="contained"
                  size="large"
                  color="primary"
                >
                  Learn More
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Hidden>

      <Hidden only={['sm', 'xs']}>
        <div
          style={{
            background: 'url(/images/bg/2.jpg) no-repeat center center',
            backgroundSize: 'cover',
            minHeight: 700,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Container classes={{ root: classes.containerroot }} maxWidth="md">
            <Grid style={{ marginTop: 50 }} container spacing={0}>
              <Grid item xs={12} md={6}></Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ color: 'white', textAlign: 'center' }}
              >
                <h1
                  style={{
                    fontWeight: 'normal',
                    marginBottom: 0,
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                >
                  936 LAE
                  <br />
                  Energy Room Treatment
                </h1>

                <h2 style={{ fontWeight: 'normal', marginBottom: 20 }}>
                  Treatment Benefits
                </h2>
                <Grid style={{ marginTop: 40 }} container spacing={0}>
                  <Grid item xs={6} md={3}>
                    <img
                      src="/images/icon/laeroom/BoostMetabolism.png"
                      style={{ height: 70 }}
                    />
                    <br />
                    Boost Metabolism
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <img
                      src="/images/icon/laeroom/Detoxification.png"
                      style={{ height: 70 }}
                    />
                    <br />
                    Detoxification
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <img
                      src="/images/icon/laeroom/Refreshing.png"
                      style={{ height: 70 }}
                    />
                    <br />
                    Refreshing
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <img
                      src="/images/icon/laeroom/LoseFat.png"
                      style={{ height: 70 }}
                    />
                    <br />
                    Lose Fat
                  </Grid>
                </Grid>
                <Grid
                  style={{ marginTop: 40, marginBottom: 40 }}
                  container
                  spacing={0}
                >
                  <Grid item xs={6} md={4}>
                    <img
                      src="/images/icon/laeroom/BoostImmuneSystem.png"
                      style={{ height: 70 }}
                    />
                    <br />
                    Boost Immune System
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <img
                      src="/images/icon/laeroom/ReverseAging.png"
                      style={{ height: 70 }}
                    />
                    <br />
                    Reverse Aging
                  </Grid>{' '}
                  <Hidden only={['sm', 'xs']}>
                    <Grid item xs={12} md={4}>
                      <img
                        src="/images/icon/laeroom/ImproveHeartHealth.png"
                        style={{ height: 70 }}
                      />
                      <br />
                      Improve Heart Health
                    </Grid>
                  </Hidden>
                </Grid>
                <Hidden only={['md', 'lg', 'xl']}>
                  <Grid
                    style={{ marginTop: 40, marginBottom: 40 }}
                    container
                    spacing={0}
                  >
                    <Grid item xs={12} md={4}>
                      <img
                        src="/images/icon/laeroom/ImproveHeartHealth.png"
                        style={{ height: 70 }}
                      />
                      <br />
                      Improve Heart Health
                    </Grid>
                  </Grid>
                </Hidden>
                <Button
                  component={Link2}
                  style={{ marginBottom: 50 }}
                  variant="contained"
                  size="large"
                  color="primary"
                  to="/936-lae-energy-room-treatment-benefits"
                >
                  Learn More
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Hidden>
      <Hidden only={['md', 'lg', 'xl']}>
        <div
          style={{
            background:
              'url(/images/bg/2-mobile.bg.jpg) no-repeat center center',
            backgroundSize: 'cover',
            minHeight: 700,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Container classes={{ root: classes.containerroot }} maxWidth="md">
            <Grid style={{ marginTop: 50 }} container spacing={0}>
              <Grid item xs={12} md={12}>
                <img
                  src="/images/bg/2-mobile.png"
                  style={{ width: '100%', padding: 20 }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{ color: 'white', textAlign: 'center' }}
              >
                <h1
                  style={{
                    fontWeight: 'normal',
                    marginBottom: 0,
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                >
                  936 LAE
                  <br />
                  Energy Room Treatment
                </h1>

                <h2 style={{ fontWeight: 'normal', marginBottom: 20 }}>
                  Treatment Benefits
                </h2>
                <Grid style={{ marginTop: 40 }} container spacing={0}>
                  <Grid item xs={6} md={3}>
                    <img
                      src="/images/icon/laeroom/BoostMetabolism.png"
                      style={{ height: 70 }}
                    />
                    <br />
                    Boost Metabolism
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <img
                      src="/images/icon/laeroom/Detoxification.png"
                      style={{ height: 70 }}
                    />
                    <br />
                    Detoxification
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <img
                      src="/images/icon/laeroom/Refreshing.png"
                      style={{ height: 70 }}
                    />
                    <br />
                    Refreshing
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <img
                      src="/images/icon/laeroom/LoseFat.png"
                      style={{ height: 70 }}
                    />
                    <br />
                    Lose Fat
                  </Grid>
                </Grid>
                <Grid
                  style={{ marginTop: 40, marginBottom: 40 }}
                  container
                  spacing={0}
                >
                  <Grid item xs={6} md={4}>
                    <img
                      src="/images/icon/laeroom/BoostImmuneSystem.png"
                      style={{ height: 70 }}
                    />
                    <br />
                    Boost Immune System
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <img
                      src="/images/icon/laeroom/ReverseAging.png"
                      style={{ height: 70 }}
                    />
                    <br />
                    Reverse Aging
                  </Grid>{' '}
                  <Hidden only={['sm', 'xs']}>
                    <Grid item xs={12} md={4}>
                      <img
                        src="/images/icon/laeroom/ImproveHeartHealth.png"
                        style={{ height: 70 }}
                      />
                      <br />
                      Improve Heart Health
                    </Grid>
                  </Hidden>
                </Grid>
                <Hidden only={['md', 'lg', 'xl']}>
                  <Grid
                    style={{ marginTop: 40, marginBottom: 40 }}
                    container
                    spacing={0}
                  >
                    <Grid item xs={12} md={4}>
                      <img
                        src="/images/icon/laeroom/ImproveHeartHealth.png"
                        style={{ height: 70 }}
                      />
                      <br />
                      Improve Heart Health
                    </Grid>
                  </Grid>
                </Hidden>
                <Button
                  component={Link2}
                  style={{ marginBottom: 50 }}
                  variant="contained"
                  size="large"
                  color="primary"
                  to="/936-lae-energy-room-treatment-benefits"
                >
                  Learn More
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Hidden>

      <Hidden only={['md', 'lg', 'xl']}>
        <div
          style={{
            background:
              'url(/images/service/waterbenefit/2bg2.jpg) no-repeat center center',
            backgroundSize: 'cover',
            minHeight: 700,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Container classes={{ root: classes.containerroot }} maxWidth="md">
            <Grid style={{ marginTop: 0 }} container spacing={0}>
              <Grid item xs={12} md={6}>
                <img
                  src="/images/service/waterbenefit/1water.png"
                  style={{ width: '100%', padding: 20 }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ color: 'white', textAlign: 'center' }}
              >
                <h1
                  style={{
                    fontWeight: 'normal',
                    marginBottom: 0,
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                >
                  936 LAE
                  <br />
                  Active Water Treatment
                </h1>

                <h2 style={{ fontWeight: 'normal', marginBottom: 20 }}>
                  Treatment Benefits
                </h2>

                <Grid
                  style={{ marginTop: 40, marginBottom: 40 }}
                  container
                  spacing={0}
                >
                  <Grid item xs={6} md={4}>
                    <img
                      src="/images/icon/laewater/1.png"
                      style={{ height: 70 }}
                    />
                    <br />
                    Enhances Body Metabolism & Blood Circulation
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <img
                      src="/images/icon/laewater/2.png"
                      style={{ height: 70 }}
                    />
                    <br />
                    Experience Bliss & Reduce Stress
                  </Grid>
                  <Hidden only={['sm', 'xs']}>
                    <Grid item xs={12} md={4}>
                      <img
                        src="/images/icon/laewater/3.png"
                        style={{ height: 70 }}
                      />
                      <br />
                      Slow Down & Reverse Aging
                    </Grid>
                  </Hidden>
                </Grid>
                <Hidden only={['md', 'lg', 'xl']}>
                  <Grid
                    style={{ marginTop: 40, marginBottom: 40 }}
                    container
                    spacing={0}
                  >
                    <Grid item xs={12} md={4}>
                      <img
                        src="/images/icon/laewater/3.png"
                        style={{ height: 70 }}
                      />
                      <br />
                      Slow Down & Reverse Aging
                    </Grid>
                  </Grid>
                </Hidden>

                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  component={Link2}
                  to="/936-lae-active-water-treatment-benefits"
                >
                  Learn More
                </Button>

                <br />
                <br />
                <br />
                <br />
              </Grid>
            </Grid>
          </Container>
        </div>
      </Hidden>
      <Hidden only={['sm', 'xs']}>
        <div
          style={{
            background: 'url(/images/bg/3.jpg) no-repeat center center',
            backgroundSize: 'cover',
            minHeight: 700,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Container classes={{ root: classes.containerroot }} maxWidth="md">
            <Grid style={{ marginTop: 0 }} container spacing={0}>
              <Grid item xs={12} md={6}></Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ color: 'white', textAlign: 'center' }}
              >
                <h1 style={{ fontWeight: 'normal', marginBottom: 0 }}>
                  936 LAE
                </h1>
                <h1
                  style={{
                    fontWeight: 'normal',
                    paddingTop: 0,
                    marginTop: 0,
                    marginBottom: 50,
                  }}
                >
                  Active Water Treatment
                </h1>

                <h2 style={{ fontWeight: 'normal', marginBottom: 20 }}>
                  Treatment Benefits
                </h2>

                <Grid
                  style={{ marginTop: 40, marginBottom: 40 }}
                  container
                  spacing={0}
                >
                  <Grid item xs={6} md={4}>
                    <img
                      src="/images/icon/laewater/1.png"
                      style={{ height: 70 }}
                    />
                    <br />
                    Enhances Body Metabolism & Blood Circulation
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <img
                      src="/images/icon/laewater/2.png"
                      style={{ height: 70 }}
                    />
                    <br />
                    Experience Bliss & Reduce Stress
                  </Grid>
                  <Hidden only={['sm', 'xs']}>
                    <Grid item xs={12} md={4}>
                      <img
                        src="/images/icon/laewater/3.png"
                        style={{ height: 70 }}
                      />
                      <br />
                      Slow Down & Reverse Aging
                    </Grid>
                  </Hidden>
                </Grid>
                <Hidden only={['md', 'lg', 'xl']}>
                  <Grid
                    style={{ marginTop: 40, marginBottom: 40 }}
                    container
                    spacing={0}
                  >
                    <Grid item xs={12} md={4}>
                      <img
                        src="/images/icon/laewater/3.png"
                        style={{ height: 70 }}
                      />
                      <br />
                      Slow Down & Reverse Aging
                    </Grid>
                  </Grid>
                </Hidden>

                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  component={Link2}
                  to="/936-lae-active-water-treatment-benefits"
                >
                  Learn More
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Hidden>

      <div
        style={{
          background: 'url(/images/bg/4.jpg) no-repeat center center',
          backgroundSize: 'cover',
          minHeight: 700,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Container classes={{ root: classes.containerroot }} maxWidth="md">
          <Grid style={{ marginTop: 0 }} container spacing={0}>
            <Grid
              item
              xs={12}
              md={12}
              style={{ color: 'white', textAlign: 'center' }}
            >
              <h1
                style={{ fontWeight: 'normal', marginBottom: 0, marginTop: 50 }}
              >
                Services
              </h1>
            </Grid>
          </Grid>
          <Grid style={{ marginTop: 0 }} container spacing={0}>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                color: 'white',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div style={{ maxWidth: 350 }}>
                <img src="/images/icon/LAEroom.png" style={{ height: 220 }} />
                <br />
                <h2
                  style={{
                    fontWeight: 'bold',
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                >
                  936 LAE Energy
                  <br />
                  Room Treatment
                </h2>
                <p style={{ marginBottom: 20 }}>
                  The convenience of moderm living has greatly improved our
                  lives, but these creature comfort might not necessarily be all
                  that great to our health.
                </p>
                <Button
                  component={Link2}
                  to="/booking"
                  style={{ marginBottom: 60 }}
                  variant="contained"
                  size="large"
                  color="primary"
                >
                  Booking Now
                </Button>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              style={{
                color: 'white',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div style={{ maxWidth: 350 }}>
                <img src="/images/icon/LAEwater.png" style={{ height: 220 }} />
                <br />
                <h2
                  style={{
                    fontWeight: 'bold',
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                >
                  936 LAE Active
                  <br />
                  Water Treatment
                </h2>
                <p style={{ marginBottom: 20 }}>
                  "LAE ACTIVE WATER" can stimulate blood circulation, bring
                  sufficient oxygen to the blood, improve the skin's metabolic
                  capacity, moisturize skin and eliminate fatigue; Prevent and
                  improves scalp pruritus, dandruff and hair-fall problems.
                </p>
                <Button
                  component={Link2}
                  to="/booking"
                  style={{ marginBottom: 60 }}
                  variant="contained"
                  size="large"
                  color="primary"
                >
                  Booking Now
                </Button>
              </div>
            </Grid>

            {/* <Grid
              item
              xs={12}
              md={4}
              style={{
                color: 'white',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div style={{ maxWidth: 350 }}>
                <img
                  src="/images/icon/quantummagnetic.png"
                  style={{ height: 220 }}
                />
                <br />
                <h2
                  style={{
                    fontWeight: 'bold',
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                >
                  Quantum Magnetic
                  <br />
                  Resonance Analyser
                </h2>
                <p style={{ marginBottom: 20 }}>
                  We help clients deliver more effective and efficient 40 types
                  general health examination with quantum magnetic resonance
                  health analyzer.
                </p>
                <Button
                  component={Link2}
                  to="/booking"
                  style={{ marginBottom: 60 }}
                  variant="contained"
                  size="large"
                  color="primary"
                >
                  Booking Now
                </Button>
              </div>
            </Grid> */}
          </Grid>
        </Container>
      </div>

      <div
        style={{
          background: 'url(/images/bg/5.jpg) no-repeat center center',
          backgroundSize: 'cover',
          minHeight: 700,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Container classes={{ root: classes.containerroot }} maxWidth="md">
          <Grid style={{ marginTop: 50 }} container spacing={0}>
            <Grid
              item
              xs={12}
              md={12}
              style={{ color: 'white', textAlign: 'center' }}
            >
              <h1 style={{ fontWeight: 'normal', marginBottom: 0 }}>
                Promotion
              </h1>
              {/* <h3
                style={{
                  fontWeight: 'normal',
                  paddingTop: 0,
                  marginTop: 0,
                  marginBottom: 50,
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </h3> */}
            </Grid>
          </Grid>
          <Grid style={{ marginTop: 0 }} container spacing={0}>
            {/* <Grid
              item
              xs={12}
              md={4}
              style={{
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div style={{ maxWidth: 300 }}>
                <div style={{ textAlign: 'center' }}>
                  <img src="/images/products/c.png" style={{ height: 220 }} />
                </div>
                <br />
                <h2
                  style={{
                    fontWeight: 'bold',
                    marginBottom: 20,
                    marginTop: 20,
                    textAlign: 'center',
                  }}
                >
                  ELIFE
                </h2>
                <p style={{ marginBottom: 20, textAlign: 'left' }}>
                  Lorem ipsum dolor sit amet, consectetur consectetur
                </p>
                <Chip
                  size="small"
                  color="primary"
                  component={Link2}
                  to="/booking"
                  label="Health Series"
                  style={{ cursor: 'pointer', marginBottom: 80 }}
                />
              </div>
            </Grid> */}

            <Grid
              item
              xs={12}
              md={6}
              style={{
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div style={{ maxWidth: 300 }}>
                <div style={{ textAlign: 'center' }}>
                  <img src="/images/products/b.png" style={{ height: 220 }} />
                </div>
                <br />
                <h2
                  style={{
                    fontWeight: 'bold',
                    marginBottom: 20,
                    marginTop: 20,
                    textAlign: 'center',
                  }}
                >
                  936 Wellness Retreat Mist
                </h2>
                <p style={{ marginBottom: 20, textAlign: 'left' }}>
                  When 936 Wellness Retreat Mist is applied, the hydrogen bond
                  between water molecules will be broken due to Resonance.
                </p>

                <Chip
                  size="small"
                  color="primary"
                  component={Link2}
                  to="/shop/37/936-Wellness-Retreat-Mist"
                  label="Health Series"
                  style={{ cursor: 'pointer', marginBottom: 80 }}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div style={{ maxWidth: 300 }}>
                <div style={{ textAlign: 'center' }}>
                  <img src="/images/products/a.png" style={{ height: 220 }} />
                </div>
                <br />
                <h2
                  style={{
                    fontWeight: 'bold',
                    marginBottom: 20,
                    marginTop: 20,
                    textAlign: 'center',
                  }}
                >
                  936 Beauty Mist
                </h2>
                <p
                  style={{
                    marginBottom: 20,
                    textAlign: 'left',
                  }}
                >
                  936 Beauty Mist can form 9.36μm light waves similar to the
                  body cells and organs, which improves blood circulation and
                  vitality.
                </p>
                <Chip
                  size="small"
                  color="primary"
                  component={Link2}
                  to="/shop/34/936-Beauty-Mist"
                  label="Beauty Series"
                  style={{ cursor: 'pointer', marginBottom: 80 }}
                />
              </div>
            </Grid>
          </Grid>
          {/* <Grid
            style={{ marginTop: 0, marginBottom: 50 }}
            container
            spacing={0}
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{ color: 'white', textAlign: 'center' }}
            >
              <ToggleButtonGroup */}
          {/* value={alignment} */}
          {/* exclusive */}
          {/* onChange={handleAlignment} */}
          {/* aria-label="text alignment"
              >
                <ToggleButton
                  component={Button}
                  variant="contained"
                  value="left"
                  aria-label="left aligned"
                >
                  PREV
                </ToggleButton>
                <ToggleButton
                  component={Button}
                  variant="contained"
                  value="justify"
                  aria-label="justified"
                >
                  NEXT
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid> */}
        </Container>
      </div>

      <div
        style={{
          background: 'url(/images/bg/6.jpg) no-repeat center center',
          backgroundSize: 'cover',
          minHeight: 700,
          display: 'flex',
          alignItems: 'top',
        }}
      >
        <Container classes={{ root: classes.containerroot }} maxWidth="md">
          <Grid style={{ marginTop: 0 }} container spacing={0}>
            <Grid
              item
              xs={12}
              md={12}
              style={{ color: 'white', textAlign: 'center' }}
            >
              <h1
                style={{ fontWeight: 'normal', marginBottom: 0, marginTop: 50 }}
              >
                Products
              </h1>
              {/* <h3
                style={{
                  fontWeight: 'normal',
                  paddingTop: 0,
                  marginTop: 0,
                  marginBottom: 50,
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </h3> */}
            </Grid>
          </Grid>
          <Grid style={{ marginTop: 15 }} container spacing={0}>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                color: 'white',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Tabs
                value={valueProduct}
                onChange={handleChangeProduct}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab
                  style={{ color: 'white' }}
                  label="936 Wellness Retreat Mist"
                />
                <Tab style={{ color: 'white' }} label="936 Beauty Mist" />
                {/* <Tab style={{ color: 'white' }} label="ELIFE" /> */}
              </Tabs>
            </Grid>

            <br />
            <br />
            <div
              style={{
                minHeight: 500,
              }}
            >
              <TabPanel value={valueProduct} index={0}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    marginTop: 20,
                    color: 'white',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Grid style={{ marginTop: 0 }} container spacing={0}>
                    <Grid
                      item
                      xs={12}
                      md={5}
                      style={{ color: 'white', textAlign: 'center' }}
                    >
                      <Hidden only={['sm', 'xs']}>
                        <img
                          src="/images/products/b.png"
                          style={{ maxHeight: 308 }}
                        />
                      </Hidden>

                      <Hidden only={['md', 'lg', 'xl']}>
                        <img
                          src="/images/products/b.png"
                          style={{ maxHeight: 208 }}
                        />
                      </Hidden>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={7}
                      style={{ color: 'white', textAlign: 'left' }}
                    >
                      <h2
                        style={{
                          fontWeight: 'bold',
                          marginBottom: 20,
                          marginTop: 0,
                        }}
                      >
                        936 Wellness Retreat Mist
                      </h2>
                      <p style={{ marginBottom: 20 }}>
                        When 936 Wellness Retreat Mist is applied, the hydrogen
                        bond between water molecules will be broken due to
                        Resonance.
                        <br />
                        <br />
                        Big water molecular cluster are now broken down into
                        small water molecular cluster with a larger surface
                        area.
                        <br />
                        <br />
                        This increases the water absorption rate in our body.
                      </p>
                      <Button
                        component={Link2}
                        to="/shop/37/936-Wellness-Retreat-Mist"
                        style={{ marginBottom: 40 }}
                        variant="contained"
                        size="large"
                        color="primary"
                      >
                        Buy Now
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={valueProduct} index={1}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    marginTop: 20,
                    color: 'white',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Grid style={{ marginTop: 0 }} container spacing={0}>
                    <Grid
                      item
                      xs={12}
                      md={5}
                      style={{ color: 'white', textAlign: 'center' }}
                    >
                      <Hidden only={['sm', 'xs']}>
                        <img
                          src="/images/products/a.png"
                          style={{ maxHeight: 308 }}
                        />
                      </Hidden>

                      <Hidden only={['md', 'lg', 'xl']}>
                        <img
                          src="/images/products/a.png"
                          style={{ maxHeight: 208 }}
                        />
                      </Hidden>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={7}
                      style={{ color: 'white', textAlign: 'left' }}
                    >
                      <h2
                        style={{
                          fontWeight: 'bold',
                          marginBottom: 20,
                          marginTop: 0,
                        }}
                      >
                        936 Beauty Mist
                      </h2>
                      <p style={{ marginBottom: 20 }}>
                        936 Beauty Mist can form 9.36μm light waves similar to
                        the body cells and organs, which improves blood
                        circulation and vitality.
                        <br />
                        <br />
                        The 936 Beauty Mist has strong moisturizing nutrients
                        with powerful moisturizing effect, It has multi-effect
                        moisturizing factors and strong vitality.
                        <br />
                        <br />
                        It is very nutritious and also a rare anti-frustration
                        ingredient.
                        <br />
                        <br />
                        936 Beauty Mist can penetrate and moisturize from the
                        surface, middle and deep layers of the skin with its
                        magical moisturizing effect.
                      </p>
                      <Button
                        component={Link2}
                        to="/shop/34/936-Beauty-Mist"
                        style={{ marginBottom: 40 }}
                        variant="contained"
                        size="large"
                        color="primary"
                      >
                        Buy Now
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
              {/* <TabPanel value={valueProduct} index={2}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    marginTop: 20,
                    color: 'white',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Grid style={{ marginTop: 0 }} container spacing={0}>
                    <Grid
                      item
                      xs={12}
                      md={5}
                      style={{ color: 'white', textAlign: 'center' }}
                    >
                      <Hidden only={['sm', 'xs']}>
                        <img
                          src="/images/products/c.png"
                          style={{ maxHeight: 308 }}
                        />
                      </Hidden>

                      <Hidden only={['md', 'lg', 'xl']}>
                        <img
                          src="/images/products/c.png"
                          style={{ maxHeight: 208 }}
                        />
                      </Hidden>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={7}
                      style={{ color: 'white', textAlign: 'left' }}
                    >
                      <h2
                        style={{
                          fontWeight: 'bold',
                          marginBottom: 20,
                          marginTop: 50,
                        }}
                      >
                        ELIFE
                      </h2>
                      <p style={{ marginBottom: 20 }}>
                        Experience the finest skin care product, specially
                        developed based on our core technology LAE Light
                        Activated Energy.
                        <br />
                        <br />
                        A beauty secret finally revealed. The world's first
                        beauty and healthcare 2-in-1 product.
                        <br />
                        6 substantial efficacies and 4 unique elements are all
                        you nned to instantly restores skin elasticity.
                        <br />
                        <br />
                        ezurose, a rose hidden within the light.
                      </p>
                      <Button
                        component={Link2}
                        to="/shop/35/wte-beauty-series"
                        style={{ marginBottom: 40 }}
                        variant="contained"
                        size="large"
                        color="primary"
                      >
                        Buy Now
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel> */}
            </div>
          </Grid>
          <br />
          <br />
        </Container>
      </div>

      <div
        style={{
          background: 'url(/images/bg/7.jpg) no-repeat center center',
          backgroundSize: 'cover',
          minHeight: 700,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Container classes={{ root: classes.containerroot }} maxWidth="md">
          <Grid style={{ marginTop: 50 }} container spacing={0}>
            <Grid
              item
              xs={12}
              md={12}
              style={{ color: 'white', textAlign: 'center' }}
            >
              <h1 style={{ fontWeight: 'normal', marginBottom: 0 }}>
                Testimonial
              </h1>
              {/*  <h3
                style={{
                  fontWeight: 'normal',
                  paddingTop: 0,
                  marginTop: 0,
                  marginBottom: 50,
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </h3> */}
            </Grid>
          </Grid>
          {/* <Grid style={{ marginTop: 0 }} container spacing={0}>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                color: 'white',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div style={{ maxWidth: 250 }}>
                <img src="/images/icon/LAEroom.png" style={{ height: 220 }} />
                <br />
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              style={{
                color: 'white',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div style={{ maxWidth: 250 }}>
                <img src="/images/icon/LAEroom.png" style={{ height: 220 }} />
                <br />
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              style={{
                color: 'white',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div style={{ maxWidth: 250 }}>
                <img src="/images/icon/LAEroom.png" style={{ height: 220 }} />
                <br />
              </div>
            </Grid>
          </Grid> */}
          {/* <Grid
            style={{ marginTop: 50, marginBottom: 50 }}
            container
            spacing={0}
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{ color: 'white', textAlign: 'center' }}
            >
              <ToggleButtonGroup
                exclusive
                aria-label="text alignment"
              >
                <ToggleButton
                  component={Button}
                  variant="contained"
                  value="left"
                  aria-label="left aligned"
                >
                  PREV
                </ToggleButton>
                <ToggleButton
                  component={Button}
                  variant="contained"
                  value="justify"
                  aria-label="justified"
                >
                  NEXT
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid> */}
          <h1 style={{ textAlign: 'center' }}>Coming Soon</h1>
        </Container>
      </div>

      <div
        style={{
          background:
            'url(/images/bg/energybalance.jpg) no-repeat center center',
          backgroundSize: 'cover',
          height: 200,
          display: 'flex',
          alignItems: 'center',
          textTransform: 'uppercase',
          color: 'white',
        }}
      >
        <h3
          style={{ fontWeight: 'normal', width: '100%', textAlign: 'center' }}
        >
          Derived from the concept of "energy balance",
          <br />
          the world's first
          <br />
          "light activated energy"
        </h3>
      </div>

      <div
        style={{
          background: 'url(/images/bg/8.jpg) no-repeat center center',
          backgroundSize: 'cover',
          minHeight: 700,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Container classes={{ root: classes.containerroot }} maxWidth="md">
          <Grid style={{ marginTop: 0 }} container spacing={10}>
            <Grid item xs={12} style={{ textAlign: 'center' }} md={6}>
              <Hidden only={['sm', 'xs']}>
                <img
                  src="/images/icon/wellnessscientific.png"
                  style={{ maxHeight: 400 }}
                />
              </Hidden>

              <Hidden only={['md', 'lg', 'xl']}>
                <img
                  src="/images/icon/wellnessscientific.png"
                  style={{ maxHeight: 300 }}
                />
              </Hidden>

              <Grid style={{ marginTop: 0 }} container spacing={0}>
                <Grid item xs={12} md={4}>
                  <h3 style={{ marginTop: 0, color: 'white' }}>
                    Detoxification
                  </h3>
                </Grid>
                <Grid item xs={12} md={4}>
                  <h3 style={{ marginTop: 0, color: 'white' }}>
                    Deep Relaxation
                  </h3>
                </Grid>
                <Grid item xs={12} md={4}>
                  <h3 style={{ marginTop: 0, color: 'white' }}>Healthy Life</h3>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
              <div style={{ padding: 20 }}>
                <img
                  src="/images/icon/logowhite.png"
                  style={{ maxHeight: 100 }}
                />
                <h3
                  style={{
                    marginTop: 50,
                    letterSpacing: 10,
                    textTransform: 'uppercase',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  Subscribe us today
                </h3>
                <h1
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  Stay Up to Date
                </h1>

                <br />
                <br />
                <FormControl fullWidth>
                  <InputLabel shrink htmlFor="SubscribeEmail"></InputLabel>
                  <InputBase
                    classes={{
                      root: classes.rootBootstrap,
                      input: classes.inputBootstrap,
                    }}
                    id="SubscribeEmail"
                    name="SubscribeEmail"
                    label="SubscribeEmail"
                    fullWidth
                    value={subEmail}
                    margin="dense"
                    onChange={(e) => {
                      setSubEmail(e.target.value)
                    }}
                    autoComplete="off"
                    inputProps={{
                      maxLength: 200,
                      type: 'email',
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        {' '}
                        <IconButton
                          style={{
                            position: 'absolute',
                            right: 0,
                            marginRight: 10,
                          }}
                          aria-label="toggle password visibility"
                          onClick={() => {
                            const re =
                              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            if (re.test(subEmail)) {
                              subscribeMail({
                                variables: {
                                  Email: subEmail,
                                },
                              })
                            } else {
                              enqueueSnackbar(
                                'Please provide a valid email address',
                                {
                                  variant: 'error',
                                }
                              )
                            }
                          }}
                          /* onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword} */
                          edge="end"
                        >
                          <ArrowForwardIcon style={{ color: 'white' }} />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText
                    classes={{
                      root: classes.rootBootstrapHelpertext,
                    }}
                  >
                    <span>Get the latest news directly to your inbox</span>
                  </FormHelperText>
                </FormControl>
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      {/* <Container classes={{ root: classes.containerroot }} maxWidth="md">
        <div>
          <div className={classes.root}>
            <div style={{ display: 'flex' }}>
              <div className={classes.paddingleft} style={{ flexGrow: 1 }}>
                {product && (
                  <ShopDetails
                    viewLocation={viewLocation}
                    setBackProductID={setBackProductID}
                    setBack={setBack}
                    executeScroll={executeScroll}
                    carts={carts}
                    setSelectedProductID={setSelectedProductID}
                    selectedProductID={selectedProductID}
                    setProduct={setProduct}
                    data={product}
                    insertCart={insertCart}
                    updateCart={updateCart}
                    removeCart={removeCart}
                  />
                )}
                
                {!loadingProducts && !product && (
                  <React.Fragment>
                    <Hidden only={['md', 'lg', 'xl']}>
                      {products.map(
                        (product, i) =>
                          product.Category == 1 && (
                            <Card
                              key={i}
                              elevation={0}
                              ref={myRefs.current[product.id]}
                              variant="outlined"
                              style={{
                                marginBottom: 8,
                                padding: 5,
                                border: carts.find(
                                  (a) => a.ProductID == product.id
                                )
                                  ? `1px solid ${shopconfig.COLORPRIMARY}`
                                  : '1px solid #b4b4b4',
                                border: carts.find(
                                  (a) => a.ProductID == product.id
                                )
                                  ? `1px solid ${shopconfig.COLORPRIMARY}`
                                  : '1px solid #b4b4b4',
                              }}
                              classes={{ root: classes.card }}
                            >
                              <Grid container spacing={1} key={i}>
                                <Grid item xs={4}>
                                  <Link
                                    style={{
                                      display: 'block',
                                      fontSize: 0,
                                      padding: 0,
                                      margin: 0,
                                      cursor: 'pointer',
                                    }}
                                    href=""
                                    onClick={(e) => {
                                      e.preventDefault()
                                      product.ProductID = product.id
                                      setProductMobile(product)
                                      setOpenProduct(true)
                                    }}
                                  >
                                    <PreloadImage
                                      disableTransition={true}
                                      disableSpinner={true}
                                      style={{
                                        height: 150,
                                        position: 'relative',
                                      }}
                                      src={`${restApi}/media/${dbhost}/uploads/invoice/${product.ProductImage}`}
                                    />
                                  </Link>
                                </Grid>
                                <Grid item xs={8}>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <div
                                        style={{
                                          fontSize: 17,
                                          fontWeight: 'bold',
                                          color: '#cf242a',
                                          marginTop: 0,
                                          marginBottom: 3,
                                          lineHeight: '1.2',
                                        }}
                                      >
                                        <Link
                                          href=""
                                          onClick={(e) => {
                                            e.preventDefault()
                                            product.ProductID = product.id
                                            setProductMobile(product)
                                            setOpenProduct(true)
                                          }}
                                        >
                                          {product.ProductName}
                                        </Link>
                                      </div>

                                    </Grid>
                                    <Grid item xs={12}>
                                      <div>
                                        {product.ProductPrices &&
                                          product.ProductPrices.split(',').map(
                                            (price, i) => {
                                              if (
                                                i != 0 &&
                                                i !=
                                                  product.ProductPrices.split(
                                                    ','
                                                  ).length -
                                                    1
                                              )
                                                return

                                              const priceitem = price.split('|')
                                              return (
                                                <span key={i}>
                                                  {i ==
                                                    product.ProductPrices.split(
                                                      ','
                                                    ).length -
                                                      1 &&
                                                    product.ProductPrices.split(
                                                      ','
                                                    ).length > 1 && (
                                                      <span> - </span>
                                                    )}
                                                  <span
                                                    style={{
                                                      fontWeight: 'bold',
                                                      fontSize: 16,
                                                      display: 'inline-block',
                                                    }}
                                                  >
                                                    {priceitem[0]}
                                                  </span>
                                                </span>
                                              )
                                            }
                                          )}
                                      </div>
                                    </Grid>

                                   
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Card>
                          )
                      )}
                    </Hidden>
                  </React.Fragment>
                )}
                {!product && (
                  <Hidden only={['sm', 'xs']}>
                    <Grid
                      style={{ marginTop: 0, marginBottom: 80 }}
                      container
                      spacing={1}
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      {products.map(
                        (product, i) =>
                          product.Category == 1 && (
                            <Grid
                              ref={myRefs.current[product.id]}
                              item
                              xs={6}
                              sm={6}
                              md={3}
                              key={i}
                            >
                              <Link2
                                style={{ textDecoration: 'none' }}
                                to={`/shop/${product.id}/${product.PrettyUrl}`}
                              >
                                <Card
                                  elevation={0}
                                  variant="outlined"
                                  style={{
                                    cursor: 'pointer',
                                    border: carts.find(
                                      (a) => a.ProductID == product.id
                                    )
                                      ? `1px solid ${shopconfig.COLORPRIMARY}`
                                      : '1px solid #b4b4b4',
                                    borderBottom: carts.find(
                                      (a) => a.ProductID == product.id
                                    )
                                      ? `5px solid ${shopconfig.COLORPRIMARY}`
                                      : '1px solid #b4b4b4',
                                  }}
                                  classes={{ root: classes.card }}
                                >
                                  <PreloadImage
                                    disableTransition={true}
                                    disableSpinner={true}
                                    style={{
                                      height: 400,
                                      position: 'relative',
                                    }}
                                    src={`${restApi}/media/${dbhost}/uploads/invoice/${product.ProductImage}`}
                                    title={product.ProductName}
                                    onClick={() => {
                                     
                                    }}
                                  />
                                  <CardContent
                                    style={{
                                      paddingBottom: 0,
                                      paddingTop: 0,
                                      paddingLeft: 8,
                                      paddingRight: 8,
                                    }}
                                  >
                                    <div
                                      style={{
                                        lineHeight: '1.125rem',
                                        height: '2rem',
                                        fontSize: 18,
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        display: '-webkit-box',
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: '2',
                                        color: '#00acee',
                                      }}
                                    >
                                      {`${product.ProductName}`}

                                    </div>
                                    <div
                                      style={{
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {product.ProductPrices &&
                                        product.ProductPrices.split(',').map(
                                          (price, i) => {
                                            if (
                                              i != 0 &&
                                              i !=
                                                product.ProductPrices.split(',')
                                                  .length -
                                                  1
                                            )
                                              return

                                            const priceitem = price.split('|')
                                            const currency = priceitem[0].split(
                                              ' '
                                            )[0]
                                            const singleprice = priceitem[0].split(
                                              ' '
                                            )[1]
                                            return (
                                              <React.Fragment>
                                                <span key={i}>
                                                  {i ==
                                                    product.ProductPrices.split(
                                                      ','
                                                    ).length -
                                                      1 &&
                                                    product.ProductPrices.split(
                                                      ','
                                                    ).length > 1 && (
                                                      <span> - </span>
                                                    )}
                                                  <span
                                                    style={{
                                                      fontSize: 10,
                                                      color: 'black',
                                                    }}
                                                  >
                                                    {currency}
                                                  </span>
                                                  <span
                                                    style={{
                                                      fontSize: 14,
                                                      color: 'black',
                                                    }}
                                                  >
                                                    {singleprice}
                                                  </span>
                                                  {}
                                                </span>
                                              </React.Fragment>
                                            )
                                          }
                                        )}
                                      
                                    </div>
                                   
                                  </CardContent>
                                </Card>
                              </Link2>
                            </Grid>
                          )
                      )}
                    </Grid>
                  </Hidden>
                )}
               
              </div>
              
            </div>
          </div>
        </div>

        <ProductDialog
          openProduct={openProduct}
          setOpenProduct={setOpenProduct}
          selectedProductID={selectedProductID}
          setSelectedProductID={setSelectedProductID}
          product={productMobile}
          setProduct={setProductMobile}
        />
      </Container> */}
    </React.Fragment>
  )
}
