import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'

import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Button from '@material-ui/core/Button'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { UPDATE_CART, GET_CARTS, REMOVE_CART } from '../graphql/cart'
import { GET_SHOPCONFIG } from '../graphql/config'
import { useEffect } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
const restApi = process.env.REACT_APP_API

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%',
    maxWidth: '36ch',
  },
  listitem: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: '#ffffff',
  },
  inline: {
    display: 'inline',
  },
  buttonRoot: {
    minWidth: 0,
    marginRight: 5,
    padding: 7,
  },
  textfieldRoot: {
    marginTop: 0,
    marginRight: 5,
    width: 100,
  },
  image: {
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  paddingleft: {
    padding: 10,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
    },
  },
  iconbuttonroot: {
    padding: 0,
  },
}))

export default function TrolleyItem(props) {
  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const classes = useStyles(shopconfig)
  const [dbhost, setDbhost] = useState('spa1')
  const [selectedProductID, setSelectedProductID] = useState()
  const [disableSelect, setDisableSelect] = useState()

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })
    const latest = data.updatecart
    const foundIndex = old.carts.findIndex((item) => item.id === latest.id)
    old.carts.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_CARTS,
      data: { carts: [...old.carts] },
    })

    props.setOpen(false)
    props.setSelected([])
    props.setProduct()
  }

  const [updateCart] = useMutation(UPDATE_CART, { update: updateCache })

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })
    if (data.removecart) {
      const latest = old.carts.filter(
        (item) => item.PriceID != selectedProductID
      )
      cache.writeQuery({
        query: GET_CARTS,
        data: { carts: [...latest] },
      })
    }
    setSelectedProductID()
  }

  const [removeCart] = useMutation(REMOVE_CART, { update: deleteCache })

  const truncate = (str, n) =>
    str.length > n ? str.substr(0, n - 1) + '...' : str

  useEffect(() => {
    //console.log(props)

    {
      props.data.ProductPrices &&
        props.data.ProductPrices.split(',').map((price, i) => {
          const priceitem = price.split('|')
          if (priceitem[2] == props.data.PriceID) {
            setDisableSelect(false)
          }
        })
    }
  }, [props])

  return (
    <Grid container>
      <Grid item xs={4} md={2}>
        <Hidden only={['md', 'lg', 'xl']}>
          <img
            onClick={() => {
              props.setProduct(props.data)
              props.setOpenProduct(true)
            }}
            style={{
              width: '100%',
              paddingRight: 10,
              margin: 0,
              height: 'auto',
            }}
            className={classes.image}
            src={`${restApi}/media/${dbhost}/uploads/invoice/${props.data.ProductImage}`}
          />
        </Hidden>
        <Hidden only={['sm', 'xs']}>
          <img
            style={{
              width: '100%',
              paddingRight: 10,
              margin: 0,
              height: 'auto',
            }}
            className={classes.image}
            src={`${restApi}/media/${dbhost}/uploads/invoice/${props.data.ProductImage}`}
          />
        </Hidden>
      </Grid>
      <Grid item xs={8} md={10}>
        {/* <IconButton
          size="small"
          style={{ position: 'absolute', right: 0, top: -2 }}
          onClick={() => {
            if (props.data.Qty > 1) {
              updateCart({
                variables: {
                  ProductID: props.data.ProductID,
                  PriceID: props.data.PriceID,
                  Qty: props.data.Qty - 1,
                },
              })
            } else {
              setSelectedProductID(props.data.ProductID)
              setTimeout(() => {
                removeCart({
                  variables: {
                    id: props.data.ProductID,
                  },
                })
              }, 1)
            }
          }}
          aria-label="delete"
        >
          <CloseIcon />
        </IconButton> */}
        <div
          style={{
            fontSize: 17,
            fontWeight: 'bold',
            color: shopconfig.COLORPRIMARY,
            marginTop: 0,
            marginBottom: 0,
            lineHeight: '1.2',
          }}
        >
          <span>{props.data.ProductName}</span>
        </div>
        <Grid container>
          <Grid item xs={12} md={8}>
            {props.data.ProductPrices &&
              props.data.ProductPrices.split(',').map((price, i) => {
                const priceitem = price.split('|')
                if (priceitem[2] == props.data.PriceID)
                  return (
                    <div key={i}>
                      <span
                        style={{
                          fontWeight: 'bold',
                          fontSize: 14,
                          display: 'inline-block',
                          paddingRight: 0,
                        }}
                      >
                        {priceitem[4]}
                      </span>
                    </div>
                  )
              })}
          </Grid>
          <Grid item xs={12} md={4} style={{ textAlign: 'right' }}>
            {!disableSelect && !props.disableSelect && (
              <React.Fragment>
                <IconButton
                  size="medium"
                  edge={false}
                  classes={{
                    root: classes.iconbuttonroot,
                  }}
                  onClick={() => {
                    if (props.data.Qty > 1) {
                      updateCart({
                        variables: {
                          ProductID: props.data.ProductID,
                          PriceID: props.data.PriceID,
                          Qty: props.data.Qty - 1,
                        },
                      })
                    } else {
                      setSelectedProductID(props.data.PriceID)
                      setTimeout(() => {
                        removeCart({
                          variables: {
                            id: props.data.ProductID,
                            PriceID: props.data.PriceID,
                          },
                        })
                      }, 1)
                    }
                  }}
                >
                  <RemoveCircleOutlineIcon
                    style={{
                      fontSize: 32,
                      color: shopconfig.COLORPRIMARY,
                    }}
                  />
                </IconButton>

                <span
                  style={{
                    width: 25,
                    textAlign: 'center',
                    display: 'inline-block',
                    fontSize: 14,
                    fontWeight: 'bold',
                  }}
                >
                  {props.data.Qty}
                </span>
                <IconButton
                  size="medium"
                  edge={false}
                  classes={{
                    root: classes.iconbuttonroot,
                  }}
                  onClick={() => {
                    updateCart({
                      variables: {
                        ProductID: props.data.ProductID,
                        PriceID: props.data.PriceID,
                        Qty: props.data.Qty + 1,
                      },
                    })
                  }}
                >
                  <AddCircleIcon
                    style={{
                      fontSize: 32,
                      color: shopconfig.COLORPRIMARY,
                    }}
                  />
                </IconButton>
              </React.Fragment>
            )}

            {props.disableSelect && (
              <React.Fragment>
                <h3
                  style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    margin: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                    textAlign: 'right',
                    paddingRight: 3,
                  }}
                >
                  <span style={{ fontWeight: 'normal' }}>Qty : </span>
                  {props.data.Qty}
                </h3>
              </React.Fragment>
            )}

            {/* <div
              style={{
                textAlign: 'right',
              }}
            >
              <h3
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  margin: 0,
                  paddingTop: 2,
                  paddingBottom: 0,
                  color: shopconfig.COLORPRIMARY,
                  textAlign: 'right',
                }}
              >
                {props.data.Qty} in trolley
              </h3>
            </div> */}
          </Grid>

          <Grid item xs={12} md={12} style={{ textAlign: 'right' }}>
            <h3
              style={{
                fontSize: 16,
                fontWeight: 'bold',
                margin: 0,
                paddingTop: 0,
                paddingBottom: 0,
                textAlign: 'right',
                paddingRight: 3,
              }}
            >
              <span style={{ fontWeight: 'normal' }}>Total : </span>
              RM {parseFloat(props.data.InvoiceAmount).toFixed(2)}
            </h3>
          </Grid>
        </Grid>
      </Grid>
      {/* 
        <TextField
          name="Quantity"
          label=""
          classes={{ root: classes.textfieldRoot }}
          margin="dense"
          variant="outlined"
          value={props.data.Qty}
          type="number"
          autoComplete="off"
          onChange={(e) => {
            if (e.target.value)
              updateCart({
                variables: {
                  ProductID: props.data.ProductID,
                  PriceID: props.data.PriceID,
                  Qty: parseInt(e.target.value),
                },
              })
          }}
          inputProps={{ min: '0', step: 'any' }}
        /> */}
    </Grid>
  )
}
