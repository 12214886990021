import React, { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import useForm from 'react-hook-form'
import { useSnackbar } from 'notistack'
import Cookies from 'js-cookie'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { useHistory } from 'react-router-dom'

import CssBaseline from '@material-ui/core/CssBaseline'

import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import TextFieldDisplay from '../common/textFieldDisplay'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { GET_SHOPCONFIG } from '../graphql/config'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { UPDATE_BUYER, GET_BUYER, INSERT_BUYER } from '../graphql/buyer'
import { GET_POSTCODES, GET_TOWNS } from '../graphql/postcode'
import { GET_BRANCHES } from '../graphql/branch'
import { useEffect } from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  paperroot: {
    padding: theme.spacing(2),
    border: '1px solid #b4b4b4',
  },
  toolbar: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  dividerRoot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  margin: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  containerroot: {
    paddingTop: 0,
    paddingBottom: 50,
    paddingLeft: 0,
    paddingRight: 0,
  },
  margin2: {
    width: '100%',
    marginTop: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative' /* 
    backgroundColor: theme.palette.common.white, */,
    backgroundColor: 'transparent',
    border: '2px solid #ced4da',
    width: '100%',
    padding: '13px 10px 10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderColor: (props) => `${props.COLORPRIMARY}`,
    },
  },
}))

export default function CustomizedSteppers(props) {
  const history = useHistory()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true,
  })

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const classes = useStyles(shopconfig)

  const { handleSubmit, register, errors, setError } = useForm()
  const [countryState, setCountryState] = React.useState('')

  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [postCode, setPostCode] = useState('')
  const [country, setCountry] = useState('Malaysia')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [branch, setBranch] = useState()

  const { loading: loadingTowns, data: { towns } = { towns: [] } } =
    useQuery(GET_TOWNS)

  const { data: { branches } = { branches: [] }, refetch: refetchBranches } =
    useQuery(GET_BRANCHES)

  const { loading: loadingPostcodes, data: { postcodes } = { postcodes: [] } } =
    useQuery(GET_POSTCODES, { variables: { town: city }, skip: !city })

  const addCache = (cache, { data }) => {
    const latest = data.insertBuyer

    if (latest.Error == 'Username exist') {
      setError(
        'Username',
        true,
        'Mobile Number exist. Try another Mobile Number.'
      )
      return
    }

    enqueueSnackbar('Account created, please login to complete', {
      variant: 'success',
    })

    history.push('/login')
  }

  const [insertBuyer] = useMutation(INSERT_BUYER, {
    update: addCache,
  })

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_BUYER,
    })
    const latest = data.updateBuyer
    cache.writeQuery({
      query: GET_BUYER,
      data: { buyer: { ...latest } },
    })

    enqueueSnackbar('Account updated', {
      variant: 'success',
    })
  }

  const [updateBuyer] = useMutation(UPDATE_BUYER, { update: updateCache })

  const { loading: loadingBuyer, data: { buyer } = { buyer: { id: null } } } =
    useQuery(GET_BUYER, { skip: !Cookies.get('signedin') })

  const onSubmit = (values) => {
    if (!buyer.id && values.Password.length < 6) {
      setError('Password', true, 'Minimum 6 characters is Required')

      return
    }

    if (buyer.id && values.Password && values.Password.length < 6) {
      setError('Password', true, 'Minimum 6 characters is Required')

      return
    }

    if (!city) {
      setError('City', true, 'This field is Required')
      enqueueSnackbar('City is required', {
        variant: 'error',
      })
      return
    }

    if (!postCode) {
      setError('Postcode', true, 'This field is Required')
      enqueueSnackbar('Postcode is required', {
        variant: 'error',
      })
      return
    }

    if (!branch) {
      setError('Spa location', true, 'Select a Spa location')
      enqueueSnackbar('Spa location is required', {
        variant: 'error',
      })
      return
    }

    if (!buyer.id) {
      /* if (values.Username.length < 4) {
        setError('Username', true, 'Minimum 4 characters is Required')
        return
      }

      if (!values.Username.match(/^[a-zA-Z0-9]+$/)) {
        setError('Username', true, 'Special characters is not allowed')
        return
      } */

      insertBuyer({
        variables: {
          Branch: branch,
          Email: email,
          Phone: phone,
          FirstName: firstName,
          LastName: lastName,
          Address1: address1,
          Address2: address2,
          City: city,
          Postcode: postCode,
          State: countryState,
          Country: country,
          Username: phone,
          Password: password,
        },
      })
    } else {
      console.log('postCode', postCode)
      updateBuyer({
        variables: {
          Email: email,
          FirstName: firstName,
          LastName: lastName,
          Address1: address1,
          Address2: address2,
          City: city,
          Postcode: postCode,
          State: countryState,
          Country: country,
          Password: password,
        },
      })

      setPassword('')
    }
  }

  const handleChange = (event) => {
    setCountryState(event.target.value)
  }

  useEffect(() => {
    if (!buyer.id) {
      setEmail('')
      setPhone('')
      setFirstName('')
      setLastName('')
      setAddress1('')
      setAddress2('')
      setCity('')
      setPostCode('')
      setCountry('')
      setUsername('')
      setCountryState('')
      setBranch('')
      return
    } else {
      setEmail(buyer.Email)
      setPhone(buyer.Username)
      setFirstName(buyer.FirstName)
      setLastName(buyer.LastName)
      setAddress1(buyer.Address1)
      setAddress2(buyer.Address2)
      setCity(buyer.City)
      setPostCode(buyer.Postcode)
      setCountry(buyer.Country)
      setUsername(buyer.Username)
      setCountryState(buyer.State)
      setBranch(buyer.Branch)
    }
  }, [props])

  useEffect(() => {
    refetchBranches()
  }, [props])

  return (
    <React.Fragment>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <br />
            <br />

            <Grid container>
              <Grid item xs={12}>
                {!buyer.id && (
                  <FormControl
                    error={errors.Branch && true}
                    className={classes.margin}
                  >
                    <InputLabel shrink htmlFor="Branch">
                      Select your LAE Branch
                    </InputLabel>
                    <Select
                      classes={{
                        root: classes.rootBootstrap,
                        input: classes.inputBootstrap,
                      }}
                      fullWidth
                      labelId="localtion-label"
                      value={branch}
                      onChange={(e, value) => {
                        setBranch(e.target.value)
                      }}
                    >
                      {branches.map((a, i) => (
                        <MenuItem
                          key={i}
                          value={a.BranchCode}
                          selected={branch == a.BranchCode ? 'selected' : ''}
                        >
                          {a.BranchName}
                        </MenuItem>
                      ))}
                    </Select>

                    <FormHelperText>
                      {errors.Branch && errors.Branch.message}
                    </FormHelperText>
                  </FormControl>
                )}
                {buyer.id && (
                  <TextFieldDisplay
                    label="Register under LAE Branch"
                    value={buyer.BranchName}
                  />
                )}
              </Grid>
            </Grid>

            <br />
            {/* <Divider classes={{ root: classes.dividerRoot }} /> */}

            <Grid container spacing={isMobile ? 0 : 2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={errors.Username && true}>
                  <InputLabel shrink htmlFor="Username">
                    Mobile
                  </InputLabel>
                  <InputBase
                    classes={{
                      root: classes.rootBootstrap,
                      input: classes.inputBootstrap,
                    }}
                    value={username}
                    id="Username"
                    name="Username"
                    onChange={(e) => {
                      setUsername(e.target.value)
                    }}
                    label="Username"
                    placeholder="eg.0127778888"
                    fullWidth
                    margin="dense"
                    autoComplete="off"
                    inputProps={{
                      readOnly: buyer.id,
                      maxLength: 200,
                    }}
                    inputRef={register({
                      required: 'This field is Required',
                    })}
                    /*  startAdornment={
                      <InputAdornment position="start">
                        <WhatsAppIcon
                          fontSize="large"
                          style={{ color: '#4FCE5D' }}
                        />
                      </InputAdornment>
                    } */
                  />
                  <FormHelperText>
                    {errors.Username && errors.Username.message}
                    {/* {!errors.Username && (
                      <React.Fragment>
                        Please provide a valid mobile no
                      </React.Fragment>
                    )} */}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl error={errors.Password && true} fullWidth>
                  <InputLabel shrink htmlFor="Password">
                    Password
                  </InputLabel>
                  <InputBase
                    classes={{
                      root: classes.rootBootstrap,
                      input: classes.inputBootstrap,
                    }}
                    value={password}
                    id="Password"
                    name="Password"
                    label="Password"
                    fullWidth
                    margin="dense"
                    onChange={(e) => {
                      setPassword(e.target.value)
                    }}
                    autoComplete="off"
                    inputProps={{
                      maxLength: 200,
                      type: 'password',
                    }}
                    inputRef={register({})}
                  />
                  <FormHelperText>
                    {errors.Password && errors.Password.message}
                    {!errors.Password && buyer.id && (
                      <span>Leave blank if no change</span>
                    )}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <Divider classes={{ root: classes.dividerRoot }} />

            <Grid container spacing={isMobile ? 0 : 2}>
              <Grid item xs={12} md={6}>
                <FormControl
                  error={errors.FirstName && true}
                  className={classes.margin}
                >
                  <InputLabel shrink htmlFor="FirstName">
                    First Name
                  </InputLabel>
                  <InputBase
                    classes={{
                      root: classes.rootBootstrap,
                      input: classes.inputBootstrap,
                    }}
                    value={firstName}
                    id="FirstName"
                    name="FirstName"
                    label="First Name"
                    fullWidth
                    onChange={(e) => {
                      setFirstName(e.target.value)
                    }}
                    margin="dense"
                    autoComplete="off"
                    inputProps={{
                      maxLength: 200,
                    }}
                    inputRef={register({
                      required: 'This field is Required',
                    })}
                  />
                  <FormHelperText>
                    {errors.FirstName && errors.FirstName.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  error={errors.LastName && true}
                  className={classes.margin}
                >
                  <InputLabel shrink htmlFor="LastName">
                    Last Name
                  </InputLabel>
                  <InputBase
                    classes={{
                      root: classes.rootBootstrap,
                      input: classes.inputBootstrap,
                    }}
                    value={lastName}
                    id="LastName"
                    name="LastName"
                    onChange={(e) => {
                      setLastName(e.target.value)
                    }}
                    label="Last Name"
                    fullWidth
                    margin="dense"
                    autoComplete="off"
                    inputProps={{
                      maxLength: 200,
                    }}
                    inputRef={register({
                      required: 'This field is Required',
                    })}
                  />
                  <FormHelperText>
                    {errors.LastName && errors.LastName.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <FormControl
              error={errors.Email && true}
              className={classes.margin}
              style={{ marginBottom: 10 }}
            >
              <InputLabel shrink htmlFor="Email">
                Email
              </InputLabel>
              <InputBase
                classes={{
                  root: classes.rootBootstrap,
                  input: classes.inputBootstrap,
                }}
                value={email}
                id="Email"
                name="Email"
                label="Email"
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                fullWidth
                margin="dense"
                autoComplete="off"
                inputProps={{
                  maxLength: 200,
                  type: 'email',
                }}
                inputRef={register({
                  required: 'This field is Required',
                })}
              />
              <FormHelperText>
                {errors.Email && errors.Email.message}
              </FormHelperText>
            </FormControl>

            <br />
            <Divider classes={{ root: classes.dividerRoot }} />

            <FormControl
              error={errors.Address1 && true}
              className={classes.margin}
            >
              <InputLabel shrink htmlFor="Address1">
                Address 1
              </InputLabel>
              <InputBase
                classes={{
                  root: classes.rootBootstrap,
                  input: classes.inputBootstrap,
                }}
                value={address1}
                id="Address1"
                name="Address1"
                label="Address1"
                onChange={(e) => {
                  setAddress1(e.target.value)
                }}
                fullWidth
                margin="dense"
                autoComplete="off"
                inputProps={{
                  maxLength: 200,
                }}
                inputRef={register({
                  required: 'This field is Required',
                })}
              />
              <FormHelperText>
                {errors.Address1 && errors.Address1.message}
              </FormHelperText>
            </FormControl>

            <FormControl
              error={errors.Address2 && true}
              className={classes.margin}
            >
              <InputLabel shrink htmlFor="Address2">
                Address 2
              </InputLabel>
              <InputBase
                classes={{
                  root: classes.rootBootstrap,
                  input: classes.inputBootstrap,
                }}
                value={address2}
                id="Address2"
                name="Address2"
                label="Address2"
                onChange={(e) => {
                  setAddress2(e.target.value)
                }}
                fullWidth
                margin="dense"
                autoComplete="off"
                inputProps={{
                  maxLength: 200,
                }}
                inputRef={register({
                  required: 'This field is Required',
                })}
              />
              <FormHelperText>
                {errors.Address2 && errors.Address2.message}
              </FormHelperText>
            </FormControl>

            <FormControl error={errors.City && true} className={classes.margin}>
              <InputLabel shrink htmlFor="City">
                City
              </InputLabel>
              <InputBase
                classes={{
                  root: classes.rootBootstrap,
                  input: classes.inputBootstrap,
                }}
                value={city}
                id="City"
                name="City"
                label="City"
                onChange={(e) => {
                  setCity(e.target.value)
                }}
                fullWidth
                margin="dense"
                autoComplete="off"
                inputProps={{
                  maxLength: 200,
                }}
                inputRef={register({
                  required: 'This field is Required',
                })}
              />
              <FormHelperText>
                {errors.City && errors.City.message}
              </FormHelperText>
            </FormControl>

            {/* <FormControl error={errors.City && true} className={classes.margin}>
              <InputLabel shrink id="City">
                City
              </InputLabel>
              <Select
                labelId="City"
                id="City"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value)
                  const getstate = towns.find((a) => a.Town == e.target.value)
                  setCountryState(getstate.State)
                  setPostCode('')
                }}
                input={
                  <InputBase
                    classes={{
                      root: classes.rootBootstrap,
                      input: classes.inputBootstrap,
                    }}
                  />
                }
                inputRef={register({})}
              >
                {towns.map((town, i) => (
                  <MenuItem key={i} value={town.Town}>
                    {town.Town}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {errors.City && errors.City.message}
              </FormHelperText>
            </FormControl> */}

            <Grid container spacing={isMobile ? 0 : 2}>
              <Grid item xs={12} md={6}>
                <FormControl
                  error={errors.Postcode && true}
                  className={classes.margin}
                >
                  <InputLabel shrink htmlFor="Postcode">
                    Postcode
                  </InputLabel>
                  <InputBase
                    classes={{
                      root: classes.rootBootstrap,
                      input: classes.inputBootstrap,
                    }}
                    value={postCode}
                    id="Postcode"
                    name="Postcode"
                    label="Postcode"
                    onChange={(e) => {
                      setPostCode(e.target.value)
                    }}
                    fullWidth
                    margin="dense"
                    autoComplete="off"
                    inputProps={{
                      maxLength: 200,
                    }}
                    inputRef={register({
                      required: 'This field is Required',
                    })}
                  />
                  <FormHelperText>
                    {errors.Postcode && errors.Postcode.message}
                  </FormHelperText>
                </FormControl>

                {/*  <FormControl
                  error={errors.Postcode && true}
                  className={classes.margin}
                >
                  <InputLabel shrink id="Postcode">
                    Postcode
                  </InputLabel>
                  <Select
                    labelId="Postcode"
                    id="Postcode"
                    value={postCode}
                    onChange={(e) => {
                      setPostCode(e.target.value)
                    }}
                    input={
                      <InputBase
                        classes={{
                          root: classes.rootBootstrap,
                          input: classes.inputBootstrap,
                        }}
                      />
                    }
                    inputRef={register({})}
                  >
                    {postcodes.map((postcode, i) => (
                      <MenuItem key={i} value={postcode.PostCode}>
                        {postcode.PostCode}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors.Postcode && errors.Postcode.message}
                  </FormHelperText>
                </FormControl> */}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  error={errors.State && true}
                  className={classes.margin}
                >
                  <InputLabel shrink htmlFor="State">
                    State
                  </InputLabel>
                  <InputBase
                    classes={{
                      root: classes.rootBootstrap,
                      input: classes.inputBootstrap,
                    }}
                    value={countryState}
                    id="State"
                    name="State"
                    label="State"
                    onChange={(e) => {
                      setCountryState(e.target.value)
                    }}
                    fullWidth
                    margin="dense"
                    autoComplete="off"
                    inputProps={{
                      maxLength: 200,
                    }}
                    inputRef={register({
                      required: 'This field is Required',
                    })}
                  />
                  <FormHelperText>
                    {errors.State && errors.State.message}
                  </FormHelperText>
                </FormControl>

                {/* <FormControl
                  error={errors.State && true}
                  className={classes.margin}
                >
                  <InputLabel shrink id="State">
                    State
                  </InputLabel>
                  <InputBase
                    classes={{
                      root: classes.rootBootstrap,
                      input: classes.inputBootstrap,
                    }}
                    value={countryState}
                    id="State"
                    name="State"
                    label="State"
                    fullWidth
                    margin="dense"
                    autoComplete="off"
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                  <FormHelperText>
                    {errors.State && errors.State.message}
                  </FormHelperText>
                </FormControl> */}
              </Grid>
            </Grid>

            <br />
            <br />

            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={isMobile ? 0 : 2}
            >
              <Grid item xs={12} md={12} style={{ textAlign: 'right' }}>
                <Button
                  size="large"
                  disableElevation
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    history.push('/')
                  }}
                >
                  Cancel
                </Button>

                <Button
                  size="large"
                  variant="contained"
                  disableElevation
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
            <br />
            <br />
            <br />
            <br />
          </form>
        </div>
      </Container>
    </React.Fragment>
  )
}
