import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  CardActions,
  Button,
  CardMedia,
  List,
  Link,
} from '@material-ui/core/'

import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import { Link as Link2 } from 'react-router-dom'
import Hidden from '@material-ui/core/Hidden'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useEffect } from 'react'
import ShopDetails from './shopDetails'
import Loading from '../common/loading'
import Menu from './menu'
import MenuCategory from './menuCategory'
import { GET_SHOPCONFIG } from '../graphql/config'
import { GET_CATEGORIES } from '../graphql/category'

const restApi = process.env.REACT_APP_API

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  listRoot: {
    width: '100%',
    maxWidth: '36ch',
    padding: 0,
    overflowY: 'scroll',
    flexGrow: 1,
    backgroundColor: '#ebebeb',
  },
  media: {
    height: 150,
    margin: theme.spacing(1),
  },
  card: {},
  buttonRoot: {
    minWidth: 0,
    marginRight: 5,
    padding: 7,
  },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  containerroot: {
    paddingTop: 20,
    paddingBottom: 50,
    paddingLeft: 0,
    paddingRight: 0,
  },
}))

export default function Return(props) {
  const classes = useStyles()
  const [selectedProductID, setSelectedProductID] = useState()
  const [product, setProduct] = useState()
  const [back, setBack] = useState()
  const [backProductID, setBackProductID] = useState()
  const [currPage, setCurrPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [maxPages, updateMaxPages] = useState(0)
  const [viewLocation, setViewLocation] = useState()

  const [dbhost, setDbhost] = useState('spa1')

  /*const [dbhost, setDbhost] = useState('shop')*/

  const { data: { categories } = { categories: [] } } = useQuery(GET_CATEGORIES)

  const [totalAmount, setTotalAmount] = useState(0)

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  return (
    <React.Fragment>
      <Container classes={{ root: classes.containerroot }} maxWidth="md">
        <div className={classes.toolbar}>
          <div className={classes.root}>
            <h2>Terms & Condition</h2>

            <br />
            <h3>RETURN POLICY</h3>

            <br />
            <h4>SERVICE REFUND POLICY</h4>
            <p>
              ALL LAE ROOM TREATMENT SERVICE are purchase online by apps. After
              purchase completed, customer can booking service time by apps.
            </p>
            <p>
              At 936 WELLNESS SCIENTIFIC, we take the time to prepare for your
              scheduled appointment before your arrival. We are committed to
              providing you all with exceptional service. When a client cancels
              without giving enough notice, they prevent another client from
              being seen. Please give us 24 hours notice to notify us of any
              changes or cancellations. We understand that life's circumstances
              sometimes prevent you from keeping your appointments, but if
              24-hour notification is not given, you will be charged the full
              amount of your scheduled service for the missed appointment.
            </p>

            <h5>CANCELLATION POLICY</h5>
            <p>
              If the cancellation is made within 24 hours of booking, no charges
              or cancellation fees will be incurred. Cancellation is completely
              free of charge until 1 day before your check-in date.
            </p>
            <p>
              {' '}
              If you cancel your booking within 1 day of your check-in date,90%
              of the total amount will be charged as cancellation fee.
            </p>
            <br />
            <br />
            <h4>PRODUCT RETURN POLICY</h4>
            <p>
              All items sold are not returnable unless defective or damaged.
            </p>
            <p>
              <strong>No cash refund</strong> except for no-stock item(s)
            </p>

            <p>
              <strong>No cash refund</strong> on any defective items. We will do
              exchange only.
            </p>

            <p>
              <strong>No refund</strong> on parcel bounce back to us. We do only
              store credit.
            </p>

            <p>
              In all cases, the items returned must be in their original
              condition, UNWORN, UNALTERED, with all the tags and the original
              packaging. The goods will be inspected once received.
            </p>

            <br />
            <h4>RETURN PERIOD</h4>

            <p>
              All items come with 15 days return (return validity period starts
              from the delivery date). You will also be able to choose self
              collection.
            </p>

            <br />
            <h4>HOW TO RETURN</h4>

            <p>
              <strong>STEP 1:</strong> Ensure all item are returned in original
              condition. Missing item will cause return to be rejected.
            </p>
            <p>
              <strong>STEP 2:</strong> Take photo of the item before and after
              wrapping to show all item are pack in original condition and
              package is seal securely.
            </p>
            <p>
              <strong>STEP 3:</strong> Packaging, sealing, labeling and
              weighing.
            </p>

            <br />
            <br />

            <h3>DELIVERY METHOD</h3>
            <p>
              We are currently do not provide product delivery service, only
              Self Collection at our Health Center is available.
            </p>
          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}
