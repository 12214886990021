import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'
import Button from '@material-ui/core/Button'

import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import IconButton from '@material-ui/core/IconButton'

const restApi = process.env.REACT_APP_API

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  rootDiv: {
    padding: 20,
    [theme.breakpoints.up('sm')]: {
      padding: 80,
    },
  },
  listRoot: {
    width: '100%',
    maxWidth: '36ch',
    padding: 0,
    overflowY: 'scroll',
    flexGrow: 1,
    backgroundColor: '#ebebeb',
  },
  media: {
    height: 150,
    margin: theme.spacing(1),
  },
  card: {},
  buttonRoot: {
    minWidth: 0,
    marginRight: 5,
    padding: 7,
  },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  containerroot: {
    paddingTop: 20,
    paddingBottom: 50,
    paddingLeft: 0,
    paddingRight: 0,
  },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  rootBootstrapHelpertext: {
    color: 'white',
    marginTop: theme.spacing(2),
  },
  inputBootstrap: {
    borderRadius: 6,
    color: 'white',
    position: 'relative',
    backgroundColor: 'transparent',
    border: '3px solid #ced4da',
    width: '100%',
    padding: '13px 10px 10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderColor: (props) => `${props.COLORPRIMARY}`,
    },
  },
}))

export default function ServicesEnergyRoomBenefits(props) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div
        style={{
          background: 'url(/images/bg/2.jpg) no-repeat center center',
          backgroundSize: 'cover',
          minHeight: 700,
          display: 'flex',
          alignItems: 'center',
          marginTop: 0,
        }}
      >
        <Container classes={{ root: classes.containerroot }} maxWidth="md">
          <Grid style={{ marginTop: 0 }} container spacing={0}>
            <Grid item xs={12} md={6}></Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ color: 'white', textAlign: 'center' }}
            >
              <h1
                style={{
                  fontWeight: 'normal',
                  marginBottom: 0,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                936 LAE
                <br />
                Energy Room Treatment
              </h1>

              <h2 style={{ fontWeight: 'normal', marginBottom: 20 }}>
                Treatment Benefits
              </h2>
              <Grid style={{ marginTop: 40 }} container spacing={0}>
                <Grid item xs={6} md={3}>
                  <img
                    src="/images/icon/laeroom/BoostMetabolism.png"
                    style={{ height: 70 }}
                  />
                  <br />
                  Boost Metabolism
                </Grid>
                <Grid item xs={6} md={3}>
                  <img
                    src="/images/icon/laeroom/Detoxification.png"
                    style={{ height: 70 }}
                  />
                  <br />
                  Detoxification
                </Grid>
                <Grid item xs={6} md={3}>
                  <img
                    src="/images/icon/laeroom/Refreshing.png"
                    style={{ height: 70 }}
                  />
                  <br />
                  Refreshing
                </Grid>
                <Grid item xs={6} md={3}>
                  <img
                    src="/images/icon/laeroom/LoseFat.png"
                    style={{ height: 70 }}
                  />
                  <br />
                  Lose Fat
                </Grid>
              </Grid>
              <Grid
                style={{ marginTop: 40, marginBottom: 40 }}
                container
                spacing={0}
              >
                <Grid item xs={6} md={4}>
                  <img
                    src="/images/icon/laeroom/BoostImmuneSystem.png"
                    style={{ height: 70 }}
                  />
                  <br />
                  Boost Immune System
                </Grid>
                <Grid item xs={6} md={4}>
                  <img
                    src="/images/icon/laeroom/ReverseAging.png"
                    style={{ height: 70 }}
                  />
                  <br />
                  Reverse Aging
                </Grid>{' '}
                <Hidden only={['sm', 'xs']}>
                  <Grid item xs={12} md={4}>
                    <img
                      src="/images/icon/laeroom/ImproveHeartHealth.png"
                      style={{ height: 70 }}
                    />
                    <br />
                    Improve Heart Health
                  </Grid>
                </Hidden>
              </Grid>
              <Hidden only={['md', 'lg', 'xl']}>
                <Grid
                  style={{ marginTop: 40, marginBottom: 40 }}
                  container
                  spacing={0}
                >
                  <Grid item xs={12} md={4}>
                    <img
                      src="/images/icon/laeroom/ImproveHeartHealth.png"
                      style={{ height: 70 }}
                    />
                    <br />
                    Improve Heart Health
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          background:
            'url(/images/service/benefit/2bg.jpg) no-repeat bottom  right',
          backgroundSize: 'cover',
          color: 'white',
        }}
      >
        <Container maxWidth="md">
          <Grid
            container
            spacing={0}
            style={{
              minHeight: 500,
            }}
          >
            <Grid
              item
              xs={12}
              md={5}
              style={{
                background:
                  'url(/images/service/benefit/2.jpg) no-repeat center  center',
                backgroundSize: 'cover',

                minHeight: 500,
              }}
            ></Grid>
            <Grid
              item
              xs={12}
              md={7}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div className={classes.rootDiv}>
                <h1 style={{ fontWeight: 'normal' }}>
                  <img
                    src="/images/icon/laeroom/BoostMetabolism.png"
                    style={{
                      height: 60,
                      verticalAlign: 'middle',
                      lineHeight: 1,
                      paddingRight: 10,
                      marginBottom: 5,
                    }}
                  />
                  Boost Metabolism
                </h1>
                <p>
                  We are familiar with ultraviolet (UV) rays emitted by the sun,
                  which cause irreversible damage to the skin and its deeper
                  layers This is because the wavelength of human organs and
                  tissues are about 9.36 microns, and it cannot resonate with
                  energy wavelengths that are too different By harnessing an
                  energy wavelength of 8 — 12 microns, 936 LAE Energy Room is
                  able to generate resonance with our cells, breaking the
                  hydrogen bonds between the intracellular fluid and
                  extracellular fluid. All the water molecules in the body
                  transform into smaller clusters, which is highly conducive for
                  our metabolism and ultimately, overall health.
                </p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          background:
            'url(/images/service/benefit/3bg.jpg) no-repeat bottom  right',
          backgroundSize: 'cover',
          color: 'white',
        }}
      >
        <Container maxWidth="md">
          <Grid
            container
            spacing={0}
            style={{
              minHeight: 500,
            }}
          >
            <Grid
              item
              xs={12}
              md={7}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div className={classes.rootDiv}>
                <h1 style={{ fontWeight: 'normal' }}>
                  <img
                    src="/images/icon/laeroom/Detoxification.png"
                    style={{
                      height: 60,
                      verticalAlign: 'middle',
                      lineHeight: 1,
                      paddingRight: 10,
                      marginBottom: 5,
                    }}
                  />
                  Detoxification
                </h1>
                <p>
                  Direct heat exposure increases the penetration strength of
                  this energy, reaching deep into the tissues to burn excess fat
                  and promote the excretion of heavy metals, toxins and
                  fat-soluble waste matter through the sebaceous glands.
                </p>
                <p>
                  At the same time, improved metabolic functions also help to
                  rebalance the endocrine system and improve autoimmunity.
                </p>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              style={{
                background:
                  'url(/images/service/benefit/3.jpg) no-repeat center  center',
                backgroundSize: 'cover',

                minHeight: 500,
              }}
            ></Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          background:
            'url(/images/service/benefit/4bg.jpg) no-repeat bottom  right',
          backgroundSize: 'cover',
          color: 'white',
        }}
      >
        <Container maxWidth="md">
          <Grid
            container
            spacing={0}
            style={{
              minHeight: 500,
            }}
          >
            {' '}
            <Grid
              item
              xs={12}
              md={5}
              style={{
                background:
                  'url(/images/service/benefit/4.jpg) no-repeat center  center',
                backgroundSize: 'cover',

                minHeight: 500,
              }}
            ></Grid>
            <Grid
              item
              xs={12}
              md={7}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div className={classes.rootDiv}>
                <h1 style={{ fontWeight: 'normal' }}>
                  <img
                    src="/images/icon/laeroom/Refreshing.png"
                    style={{
                      height: 60,
                      verticalAlign: 'middle',
                      lineHeight: 1,
                      paddingRight: 10,
                      marginBottom: 5,
                    }}
                  />
                  Refreshing
                </h1>
                <p>
                  Our respiratory system is mainly composed of the nasal cavity,
                  larynx, trachea, bronchi, lungs, and diaphragm. Its main
                  function is to obtain oxygen from the air, and expel the
                  carbon dioxide produced by the body back into the atmosphere.{' '}
                </p>
                <p>
                  While breathing, the average adult inhales 250m1 of oxygen
                  into the blood stream in one minute, and exhales 200m1 of
                  carbon dioxide into the air. When the respiratory organs are
                  damaged or infected, the human body's oxygen supply will be
                  compromised.{' '}
                </p>
                <p>
                  With regular use of 936 LAE Energy Room you can breathe
                  easily, you are one step closer to long-term health and
                  happiness!
                </p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          background:
            'url(/images/service/benefit/5bg.jpg) no-repeat bottom  right',
          backgroundSize: 'cover',
          color: 'white',
        }}
      >
        <Container maxWidth="md">
          <Grid
            container
            spacing={0}
            style={{
              minHeight: 500,
            }}
          >
            <Grid
              item
              xs={12}
              md={7}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div className={classes.rootDiv}>
                <h1 style={{ fontWeight: 'normal' }}>
                  <img
                    src="/images/icon/laeroom/LoseFat.png"
                    style={{
                      height: 60,
                      verticalAlign: 'middle',
                      lineHeight: 1,
                      paddingRight: 10,
                      marginBottom: 5,
                    }}
                  />
                  Lose Fat
                </h1>
                <p>
                  Obesity is when a person's calorie intake surpasses the energy
                  needed by their body, resulting in the accumulation of fat and
                  weight gain.
                </p>

                <p>
                  When the body's temperature increases, it burns fat as fuel.
                  Therefore, if you wish to lose weight naturally and safely,
                  you need to reduce your body fat. If your goal is to put on
                  weight, your digestive system will work effectively if its
                  energy balance is restored.
                </p>
                <p>
                  936 LAE Energy Room helps to improve the body's condition so
                  that it can perform to the best of its potential.
                </p>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              style={{
                background:
                  'url(/images/service/benefit/5.png) no-repeat center  center',
                backgroundSize: 'cover',

                minHeight: 500,
              }}
            ></Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          background:
            'url(/images/service/benefit/6bg.jpg) no-repeat bottom  right',
          backgroundSize: 'cover',
          color: 'white',
        }}
      >
        <Container maxWidth="md">
          <Grid
            container
            spacing={0}
            style={{
              minHeight: 500,
            }}
          >
            {' '}
            <Grid
              item
              xs={12}
              md={5}
              style={{
                background:
                  'url(/images/service/benefit/6.jpg) no-repeat center  center',
                backgroundSize: 'cover',
              }}
            ></Grid>
            <Grid
              item
              xs={12}
              md={7}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div className={classes.rootDiv}>
                <h1 style={{ fontWeight: 'normal' }}>
                  <img
                    src="/images/icon/laeroom/BoostImmuneSystem.png"
                    style={{
                      height: 60,
                      verticalAlign: 'middle',
                      lineHeight: 1,
                      paddingRight: 10,
                      marginBottom: 5,
                    }}
                  />
                  Boost Immune System
                </h1>

                <p>
                  Mainly composed of lymph glands, thymus glands and white blood
                  cells, the immune system goes to work when bacteria or foreign
                  substances invade the human body, producing antibod-ies as a
                  form of defense.
                </p>
                <p>
                  936 LAE Energy Room is developed with the latest expertise and
                  technology in the field of energy medicine, providing modern
                  individuals with a quick, effective and easy way to stay
                  healthy.
                </p>
                <p>
                  A single 30-minute session is equivalent to running a marathon
                  for 30 kilometers, whereby "fat sweat" containing excess fat,
                  old waste buildup, heavy metals and toxins is excreted from
                  the body. Your metabolism is strengthened, and naturally, your
                  body's resistance improves too. Now, you no longer have to be
                  afraid of the flu!
                </p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          background:
            'url(/images/service/benefit/7bg.jpg) no-repeat bottom  right',
          backgroundSize: 'cover',
          color: 'white',
        }}
      >
        <Container maxWidth="md">
          <Grid
            container
            spacing={0}
            style={{
              minHeight: 500,
            }}
          >
            <Grid
              item
              xs={12}
              md={7}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div className={classes.rootDiv}>
                <h1 style={{ fontWeight: 'normal' }}>
                  <img
                    src="/images/icon/laeroom/ReverseAging.png"
                    style={{
                      height: 60,
                      verticalAlign: 'middle',
                      lineHeight: 1,
                      paddingRight: 10,
                      marginBottom: 5,
                    }}
                  />
                  ReverseAging
                </h1>
                <p>
                  Reverse Aging Physical degeneration, aging, dementia, organ
                  failure and osteoporosis pose as major issues for the elderly.
                  The first three stem from the same reason: in fact, physical
                  degeneration and aging are natural processes of biological
                  evolution. We might not be able to reverse the effects of
                  time, but we can delay the process and at the same time,
                  reduce the problems and pain often associated with aging!
                </p>

                <p>
                  Apart from age, osteoporosis is also caused by calcium loss,
                  which makes bones brittle and prone to breakage. Taking too
                  much artificially-produced calcium will cause deposits in the
                  blood, leading to other unwanted diseases, on the best method
                  is to consume natural sources of calcium through dietary
                  habits.
                </p>
                <p>
                  A multi-wonder treatment for everyone in the family, 936 LAE
                  Energy Room is the best healthcare and anti-aging solution —
                  not only does it slow down the signs of aging, but it also
                  delays the rate of calcium depletion.
                </p>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              style={{
                background:
                  'url(/images/service/benefit/7.jpg) no-repeat center  center',
                backgroundSize: 'cover',
                minHeight: 500,
              }}
            ></Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          background:
            'url(/images/service/benefit/8bg.jpg) no-repeat bottom  right',
          backgroundSize: 'cover',
          color: 'white',
        }}
      >
        <Container maxWidth="md">
          <Grid
            container
            spacing={0}
            style={{
              minHeight: 500,
            }}
          >
            <Grid
              item
              xs={12}
              md={5}
              style={{
                background:
                  'url(/images/service/benefit/8.jpg) no-repeat center  center',
                backgroundSize: 'cover',
                minHeight: 500,
              }}
            ></Grid>
            <Grid
              item
              xs={12}
              md={7}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div className={classes.rootDiv}>
                <h1 style={{ fontWeight: 'normal' }}>
                  <img
                    src="/images/icon/laeroom/ImproveHeartHealth.png"
                    style={{
                      height: 60,
                      verticalAlign: 'middle',
                      lineHeight: 1,
                      paddingRight: 10,
                      marginBottom: 5,
                    }}
                  />
                  Improve Heart Health
                </h1>

                <p>
                  936 LAE Energy Room is an effective exercise replacement.
                  Regular exercise helps to promote blood circulation in the
                  peripheral nerves.
                </p>
                <p>
                  Frequent dry brushing, morning walks, and slow jogging are
                  very helpful, but if it is too troublesome to work out because
                  of time and environment constraints, 936 LAE Energy Room does
                  the work for you with just 30 minutes a day to give you a
                  healthy, happy life.
                </p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  )
}
