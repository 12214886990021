import React from 'react'
import PropTypes from 'prop-types'
import PaginationDot from './slidesPaginationDot'

const styles = {
  root: {
    position: 'relative',
    marginTop: '-30px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

class Pagination extends React.Component {
  handleClick = (event, index) => {
    this.props.onChangeIndex(index)
  }

  render() {
    const { index, dots } = this.props

    const children = []

    for (let i = 0; i < dots; i += 1) {
      children.push(
        <PaginationDot
          key={i}
          index={i}
          active={i === index}
          onClick={this.handleClick}
        />
      )
    }

    return (
      <div style={styles.root}>
        <div></div>
        {children}
      </div>
    )
  }
}

Pagination.propTypes = {
  dots: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onChangeIndex: PropTypes.func.isRequired,
}

export default Pagination
