import React, { useEffect, useState } from 'react'
import { useHistory, Link as Link2 } from 'react-router-dom'

import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'

import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const restApi = process.env.REACT_APP_API

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  menuItem: {
    paddingLeft: 0,
    paddingRight: 10,
    textDecoration: 'none',
    fontWeight: 'normal',
    color: 'white',
  },
}))

export default function MenuCategory(props) {
  const classes = useStyles()
  const [value, setValue] = React.useState()
  const [dbhost, setDbhost] = useState('spa1')

  const history = useHistory()

  useEffect(() => {
    const selected = props.categories.find((a) => a.id == props.value)
    setValue(props.categories.indexOf(selected))
  }, [props])

  return (
    <div>
      <List>
        {props.categories &&
          props.categories.map(
            (a, i) =>
              a.Category == props.category && (
                <ListItem style={{ paddingLeft: 0 }} key={i}>
                  <Link2
                    className={classes.menuItem}
                    to={`/shop/${a.id}/${a.PrettyUrl}`}
                  >
                    <img
                      src={`${restApi}/media/${dbhost}/uploads/invoice/${a.ProductImage}`}
                      height={40}
                      style={{ verticalAlign: 'middle', paddingRight: 10 }}
                    />{' '}
                    {a.ProductName}
                  </Link2>
                </ListItem>
              )
          )}
      </List>
    </div>
  )
}
