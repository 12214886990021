import { gql } from 'apollo-boost'

export const CHECK_USERNAME = gql`
  query CheckUsername($Username: String) {
    checkUsername(Username: $Username)
  }
`
export const GET_BUYER = gql`
  {
    buyer {
      id
      UserID
      Email
      Phone
      FirstName
      LastName
      Address1
      Address2
      City
      Postcode
      State
      Country
      Error
      Username
      Branch
      BranchName
    }
  }
`
export const INSERT_BUYER = gql`
  mutation InsertBuyer(
    $Email: String
    $Phone: String
    $FirstName: String
    $LastName: String
    $Address1: String
    $Address2: String
    $City: String
    $Postcode: String
    $State: String
    $Country: String
    $Username: String
    $Password: String
    $Branch: String
  ) {
    insertBuyer(
      Email: $Email
      Phone: $Phone
      FirstName: $FirstName
      LastName: $LastName
      Address1: $Address1
      Address2: $Address2
      City: $City
      Postcode: $Postcode
      State: $State
      Country: $Country
      Username: $Username
      Password: $Password
      Branch: $Branch
    ) {
      id
      UserID
      Email
      Phone
      FirstName
      LastName
      Address1
      Address2
      City
      Postcode
      State
      Country
      Error
      Username
      Branch
      BranchName
    }
  }
`
export const UPDATE_BUYER = gql`
  mutation UpdateBuyer(
    $Email: String
    $Phone: String
    $FirstName: String
    $LastName: String
    $Address1: String
    $Address2: String
    $City: String
    $Postcode: String
    $State: String
    $Country: String
    $Password: String
  ) {
    updateBuyer(
      Email: $Email
      Phone: $Phone
      FirstName: $FirstName
      LastName: $LastName
      Address1: $Address1
      Address2: $Address2
      City: $City
      Postcode: $Postcode
      State: $State
      Country: $Country
      Password: $Password
    ) {
      id
      UserID
      Email
      Phone
      FirstName
      LastName
      Address1
      Address2
      City
      Postcode
      State
      Country
      Error
      Username
      Branch
      BranchName
    }
  }
`
