import React, { useState, useEffect } from 'react'
import { Link as Link2, useHistory } from 'react-router-dom'
import { navigate } from '@reach/router'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Hidden from '@material-ui/core/Hidden'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Cookies from 'js-cookie'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import axios from 'axios'
import Container from '@material-ui/core/Container'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import InputAdornment from '@material-ui/core/InputAdornment'
import { FORGETPASSWORD } from './components/graphql/staff'

import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'

const url = process.env.REACT_APP_API+'/users/forgotpassword'

const useStyles = makeStyles((theme) => ({
  root: {
    /* height: 'calc(100vh - 48px)', */
    backgroundColor: 'white',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: 'transparent',
    border: '2px solid #ced4da',
    width: '100%',
    padding: '13px 10px 10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderColor: (props) => `${props.COLORPRIMARY}`,
    },
  },
}))

export default function ForgotPassword(props) {
  const classes = useStyles()
  const history = useHistory()
  const [done, setDone] = useState()
  const [key, setKey] = useState(
    (props.match && props.match.params && props.match.params.key) || ''
  )
  const [username, setUsername] = useState(
    (props.match && props.match.params && props.match.params.username) || ''
  )
  const [step, setStep] = useState(
    (props.match && props.match.params && props.match.params.username) || ''
  )
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const updateCache = (cache, { data }) => {
    history.push('/login')
  }

  const [forgetPassword] = useMutation(FORGETPASSWORD, {
    update: updateCache,
  })

  const submitFormReset = (event) => {
    setDone(true)
    event.preventDefault()
    const options = {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: `Username=${username}`,
    }

    fetch(url, options).then((response) => {
      setDone(true)
    })
  }

  const submitForm = (event) => {
    event.preventDefault()
    if (!password || password != confirmPassword) {
      alert('Password incorrect')
      return
    }
    if (password.length < 8) {
      alert('Must be more than 8 characters')
      return
    }

    if (password == confirmPassword) {
      forgetPassword({
        variables: {
          Username: username,
          Password: password,
          Key: key,
        },
      })
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {!step && (
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          <p>Please provide your Mobile no</p>

          {done && (
            <p style={{ fontWeight: 'bold' }}>
              Please check your email for further instruction.
            </p>
          )}
          {!done && (
            <form
              onSubmit={submitFormReset}
              className={classes.form}
              noValidate
            >
              <FormControl fullWidth>
                <InputLabel shrink htmlFor="Username">
                  Mobile No
                </InputLabel>
                <InputBase
                  classes={{
                    root: classes.rootBootstrap,
                    input: classes.inputBootstrap,
                  }}
                  value={username}
                  id="Username"
                  name="Username"
                  placeholder="eg.0127778888"
                  onChange={(e) => {
                    setUsername(e.target.value)
                  }}
                  label="Username"
                  fullWidth
                  margin="dense"
                  autoComplete="off"
                  inputProps={{
                    maxLength: 200,
                  }}
                />
                <FormHelperText></FormHelperText>
              </FormControl>
              <br />
              <br />
              <Button
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </form>
          )}
        </div>
      )}
      {step && (
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            New Password
          </Typography>
          <form onSubmit={submitForm} className={classes.form} noValidate>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor="Password">
                Password
              </InputLabel>
              <InputBase
                classes={{
                  root: classes.rootBootstrap,
                  input: classes.inputBootstrap,
                }}
                value={password}
                id="Password"
                name="Password"
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
                label="Password"
                fullWidth
                margin="dense"
                autoComplete="off"
                inputProps={{
                  maxLength: 200,
                  type: 'password',
                }}
              />
              <FormHelperText></FormHelperText>
            </FormControl>
            <br />
            <br />

            <FormControl fullWidth>
              <InputLabel shrink htmlFor="ConfirmPassword">
                Confirm Password
              </InputLabel>
              <InputBase
                classes={{
                  root: classes.rootBootstrap,
                  input: classes.inputBootstrap,
                }}
                value={confirmPassword}
                id="ConfirmPassword"
                name="ConfirmPassword"
                onChange={(e) => {
                  setConfirmPassword(e.target.value)
                }}
                label="ConfirmPassword"
                fullWidth
                margin="dense"
                autoComplete="off"
                inputProps={{
                  maxLength: 200,
                  type: 'password',
                }}
              />
              <FormHelperText></FormHelperText>
            </FormControl>

            <div style={{ marginTop: 30, textAlign: 'center' }}>
              <Button
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                Save and proceed to Login
              </Button>
            </div>
            {/* <div style={{ marginTop: 30, textAlign: 'left' }}>
            <Button
              className={classes.button}
              onClick={() => {
                scrollToTop()
                history.push('/')
              }}
              startIcon={<ArrowBackIcon />}
            >
              Back to shopping
            </Button>
            <br />
            <br />
          </div> */}
          </form>
        </div>
      )}
    </Container>
  )
}
