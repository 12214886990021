import React, { useState, useEffect } from 'react'
import {
  Redirect,
  Route,
  BrowserRouter,
  Switch,
  withRouter,
  Link,
  Link as Link2,
  NavLink,
} from 'react-router-dom'

import clsx from 'clsx'
import Cookies from 'js-cookie'
import Login from './Login'
import PrivateArea from './PrivateArea'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBox,
  faUserTie,
  faTruck,
  faPlane,
  faFileInvoiceDollar,
  faCog,
  faCalculator,
  faPowerOff,
  faFileAlt,
  faFolderOpen,
  faDollarSign,
} from '@fortawesome/free-solid-svg-icons'

import axios from 'axios'
import './App.css'

import {
  fade,
  makeStyles,
  useTheme,
  createMuiTheme,
  ThemeProvider,
  withStyles,
} from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import HomeIcon from '@material-ui/icons/HomeOutlined'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { green, red } from '@material-ui/core/colors'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuList from '@material-ui/core/MenuList'

import Collapse from '@material-ui/core/Collapse'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import StarBorder from '@material-ui/icons/StarBorder'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import IconButton from '@material-ui/core/IconButton'
import FacebookIcon from '@material-ui/icons/Facebook'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import ScheduleIcon from '@material-ui/icons/Schedule'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import HistoryIcon from '@material-ui/icons/History'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'

import AccountCircle from '@material-ui/icons/AccountCircle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'

import MenuDropdown from './components/common/menuDropdown'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'

import { GET_SHOPCONFIG } from './components/graphql/config'
import { GET_BUYER } from './components/graphql/buyer'
import { SnackbarProvider } from 'notistack'
import Logout from './Logout'
import Shop from './components/shop/shop'
import ShopDetails from './components/shop/shopDetails2'
import ShopDetails3 from './components/shop/shopDetails3'
import Checkout from './components/shop/checkout'
import Customer from './components/shop/customer'
import MyOrder from './components/shop/myorders'
import Terms from './components/shop/terms'
import Faq from './components/shop/faq'
import BottomNav from './components/shop/bottomNav'
import MobileCart from './components/shop/mobiecart'
import CartButton from './components/shop/cartButton'
import OrderDetail from './components/shop/orderdetail'
import Home from './components/pages/home'
import Report from './components/pages/report'
import Booking from './components/shop/booking'
import BookingDetail from './components/shop/bookingdetail'

import ScrollToTop from 'react-router-scroll-top'
import Return from './components/shop/return'
import BrandStory from './components/shop/brandstory'
import ServicesEnergyRoom from './components/shop/servicesEnergyRoom'
import ServicesEnergyRoomBenefits from './components/shop/servicesEnergyRoomBenefits'
import ServicesWater from './components/shop/servicesWater'
import ServicesWaterBenefits from './components/shop/servicesWaterBenefits'
import ServicesQuantum from './components/shop/servicesQuantum'
import Product from './components/shop/product'
import Investor from './components/shop/investor'
import Contact from './components/shop/contactus'
import { H6 } from '@blueprintjs/core'
import Promotion from './components/shop/promotion'
import WhatIsLae from './components/shop/whatislae'
import ForgotPassword from './ForgotPassword'
import PaymentSuccess from './components/shop/paymentSuccess'

const GET_DARKMODE = gql`
  {
    isDarkModeEnabled @client
  }
`
const drawerWidth = 240

const restApi = process.env.REACT_APP_API+'/'

const useStyles = makeStyles((theme) => ({
  root: {},
  bottomlabel: {
    fontSize: '9px !important',
    fontWeight: 'normal',
    marginTop: 5,
  },
  containerroot: {
    background: 'url(/images/bg/0.jpg) no-repeat center center',
    backgroundSize: 'cover',
    color: 'white',
    boxShadow: '0 4px 2px -2px rgba(0,0,0,.25)',
  },
  bottomrootroot: {
    position: 'fixed',
    width: '100%',
    borderRadius: 0,
    bottom: 0,
    [theme.breakpoints.up('sm')]: {
      position: 'static',
    },
  },
  bottomroot: {
    color: 'white',
    paddingTop: 12,
    minWidth: 50,
    background: 'url(/images/bg/0.jpg) no-repeat center center',
    backgroundSize: 'cover',
  },
  title: {
    flexGrow: 1,
    padding: 0,
    textAlign: 'center',
    lineHeight: 1,
    color: (props) => props.COLORPRIMARY,
    textDecoration: 'none',
  },
  toolBar2: {
    margin: 'auto',
    maxWidth: 1199,
    width: '100%',
    minHeight: 48,
    paddingRight: 0,
    paddingLeft: 0,
    color: (props) => props.COLORPRIMARY,

    background: 'url(/images/bg/0.jpg) no-repeat center center',
    backgroundSize: 'cover',

    position: 'fixed',
    zIndex: 1199,
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      minHeight: 1,
      height: 1,
      color: 'white',
    },
  },
  appBar: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    backgroundColor: 'white',
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appbarroot: {
    height: 10,
    fontSize: 12,
    position: 'fixed',
    borderRadius: 0,
    background: 'url(/images/bg/0.jpg) no-repeat center center',
    backgroundSize: 'cover',
    boxShadow: 'none!important',
    [theme.breakpoints.up('sm')]: {
      background: 'url(/images/bg/0.jpg) no-repeat center center',
      backgroundSize: 'cover',
    },
  },
  toolbar: {
    padding: theme.spacing(0, 0, 0, 0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  menuTopButton: { color: 'white', fontSize: 10, lineHeight: '10px' },
  menutopstarticon: { marginRight: 4 },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuItem: {
    paddingLeft: 0,
    paddingRight: 0,
    textDecoration: 'none',
    fontWeight: 'normal',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  hide: {
    display: 'none',
  },
  link: { color: (props) => props.COLORPRIMARY, textDecoration: 'none' },
  inline: {
    display: 'inline',
    color: (props) => props.COLORPRIMARY,
  },
  drawer: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
    },
  },
  drawerOpen: {
    background: '#cfd8dc!important',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    background: '#cfd8dc!important',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  content: {
    /*  minHeight: 'calc(100vh - 80px)',
    minHeight: 'calc(100vh - 80px)', */
    /* minHeight: 'calc(100vh - 492px)', */
    minHeight: '100vh',
    flexGrow: 1,
    padding: theme.spacing(0),
    paddingTop: 0,
    marginTop: 0,
    overflow: 'hidden',
    background: 'url(/images/bg/bgFull.jpg) center center no-repeat',
    backgroundSize: 'cover',
    paddingTop: 48,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
      paddingTop: 80,
    } /* 
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(7) + 1,
    }, */,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  icon: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  iconList: {
    color: '#757575',
    minWidth: '26px',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
  },
  /* menuItem: {
    paddingLeft: 0,
    paddingRight: 10,
    textDecoration: 'none',
    color: 'white',
  }, */
  signinMenu: {
    paddingRight: 10,
    textDecoration: 'none',
    fontWeight: 'bold',
    color: (props) => props.COLORPRIMARY,
    [theme.breakpoints.up('sm')]: {
      color: 'white',
    },
  },
  menuDivider: {
    paddingLeft: 5,
    paddingRight: 5,
    height: 10,
    borderLeft: '1px solid rgba(255, 255, 255, 255)',
  },
  listitemroot: {
    minWidth: 280,
    paddingBottom: 5,
    paddingTop: 5,
    margin: 0,
  },
  footer: {
    /* backgroundColor: '#333', */
    /* color: 'white', */
    fontSize: 13,
    fontWeight: 'bold',
    height: 85,
    paddingTop: 50,
    paddingBottom: 100,
  },
  stickToBottom: {
    width: '100%',
    position: 'fixed',
    height: 'auto',
    /* position: 'absolute', */
    bottom: 0,
    zIndex: 99999,
  },
  isActive: {
    color: '#00acee!important',
  },
  nested: {
    paddingLeft: 20,
  },
}))

export default function App(props) {
  const [menuOpen, setMenuOpen] = useState(false)

  const [menuOpenCat, setMenuOpenCat] = useState({
    services: false,
    aboutus: false,
    products: false,
    events: false,
  })
  const [signedIn, setSignedIn] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [redirectTo, setRedirectTo] = useState()
  const [bottomvalue, setBottomvalue] = React.useState('home')

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: [] },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const { loading: loadingBuyer, data: { buyer } = { buyer: { id: null } } } =
    useQuery(GET_BUYER, { skip: !Cookies.get('signedin') })

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const [open, setOpen] = useState(false)

  const [theme, setTheme] = useState({
    overrides: {
      MuiPaper: {
        root: {
          borderRadius: 6,
          border: '0!important',
          boxShadow: '0 1px 1px 0 rgba(0,0,0,.05)',
        },
      },
      MuiCard: {
        root: {
          borderRadius: 6,
          border: '0!important',
          boxShadow: '0 1px 1px 0 rgba(0,0,0,.05)',
        },
      },
      MuiButton: {
        root: {
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 0,
          paddingBottom: 0,
          borderRadius: 20,
          color: 'white',
          textTransform: 'capitalize',
        },
      },
    },
    palette: {
      primary: {
        dark: '#00acee',
        main: '#00acee',
        paper: '#efefef',
        contrastText: '#fff',
      },
      secondary: {
        main: '#00acee',
      },
      background: {
        default: '#efefef',
      },
      type: 'dark', // Switching the dark mode on is a single property value change.
    },
    typography: {
      fontFamily: '"Spartan", "Helvetica", "Arial", sans-serif',
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
    transitions: {
      duration: {
        shortest: 20,
        shorter: 60,
        short: 100,
        standard: 100,
        complex: 175,
        enteringScreen: 125,
        leavingScreen: 95,
      },
    },
  })

  const classes = useStyles(shopconfig)

  const [dbhost, setDbhost] = useState('spa1')

  /*const [dbhost, setDbhost] = useState('shop')*/

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorEltopmenu, setAnchorEltopmenu] = React.useState(null)
  const open2 = Boolean(anchorEl)
  const opentopmenu = Boolean(anchorEltopmenu)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  /* useEffect(() => {
    console.log(dbhost)
  }, []) */

  const theme2 = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [dense, setDense] = React.useState(false)

  const [openMenu, setOpenMenu] = React.useState(false)
  const anchorRef = React.useRef(null)

  const {
    data: { isDarkModeEnabled, loginDetails } = {
      isDarkModeEnabled: false,
    },
    client,
  } = useQuery(GET_DARKMODE)

  const handleToggle = () => {
    toggleDarkTheme(!isDarkModeEnabled)
    setDense(!isDarkModeEnabled)
    client.writeData({ data: { isDarkModeEnabled: !isDarkModeEnabled } })
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  /* const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  } */

  const handleMenuToggle = () => {
    setMenuOpen((prevOpen) => !prevOpen)
  }

  const handleMenuClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setMenuOpen(false)
  }

  function handleMenuListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setMenuOpen(false)
    }
  }

  const prevOpen = React.useRef(menuOpen)
  React.useEffect(() => {
    if (prevOpen.current === true && menuOpen === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = menuOpen
  }, [menuOpen])

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  )

  /* const sideList = (side) => (
    <div>
      <div>
        <IconButton onClick={handleDrawerClose}>
          {theme2.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      <Divider className={classes.divider} />
      <List>
        {menus.map((menu, index) => {
          const access = menu.access.find(
            (access) => access == Cookies.get('Department')
          )
          if (access)
            return (
              <Link className={classes.link} to={menu.link} key={index}>
                <ListItem button key={index}>
                  <Tooltip title={menu.title}>
                    <ListItemIcon className={classes.iconList}>
                      {menu.icon}
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary={menu.title} />
                </ListItem>
              </Link>
            )
        })}
      </List>
      <Divider className={classes.divider} />
      <List>
        <Link
          to={`/logout`}
          style={{ color: '#37474f', textDecoration: 'none' }}
        >
          <ListItem button key={1001}>
            <ListItemIcon className={classes.iconList}>
              <FontAwesomeIcon icon={faPowerOff} style={{ fontSize: '16px' }} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </Link>
      </List>
      <Divider className={classes.divider} />
    </div>
  ) */

  // we change the palette type of the theme in state
  const toggleDarkTheme = (isDarkTheme) => {
    if (isDarkTheme) {
      setTheme({
        overrides: {
          MuiPaper: {
            root: {
              borderRadius: 6,
              border: '0!important',

              boxShadow: '0 1px 1px 0 rgba(0,0,0,.05)',
            },
          },
          MuiCard: {
            root: {
              borderRadius: 6,
              border: '0!important',
              boxShadow: '0 1px 1px 0 rgba(0,0,0,.05)',
            },
          },
          MuiButton: {
            root: {
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 0,
              paddingBottom: 0,
              borderRadius: 20,
              color: 'white',
              textTransform: 'capitalize',
            },
          },
        },
        palette: {
          primary: {
            main: shopconfig.COLORPRIMARY,
            paper: '#323232',
            contrastText: '#000',
          },
          secondary: {
            main: shopconfig.COLORPRIMARY,
          },
          background: {
            default: '#212121',
          },
          type: 'dark', // Switching the dark mode on is a single property value change.
        },
        typography: {
          fontFamily: '"Spartan", "Helvetica", "Arial", sans-serif',
          fontSize: 14,
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 500,
        },
      })
    } else {
      setTheme({
        overrides: {
          MuiPaper: {
            root: {
              borderRadius: 6,
              border: '0!important',

              boxShadow: '0 1px 1px 0 rgba(0,0,0,.05)',
            },
          },
          MuiCard: {
            root: {
              borderRadius: 6,
              border: '0!important',
              boxShadow: '0 1px 1px 0 rgba(0,0,0,.05)',
            },
          },
          MuiButton: {
            root: {
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 0,
              paddingBottom: 0,
              borderRadius: 20,
              color: 'white',
              textTransform: 'capitalize',
            },
          },
        },
        palette: {
          primary: {
            dark: shopconfig.COLORPRIMARY,
            main: shopconfig.COLORPRIMARY,
            paper: '#f5f5f5',
            contrastText: '#f3f3f3',
          },
          secondary: {
            main: shopconfig.COLORPRIMARY,
          },
          background: {
            default: '#fff',
          },
          type: 'dark', // Switching the dark mode on is a single property value change.
        },
        typography: {
          fontFamily: '"Spartan", "Helvetica", "Arial", sans-serif',
          fontSize: 14,
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 500,
        },
      })
    }
  }

  const muiTheme = createMuiTheme(theme)

  const handleSignedIn = (link) => {
    setSignedIn(true)
    setRedirect(true)
    setRedirectTo(link)
  }

  const renderRedirect = () => {
    if (redirect) {
      setRedirect(false)
      return <Redirect to={redirectTo} />
    }
    if (redirectTo) return <Redirect to={redirectTo} />
  }

  useEffect(() => {
    if (Cookies.get('signedin')) {
      setSignedIn(true)
      axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get(
        'token'
      )}`
      client.writeData({
        data: {
          isDarkModeEnabled,
          loginDetails: {
            StaffID: Cookies.get('StaffID'),
            Department: Cookies.get('Department'),
          },
        },
      })

      //console.log('staff', Cookies.get('StaffID'))
    }
  }, [])

  useEffect(() => {
    if (shopconfig.COLORPRIMARY)
      setTheme({
        overrides: {
          MuiPaper: {
            root: {
              borderRadius: 0,
              border: '0!important',

              boxShadow: '0 1px 1px 0 rgba(0,0,0,.05)',
            },
          },
          MuiCard: {
            root: {
              borderRadius: 6,
              border: '0!important',
              boxShadow: '0 1px 1px 0 rgba(0,0,0,.05)',
            },
          },
          MuiButton: {
            root: {
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 0,
              paddingBottom: 0,
              borderRadius: 20,
              color: 'white',
              textTransform: 'capitalize',
            },
          },
        },
        palette: {
          primary: {
            dark: shopconfig.COLORPRIMARY,
            main: shopconfig.COLORPRIMARY,
            paper: '#f5f5f5',
            contrastText: '#f3f3f3',
          },
          secondary: {
            main: shopconfig.COLORSECONDARY,
          },
          background: {
            default: '#fff',
          },
          type: 'dark', // Switching the dark mode on is a single property value change.
        },
        typography: {
          fontFamily: '"Spartan", "Helvetica", "Arial", sans-serif',
          fontSize: 14,
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 500,
        },
      })
    //if (shopconfig.COMSHO) document.title = `Welcome to ${shopconfig.COMSHO}`
  }, [shopconfig])

  const menus = []

  const handleChange = (event, newValue) => {
    setBottomvalue(newValue)
  }

  const ColorButton = withStyles((theme) => ({
    root: {
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: 20,
      color: 'white!important',
      textTransform: 'capitalize',
      backgroundColor: '#00acee',
      '&:hover': {
        backgroundColor: '#00acee',
        color: 'white!important',
      },
      '&:active': {
        backgroundColor: '#00acee',
        color: 'white!important',
      },
    },
  }))(Button)

  const handleDrawerOpen = () => {
    setOpen(true)
    setMenuOpenCat({
      services: true,
      aboutus: true,
      products: true,
      events: true,
    })
  }

  const handleDrawerClose = () => {
    setState({ ...state, left: false })

    /* setMenuOpenCat({
      services: false,
      aboutus: false,
      products: false,
    }) */
  }

  const handleClicktopmenu = (event) => {
    setAnchorEltopmenu(event.currentTarget)
  }

  const handleClosetopmenu = () => {
    setAnchorEltopmenu(null)
  }

  return (
    <ThemeProvider theme={muiTheme}>
      <SnackbarProvider>
        <BrowserRouter>
          <ScrollToTop>
            {renderRedirect()}

            <div className={classes.root}>
              <CssBaseline />

              <div className={classes.containerroot}>
                {/* <AppBar
                classes={{ root: classes.appbarroot }}
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                })}
              > */}
                <Toolbar variant="dense" className={classes.toolBar2}>
                  <Hidden only={['md', 'lg', 'xl']}>
                    <React.Fragment>
                      <IconButton
                        style={{ width: 48, marginLeft: 0, marginRight: 36 }}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer('left', true)}
                        edge="start"
                      >
                        <MenuIcon />
                      </IconButton>

                      <Drawer
                        anchor={'left'}
                        open={state['left']}
                        onClose={toggleDrawer('left', false)}
                      >
                        <div
                          role="presentation"
                          style={{ paddingTop: 30 }}
                          /* onClick={toggleDrawer('left', false)}
                            onKeyDown={toggleDrawer('left', false)} */
                        >
                          <List
                            component="nav"
                            dense={true}
                            classes={{ root: classes.listitemroot }}
                          >
                            <ListItem
                              button
                              onClick={() => {
                                /* setOpen(true) */
                                setMenuOpenCat({
                                  aboutus: !menuOpenCat.aboutus,
                                })
                              }}
                            >
                              <ListItemText primary="About" />
                              {menuOpenCat.aboutus ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </ListItem>
                            <Collapse
                              in={menuOpenCat.aboutus}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding dense>
                                <Link
                                  className={classes.link}
                                  to="/brandstory"
                                  onClick={handleDrawerClose}
                                >
                                  <ListItem button className={classes.nested}>
                                    <ListItemText primary="Brand Story" />
                                  </ListItem>
                                </Link>
                              </List>

                              <List component="div" disablePadding dense>
                                <Link
                                  className={classes.link}
                                  to="/936lae"
                                  onClick={handleDrawerClose}
                                >
                                  <ListItem button className={classes.nested}>
                                    <ListItemText primary="What is 936 LAE?" />
                                  </ListItem>
                                </Link>
                              </List>
                            </Collapse>
                          </List>

                          <List
                            component="nav"
                            dense={true}
                            classes={{ root: classes.listitemroot }}
                          >
                            <ListItem
                              button
                              onClick={() => {
                                /* setOpen(true) */
                                setMenuOpenCat({
                                  services: !menuOpenCat.services,
                                })
                              }}
                            >
                              <ListItemText primary="Services" />
                              {menuOpenCat.services ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </ListItem>
                            <Collapse
                              in={menuOpenCat.services}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding dense>
                                <Link
                                  className={classes.link}
                                  to="/936-lae-energy-room-treatment"
                                  onClick={handleDrawerClose}
                                >
                                  <ListItem button className={classes.nested}>
                                    <ListItemText primary="936 LAE Energy Room Treatment" />
                                  </ListItem>
                                </Link>
                              </List>
                              <List component="div" disablePadding dense>
                                <Link
                                  className={classes.link}
                                  to="/936-lae-active-water-treatment"
                                  onClick={handleDrawerClose}
                                >
                                  <ListItem button className={classes.nested}>
                                    <ListItemText primary="936 LAE Active Water Treatment" />
                                  </ListItem>
                                </Link>
                              </List>
                            </Collapse>
                          </List>

                          <List
                            component="nav"
                            dense={true}
                            classes={{ root: classes.listitemroot }}
                          >
                            <ListItem
                              button
                              onClick={() => {
                                /* setOpen(true) */
                                setMenuOpenCat({
                                  products: !menuOpenCat.products,
                                })
                              }}
                            >
                              <ListItemText primary="Products" />
                              {menuOpenCat.products ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </ListItem>
                            <Collapse
                              in={menuOpenCat.products}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding dense>
                                <Link
                                  className={classes.link}
                                  to="/product/0"
                                  onClick={handleDrawerClose}
                                >
                                  <ListItem button className={classes.nested}>
                                    <ListItemText primary="936 Wellness Retreat Mist" />
                                  </ListItem>
                                </Link>
                              </List>
                              <List component="div" disablePadding dense>
                                <Link
                                  className={classes.link}
                                  to="/product/1"
                                  onClick={handleDrawerClose}
                                >
                                  <ListItem button className={classes.nested}>
                                    <ListItemText primary="936 Beauty Mist" />
                                  </ListItem>
                                </Link>
                              </List>
                            </Collapse>
                          </List>

                          <List
                            component="nav"
                            dense={true}
                            classes={{ root: classes.listitemroot }}
                          >
                            <ListItem
                              button
                              onClick={() => {
                                /* setOpen(true) */
                                setMenuOpenCat({
                                  events: !menuOpenCat.events,
                                })
                              }}
                            >
                              <ListItemText primary="Events" />
                              {menuOpenCat.events ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </ListItem>
                            <Collapse
                              in={menuOpenCat.events}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding dense>
                                <Link
                                  className={classes.link}
                                  to="/promotion"
                                  onClick={handleDrawerClose}
                                >
                                  <ListItem button className={classes.nested}>
                                    <ListItemText primary="Promotion" />
                                  </ListItem>
                                </Link>
                              </List>
                            </Collapse>
                          </List>

                          <List
                            component="nav"
                            dense={true}
                            classes={{ root: classes.listitemroot }}
                          >
                            <ListItem
                              button
                              component={NavLink}
                              activeClassName={classes.isActive}
                              to="/investorrelation"
                              onClick={() => {
                                setState({ ...state, left: false })
                              }}
                            >
                              <ListItemText primary={`Investor Relation`} />
                            </ListItem>
                          </List>

                          {/* 
                          <List
                            component="nav"
                            dense={true}
                            classes={{ root: classes.listitemroot }}
                          >
                            <ListItem
                              button
                              component={NavLink}
                              activeClassName={classes.isActive}
                              to="/customer"
                              onClick={() => {
                                setState({ ...state, left: false })
                              }}
                            >
                              <ListItemText primary={`My Account`} />
                            </ListItem>
                          </List> */}

                          <List
                            component="nav"
                            dense={true}
                            classes={{ root: classes.listitemroot }}
                          >
                            <ListItem
                              button
                              component={NavLink}
                              activeClassName={classes.isActive}
                              to="/terms"
                              onClick={() => {
                                setState({ ...state, left: false })
                              }}
                            >
                              <ListItemText primary={`Terms & Condition`} />
                            </ListItem>
                          </List>

                          {/*  <List
                            component="nav"
                            dense={true}
                            classes={{ root: classes.listitemroot }}
                          >
                            <ListItem
                              button
                              component={NavLink}
                              activeClassName={classes.isActive}
                              to="/terms"
                              onClick={() => {
                                setState({ ...state, left: false })
                              }}
                            >
                              <ListItemText primary={`FAQ`} />
                            </ListItem>
                          </List> */}

                          <List
                            component="nav"
                            dense={true}
                            classes={{ root: classes.listitemroot }}
                          >
                            <ListItem
                              button
                              component={NavLink}
                              activeClassName={classes.isActive}
                              to="/contact"
                              onClick={() => {
                                setState({ ...state, left: false })
                              }}
                            >
                              <ListItemText primary={`Contact`} />
                            </ListItem>
                          </List>

                          <Divider />
                          <List dense={true}>
                            <ListItem button component={NavLink} to="/logout">
                              <ListItemText primary={`Logout`} />
                            </ListItem>
                          </List>
                          <Divider className={classes.divider} />
                        </div>
                      </Drawer>
                    </React.Fragment>
                  </Hidden>
                  <Typography className={classes.title} variant="h6" noWrap>
                    <Hidden only={['lg', 'xl', 'md']}>
                      <Link2 to="/">
                        <img
                          style={{ height: 40, verticalAlign: 'middle' }}
                          src={`/images/icon/logowhite.png`}
                        />
                      </Link2>
                    </Hidden>
                  </Typography>
                  <Hidden only={['lg', 'xl', 'md']}>
                    {signedIn && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CartButton />
                      </div>
                    )}
                    {!signedIn && (
                      <div
                        style={{ minWidth: 84, right: 0, textAlign: 'center' }}
                      >
                        <Link2 className={classes.signinMenu} to="/login">
                          Sign In
                        </Link2>
                      </div>
                    )}
                  </Hidden>
                </Toolbar>
              </div>

              <Hidden only={['sm', 'xs']}>
                <AppBar elevation={0} classes={{ root: classes.containerroot }}>
                  <Container maxWidth="md">
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          paddingTop: 0,
                        }}
                      >
                        <Link2 to="/">
                          <img
                            style={{ height: 60, marginTop: 13 }}
                            src={`/images/icon/logowhite.png`}
                          />
                        </Link2>
                      </div>
                      <div
                        style={{
                          marginLeft: 'auto',
                          paddingTop: 0,
                          position: 'relative',
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            right: 0,
                            textAlign: 'right',
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            paddingRight: 10,
                            zIndex: 99999,
                          }}
                        >
                          {!signedIn && (
                            <div>
                              <Button
                                disableRipple
                                size="small"
                                style={{
                                  color: 'white',
                                  backgroundColor: 'transparent',
                                }}
                                component={NavLink}
                                activeClassName={classes.isActive}
                                to="/login"
                              >
                                Login
                              </Button>
                              <span>/</span>
                              <Button
                                disableRipple
                                size="small"
                                style={{
                                  color: 'white',
                                  backgroundColor: 'transparent',
                                }}
                                component={NavLink}
                                activeClassName={classes.isActive}
                                to="/customer"
                              >
                                Signup
                              </Button>
                            </div>
                          )}
                          {signedIn && (
                            <h5
                              style={{
                                marginTop: 10,
                                fontWeight: 'normal',
                                marginBottom: 0,
                                paddingBottom: 0,
                              }}
                            >
                              Hi, {Cookies.get('FirstName')}{' '}
                              {Cookies.get('LastName')}{' '}
                              <IconButton
                                size="small"
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={handleClicktopmenu}
                              >
                                <ArrowDropDownIcon fontSize="small" />
                              </IconButton>
                              <Menu
                                id="long-menu"
                                anchorEl={anchorEltopmenu}
                                keepMounted
                                open={opentopmenu}
                                onClose={handleClosetopmenu}
                                PaperProps={{
                                  style: {
                                    maxHeight: 48 * 4.5,
                                    width: '15ch',
                                  },
                                }}
                              >
                                <MenuItem
                                  component={Link2}
                                  to="/order"
                                  onClick={handleClosetopmenu}
                                >
                                  <h5
                                    style={{
                                      fontWeight: 'normal',
                                      margin: 0,
                                      padding: 0,
                                    }}
                                  >
                                    My Orders
                                  </h5>
                                </MenuItem>
                                <MenuItem
                                  component={Link2}
                                  to="/customer"
                                  onClick={handleClosetopmenu}
                                >
                                  <h5
                                    style={{
                                      fontWeight: 'normal',
                                      margin: 0,
                                      padding: 0,
                                    }}
                                  >
                                    Update Account
                                  </h5>
                                </MenuItem>
                                <MenuItem
                                  component={Link2}
                                  to="/logout"
                                  onClick={handleClosetopmenu}
                                >
                                  <h5
                                    style={{
                                      fontWeight: 'normal',
                                      margin: 0,
                                      padding: 0,
                                    }}
                                  >
                                    Logout
                                  </h5>
                                </MenuItem>

                                {/* {options.map((option) => (
                                  <MenuItem
                                    key={option}
                                    selected={option === 'Pyxis'}
                                    onClick={handleClosetopmenu}
                                  >
                                    {option}
                                  </MenuItem>
                                ))} */}
                              </Menu>
                            </h5>
                          )}
                        </div>
                        <List
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            whiteSpace: 'nowrap',
                            padding: 0,
                            paddingTop: 30,
                          }}
                        >
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingLeft: 10,
                            }}
                          >
                            <Button
                              disableRipple
                              size="large"
                              style={{
                                color: 'white',
                                backgroundColor: 'transparent',
                              }}
                              component={NavLink}
                              activeClassName={classes.isActive}
                              exact={true}
                              to="/"
                            >
                              Home
                            </Button>
                          </ListItem>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingLeft: 10,
                            }}
                          >
                            <MenuDropdown
                              primary="About"
                              items={[
                                { Title: 'Brand Story', Url: '/brandstory' },

                                { Title: 'What is 936 LAE?', Url: '/936lae' },
                              ]}
                            />
                          </ListItem>

                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingLeft: 10,
                            }}
                          >
                            <MenuDropdown
                              primary="Services"
                              items={[
                                {
                                  Title: '936 LAE Energy Room Treatment',
                                  Url:
                                    // '/service/38/936-lae-energy-room-treatment',
                                    '/936-lae-energy-room-treatment',
                                },
                                {
                                  Title: '936 LAE Active Water Treatment',
                                  Url: '/936-lae-active-water-treatment',
                                },
                              ]}
                            />
                          </ListItem>

                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingLeft: 10,
                            }}
                          >
                            <MenuDropdown
                              primary="Products"
                              items={[
                                {
                                  Title: '936 Wellness Retreat Mist',
                                  Url: '/product/0',
                                },
                                {
                                  Title: '936 Beauty Mist',
                                  Url: '/product/1',
                                },
                                /* {
                                  Title: 'ELIFE',
                                  Url: '/product/2',
                                }, */
                              ]}
                            />
                          </ListItem>

                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingLeft: 10,
                            }}
                          >
                            <MenuDropdown
                              primary="Events"
                              items={[
                                {
                                  Title: 'Promotion',
                                  Url: '/promotion',
                                },
                              ]}
                            />
                          </ListItem>

                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingLeft: 10,
                            }}
                          >
                            <Button
                              disableRipple
                              size="large"
                              style={{
                                color: 'white',
                                backgroundColor: 'transparent',
                              }}
                              component={NavLink}
                              activeClassName={classes.isActive}
                              to="/investorrelation"
                            >
                              Investor Relation
                            </Button>
                          </ListItem>

                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingLeft: 10,
                            }}
                          >
                            <Button
                              disableRipple
                              size="large"
                              style={{
                                color: 'white',
                                backgroundColor: 'transparent',
                              }}
                              component={NavLink}
                              activeClassName={classes.isActive}
                              to="/myhealth"
                            >
                              My Health
                            </Button>
                          </ListItem>

                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingLeft: 10,
                            }}
                          >
                            <Button
                              disableRipple
                              size="large"
                              style={{
                                color: 'white',
                                backgroundColor: 'transparent',
                              }}
                              component={NavLink}
                              activeClassName={classes.isActive}
                              to="/contact"
                            >
                              Contact
                            </Button>
                          </ListItem>

                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingLeft: 10,
                            }}
                          >
                            <ColorButton
                              disableRipple
                              size="large"
                              component={NavLink}
                              activeClassName={classes.isActive}
                              to="/booking"
                            >
                              Booking Now
                            </ColorButton>
                          </ListItem>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingLeft: 10,
                            }}
                          >
                            {signedIn && <CartButton />}
                            {/* {!signedIn && (
                              <Button
                                size="large"
                                style={{ color: 'white', backgroundColor:'transparent' }}
                                component={NavLink}
                                activeClassName={classes.isActive}
                                to="/login"
                              >
                                Login
                              </Button>
                            )} */}
                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </Container>
                </AppBar>
              </Hidden>

              <main className={signedIn ? classes.content : classes.content}>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => {
                      if (dbhost) return <Shop />
                      else return <Home />
                    }}
                  />
                  <Route
                    path="/login"
                    render={(a) => <Login {...a} signedIn={setSignedIn} />}
                  />
                  <Route
                    path="/logout"
                    render={(a) => <Logout signedIn={setSignedIn} />}
                  />
                  <Route
                    exact
                    path="/myhealth"
                    render={(a) => {
                      if (signedIn) return <Report />
                      else return <Login {...a} signedIn={setSignedIn} />
                    }}
                  />
                  <Route
                    exact
                    path="/forgotpassword"
                    component={ForgotPassword}
                  ></Route>

                  <Route
                    path="/forgotpassword/:username/:key"
                    component={ForgotPassword}
                  ></Route>
                  <Route
                    path="/payment/success"
                    component={PaymentSuccess}
                  ></Route>
                  <Route path="/list/:id" component={Shop}></Route>
                  <Route path="/shop/:id" component={ShopDetails}></Route>
                  <Route path="/service/:id" component={ShopDetails3}></Route>

                  <Route path="/customer" component={Customer}></Route>
                  <Route path="/checkout" component={Checkout}></Route>
                  <Route
                    path="/booking/:id"
                    render={(a) => {
                      if (signedIn) return <Booking />
                      else return <Login {...a} signedIn={setSignedIn} />
                    }}
                  ></Route>
                  <Route
                    path="/booking"
                    render={(a) => {
                      if (signedIn) return <Booking />
                      else return <Login {...a} signedIn={setSignedIn} />
                    }}
                  ></Route>
                  <Route path="/investorrelation" component={Investor}></Route>
                  {/* <Route path="/terms" component={Terms}></Route> */}
                  <Route path="/promotion" component={Promotion}></Route>
                  <Route path="/936lae" component={WhatIsLae}></Route>
                  <Route path="/brandstory" component={BrandStory}></Route>
                  <Route path="/product/:id" component={Product}></Route>
                  <Route path="/product" component={Product}></Route>
                  <Route path="/contact" component={Contact}></Route>
                  <Route
                    path="/936-lae-energy-room-treatment-benefits"
                    component={ServicesEnergyRoomBenefits}
                  ></Route>

                  <Route
                    path="/936-lae-energy-room-treatment"
                    component={ServicesEnergyRoom}
                  ></Route>
                  <Route
                    path="/936-lae-active-water-treatment-benefits"
                    component={ServicesWaterBenefits}
                  ></Route>
                  <Route
                    path="/936-lae-active-water-treatment"
                    component={ServicesWater}
                  ></Route>
                  <Route
                    path="/quantum-magnetic-resonance-analyzer"
                    component={ServicesQuantum}
                  ></Route>
                  <Route path="/terms" component={Return}></Route>
                  <Route path="/faq" component={Faq}></Route>
                  <Route
                    path="/order/detail/:OrderNo"
                    component={OrderDetail}
                  ></Route>
                  <Route
                    path="/bookings/detail/:BookingNo"
                    render={(a) => {
                      if (signedIn) return <BookingDetail {...a} />
                      else return <Login {...a} signedIn={setSignedIn} />
                    }}
                  ></Route>
                  <Route
                    exact
                    path="/order"
                    render={(a) => {
                      if (signedIn) return <MyOrder />
                      else return <Login {...a} signedIn={setSignedIn} />
                    }}
                  ></Route>
                  <Route path="/mobilecart" component={MobileCart}></Route>
                  {/* <Route
                  path="/admin"
                  render={(a) => <Admin {...a} setTheme={setTheme} />}
                ></Route> */}
                </Switch>
              </main>

              <Hidden only={['lg', 'xl', 'md']}>
                <div style={{ height: 50 }}></div>
              </Hidden>
              <Hidden only={['sm', 'xs']}>
                <footer
                  style={{
                    background: 'url(/images/bg/9.jpg) no-repeat center center',
                    backgroundSize: 'cover',
                    color: 'white',
                    minHeight: 400,
                    fontWeight: 'normal',
                  }}
                  className={classes.footer}
                >
                  <Container maxWidth="md">
                    <Grid container spacing={0}>
                      <Grid itemScope md={5}>
                        <img
                          src="/images/icon/logowhite.png"
                          style={{ height: 80 }}
                        />
                        {/* <div style={{ marginTop: 10 }}>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit
                        </div> */}
                      </Grid>
                      <Grid item md={7}>
                        <Grid container spacing={3}>
                          <Grid item md={4}>
                            {/* <h3
                              style={{
                                letterSpacing: 10,
                                textTransform: 'uppercase',
                                color: 'white',
                                fontWeight: 'bold',
                              }}
                            >
                              Links
                            </h3>
                            <p>FAQ</p>
                            <p>Press</p>
                            <p>Career</p>
                            <p>Blog</p>
                            <p>Portfolio</p> */}
                          </Grid>
                          <Grid item md={4}>
                            <h3
                              style={{
                                letterSpacing: 10,
                                textTransform: 'uppercase',
                                color: 'white',
                                fontWeight: 'bold',
                              }}
                            >
                              Services
                            </h3>
                            <p>
                              <Link2
                                style={{
                                  textDecoration: 'none',
                                  color: 'white',
                                }}
                                to="/936-lae-energy-room-treatment"
                              >
                                936 LAE Energy Room
                              </Link2>
                            </p>
                            <p>
                              <Link2
                                style={{
                                  textDecoration: 'none',
                                  color: 'white',
                                }}
                                to="/936-lae-active-water-treatment"
                              >
                                936 LAE Active Water
                              </Link2>
                            </p>
                          </Grid>
                          <Grid item md={4}>
                            <h3
                              style={{
                                letterSpacing: 10,
                                textTransform: 'uppercase',
                                color: 'white',
                                fontWeight: 'bold',
                              }}
                            >
                              Company
                            </h3>
                            <p>
                              <Link2
                                style={{
                                  textDecoration: 'none',
                                  color: 'white',
                                }}
                                to="/brandstory"
                              >
                                About Us
                              </Link2>
                            </p>
                            <p>
                              <Link2
                                style={{
                                  textDecoration: 'none',
                                  color: 'white',
                                }}
                                to="/936-lae-energy-room-treatment"
                              >
                                Service
                              </Link2>
                            </p>
                            <p>
                              <Link2
                                style={{
                                  textDecoration: 'none',
                                  color: 'white',
                                }}
                                to="/product"
                              >
                                Product
                              </Link2>
                            </p>
                            <p>
                              <Link2
                                style={{
                                  textDecoration: 'none',
                                  color: 'white',
                                }}
                                to="/promotion"
                              >
                                Event
                              </Link2>
                            </p>
                            <p>
                              <Link2
                                style={{
                                  textDecoration: 'none',
                                  color: 'white',
                                }}
                                to="/investorrelation"
                              >
                                Investor Relation
                              </Link2>
                            </p>
                            <p>
                              <Link2
                                style={{
                                  textDecoration: 'none',
                                  color: 'white',
                                }}
                                to="/myhealth"
                              >
                                My Health
                              </Link2>
                            </p>
                            <p>
                              <Link2
                                style={{
                                  textDecoration: 'none',
                                  color: 'white',
                                }}
                                to="/contact"
                              >
                                Contact
                              </Link2>
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      {/* <Grid item md={5}></Grid> */}
                      <Grid item style={{ textAlign: 'left' }} md={12}>
                        <p>
                          <span
                            style={{ display: 'inline-block', marginRight: 10 }}
                          >
                            Copyright 936 Wellness Scientific Sdn. Bhd. 2020.
                            All Rights Reserved.
                          </span>{' '}
                          |
                          <Link
                            to="/terms"
                            style={{
                              textDecoration: 'none',
                              color: 'white',
                              display: 'inline-block',
                              marginLeft: 10,
                            }}
                          >
                            Terms & Condition
                          </Link>
                          {'  '}
                        </p>
                      </Grid>
                    </Grid>
                  </Container>
                </footer>
              </Hidden>

              {/* <Hidden only={['lg', 'xl', 'md']}></Hidden> */}
            </div>

            <Hidden only={['lg', 'xl', 'md']}>
              <BottomNavigation
                showLabels
                value={bottomvalue}
                onChange={handleChange}
                className={classes.stickToBottom}
              >
                <BottomNavigationAction
                  classes={{
                    root: classes.bottomroot,
                    label: classes.bottomlabel,
                  }}
                  component={Link}
                  to="/"
                  label="HOME"
                  value="home"
                  icon={<i className="fal fa-home-lg-alt fa-1x"></i>}
                />
                <BottomNavigationAction
                  classes={{
                    root: classes.bottomroot,
                    label: classes.bottomlabel,
                  }}
                  component={Link}
                  to="/booking"
                  label="BOOKING"
                  value="recents"
                  icon={<i className="fal fa-calendar-check fa-1x"></i>}
                />
                <BottomNavigationAction
                  classes={{
                    root: classes.bottomroot,
                    label: classes.bottomlabel,
                  }}
                  component={Link}
                  to="/myhealth"
                  value="report"
                  label="MY&nbsp;HEALTH"
                  icon={<i className="fal fa-heartbeat fa-1x"></i>}
                />
                <BottomNavigationAction
                  classes={{
                    root: classes.bottomroot,
                    label: classes.bottomlabel,
                  }}
                  component={Link}
                  to="/checkout"
                  value="cart"
                  label="CART"
                  icon={<i className="fal fa-cart-plus"></i>}
                />
                <BottomNavigationAction
                  classes={{
                    root: classes.bottomroot,
                    label: classes.bottomlabel,
                  }}
                  component={Link}
                  to="/order"
                  label="MY&nbsp;ORDER"
                  value="account"
                  icon={<i className="fal fa-shopping-bag"></i>}
                />
              </BottomNavigation>
            </Hidden>
          </ScrollToTop>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  )
}
