import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputBase from '@material-ui/core/InputBase'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { GET_SHOPCONFIG } from '../graphql/config'
import { useMutation, useQuery } from '@apollo/react-hooks'

import ImageGallery from 'react-image-gallery'

import ReactImageMagnify from 'react-image-magnify'
import 'react-image-gallery/styles/css/image-gallery.css'
import Button from '@material-ui/core/Button'
import Cookies from 'js-cookie'
import Fade from '@material-ui/core/Fade'
import { PRODUCT_IMAGES, GET_PRODUCT, GET_PRODUCTS } from '../graphql/product'
import {
  GET_CARTS,
  INSERT_CART,
  UPDATE_CART,
  REMOVE_CART,
} from '../graphql/cart'
import { GET_CATEGORIES } from '../graphql/category'

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const restApi = process.env.REACT_APP_API
const useStyles = makeStyles((theme) => ({
  paperroot: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(2),
    border: '1px solid #b4b4b4',
    marginBottom: theme.spacing(1),
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  productDesc: {
    fontWeight: 'normal',
  },
}))

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.palette.grey[200],
    border: 1,
    borderColor: theme.palette.primary.main,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
  },
}))(InputBase)

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function ShopDetails(props) {
  const [images, setImages] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)
  const [showPage, setShowPage] = useState(false)
  const [showDesc, setShowDesc] = useState(0)

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const { loading: loadingProducts, data: { products } = { products: [] } } =
    useQuery(GET_PRODUCTS)

  const {
    loading: loadingProduct,
    data: { product } = { product: {} },
    refetch: refetchProduct,
  } = useQuery(GET_PRODUCT, { variables: { id: props.match.params.id } })

  const {
    loading: loadingImages,
    data: { productimages } = { productimages: [] },
    refetch: refetchImages,
  } = useQuery(PRODUCT_IMAGES, { variables: { id: props.match.params.id } })

  const { data: { categories } = { categories: [] } } = useQuery(GET_CATEGORIES)

  const {
    loading: loadingCarts,
    data: { carts } = { carts: [] },
    refetch: refetchCart,
  } = useQuery(GET_CARTS, { skip: !Cookies.get('signedin') })

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })
    const latest = data.insertcart
    cache.writeQuery({
      query: GET_CARTS,
      data: { carts: [...old.carts, latest] },
    })
  }

  const [insertCart] = useMutation(INSERT_CART, {
    update: addCache,
  })

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })
    const latest = data.updatecart
    const foundIndex = old.carts.findIndex((item) => item.id === latest.id)
    old.carts.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_CARTS,
      data: { carts: [...old.carts] },
    })
  }

  const [updateCart] = useMutation(UPDATE_CART, { update: updateCache })

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })

    if (data.removecart) {
      const latest = old.carts.filter(
        (item) => item.PriceID != data.removecart.PriceID
      )
      cache.writeQuery({
        query: GET_CARTS,
        data: { carts: [...latest] },
      })
    }
  }

  const [removeCart] = useMutation(REMOVE_CART, { update: deleteCache })

  const [selectedPrice, setSelectedPrice] = useState()

  const classes = useStyles(shopconfig)
  const history = useHistory()

  const [dbhost, setDbhost] = useState('spa1')

  useEffect(() => {
    if (product.ProductPrices)
      setSelectedPrice(product.ProductPrices.split(',')[0].split('|')[2])
  }, [product])

  /*const [dbhost, setDbhost] = useState('shop')*/

  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
    setTimeout(() => {
      setOpen(false)
    }, 5000)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const myRenderItem = (item) => {
    return (
      <div key={Math.random()}>
        <ReactImageMagnify
          {...{
            smallImage: {
              alt: 'Wristwatch by Ted Baker London',
              isFluidWidth: true,
              src: item.original,
            },
            largeImage: {
              src: item.original,
              width: 1123,
              height: 1583,
            },
            enlargedImagePortalId: 'zoomed',
          }}
        />
        {/*
         */}
      </div>
    )
  }

  useEffect(() => {
    const arr = []
    productimages.map((a) => {
      arr.push({
        original: `${restApi}/media/${dbhost}/uploads/invoice/${a.FileName}`,
        thumbnail: `${restApi}/media/${dbhost}/uploads/invoice/${a.FileName}`,
      })
    })

    setImages(arr)
  }, [productimages])

  useEffect(() => {
    const totalAmount = carts.reduce((a, b) => a + b.InvoiceAmount, 0)
    setTotalAmount(totalAmount)
  }, [carts])

  useEffect(() => {
    setTimeout(() => {
      setShowPage(true)
    }, 200)
    window.scrollTo(0, 0)
  }, [])

  if (!product.id || productimages.length === 0)
    return <React.Fragment></React.Fragment>

  return (
    <React.Fragment>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          icon={<i class="fal fa-cart-plus"></i>}
          onClose={handleClose}
          severity="success"
        >
          Added to Cart!
        </Alert>
      </Snackbar>

      {/* <Container classes={{ root: classes.containerroot }} maxWidth="md">
        <DesktopTopMenu />
      </Container> */}
      <Fade in={showPage}>
        <div>
          <Container
            style={{ marginTop: 20 }}
            classes={{ root: classes.containerroot }}
            maxWidth="md"
          >
            <Grid container spacing={3}>
              {/* <Grid style={{ paddingTop: 20 }} item xs={12} sm={12} md={3}>
            <h3 style={{ marginBottom: 0 }}>Our Treatment</h3>
            <Divider style={{ marginTop: 20 }} />

            {products.length > 0 && (
              <MenuCategory category={product.Category} categories={products} />
            )}
          </Grid> */}

              <Grid item xs={12} sm={12} md={5}>
                <ImageGallery
                  height={100}
                  disableSwipe={false}
                  disableThumbnailScroll={false}
                  thumbnailPosition="bottom"
                  showNav={false}
                  showPlayButton={false}
                  showFullscreenButton={false}
                  useBrowserFullscreen={false}
                  items={images}
                  onThumbnailClick={(e, i) => {
                    setShowDesc(i)
                  }}
                  /* renderItem={myRenderItem.bind(this)} */
                />
              </Grid>
              <Grid item xs={12} sm={12} md={7} style={{ paddingLeft: 20 }}>
                <div
                  style={{ position: 'absolute', zIndex: 1000 }}
                  id="zoomed"
                ></div>
                <h2 style={{ marginBottom: 0 }}>{product.ProductName}</h2>

                {/*  {product.Category && (
              <Typography variant="body2" color="textSecondary" component="p">
                {`${product.Category}`}
              </Typography>
            )} */}

                {/* <div
              className={classes.productDesc}
              dangerouslySetInnerHTML={{
                __html: product.ProductDesc,
              }}
            /> */}

                <Divider style={{ marginTop: 20 }} />

                <div style={{ paddingTop: 25, minHeight: 30 }}>
                  {product.ProductPrices &&
                    product.ProductPrices.split(',').length === 1 &&
                    product.ProductPrices.split(',').map((price, i) => {
                      const priceitem = price.split('|')
                      return (
                        <div key={i}>
                          <span
                            style={{
                              fontWeight: 'bold',
                              fontSize: 17,
                              display: 'inline-block',
                              paddingRight: 10,
                            }}
                          >
                            {priceitem[0]}
                          </span>
                        </div>
                      )
                    })}

                  {selectedPrice &&
                    product.ProductPrices &&
                    product.ProductPrices.split(',').length > 1 && (
                      <React.Fragment>
                        <Select
                          defaultValue={selectedPrice}
                          value={selectedPrice}
                          onChange={(e) => {
                            setSelectedPrice(e.target.value)
                          }}
                          input={<BootstrapInput />}
                        >
                          {product.ProductPrices.split(',').map((price, i) => {
                            const priceitem = price.split('|')
                            console.log(product)
                            return (
                              <MenuItem key={i} value={priceitem[2]}>
                                {priceitem[3]} - <strong>{priceitem[0]}</strong>
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </React.Fragment>
                    )}
                </div>

                {/* {product.UnitsInStock > 10 && (
              <div
                style={{ marginTop: 15, color: '#757575' }}
              >{`${product.UnitsInStock} piece available`}</div>
            )} */}

                {/* {product.UnitsInStock < 11 && product.UnitsInStock > 0 && (
              <div
                style={{ marginTop: 15, color: 'red' }}
              >{`Only ${product.UnitsInStock} piece left`}</div>
            )} */}

                {product.UnitsInStock == 0 && (
                  <div
                    style={{ marginTop: 15, color: 'red' }}
                  >{`Out of stock`}</div>
                )}

                <div style={{ marginTop: 25 }}>
                  {product.UnitsInStock > 0 &&
                    !carts.find(
                      (a) =>
                        a.ProductID == product.id && a.PriceID == selectedPrice
                    ) && (
                      <React.Fragment>
                        <Button
                          disableElevation
                          variant="contained"
                          size="small"
                          color="primary"
                          onClick={() => {
                            if (!Cookies.get('signedin')) {
                              history.push('/login')
                              return
                            }
                            insertCart({
                              variables: {
                                ProductID: product.id,
                                PriceID: selectedPrice,
                                Qty: 1,
                              },
                            })

                            handleClick()
                          }}
                        >
                          Add to cart
                        </Button>
                        <Button
                          variant="outlined"
                          size="large"
                          color="primary"
                          style={{ minWidth: 0, marginLeft: 10 }}
                          onClick={() => {
                            history.push('/checkout')
                          }}
                        >
                          View Cart
                        </Button>
                      </React.Fragment>
                    )}
                  {carts.find(
                    (a) =>
                      a.ProductID == product.id && a.PriceID == selectedPrice
                  ) && (
                    <React.Fragment>
                      <Button
                        variant="contained"
                        size="small"
                        disableElevation
                        color="primary"
                        style={{ minWidth: 0, marginRight: 10 }}
                        onClick={() => {
                          if (!Cookies.get('signedin')) {
                            history.push('/login')
                            return
                          }
                          updateCart({
                            variables: {
                              ProductID: product.id,
                              PriceID: selectedPrice,
                              Qty:
                                carts.find(
                                  (a) =>
                                    a.ProductID == product.id &&
                                    a.PriceID == selectedPrice
                                ).Qty + 1,
                            },
                          })
                        }}
                      >
                        <AddIcon />
                      </Button>

                      <Button
                        variant="contained"
                        size="small"
                        disableElevation
                        color="primary"
                        style={{ minWidth: 0 }}
                        onClick={() => {
                          if (!Cookies.get('signedin')) {
                            history.push('/login')
                            return
                          }
                          if (
                            carts.find(
                              (a) =>
                                a.ProductID == product.id &&
                                a.PriceID == selectedPrice
                            ).Qty > 1
                          ) {
                            updateCart({
                              variables: {
                                ProductID: product.id,
                                PriceID: selectedPrice,
                                Qty:
                                  carts.find(
                                    (a) =>
                                      a.ProductID == product.id &&
                                      a.PriceID == selectedPrice
                                  ).Qty - 1,
                              },
                            })
                          } else {
                            setTimeout(() => {
                              removeCart({
                                variables: {
                                  id: product.id,
                                  PriceID: selectedPrice,
                                },
                              })
                            }, 1)
                          }
                        }}
                      >
                        <RemoveIcon />
                      </Button>
                    </React.Fragment>
                  )}
                </div>

                <div
                  style={{
                    minHeight: carts.find((a) => a.ProductID == product.id)
                      ? 38
                      : 40,
                    textAlign: 'left',
                  }}
                >
                  {carts.find(
                    (a) =>
                      a.ProductID == product.id && a.PriceID == selectedPrice
                  ) && (
                    <h3
                      style={{
                        margin: 0,
                        paddingTop: 10,
                        color: shopconfig.COLORPRIMARY,
                      }}
                    >
                      {
                        carts.find(
                          (a) =>
                            a.ProductID == product.id &&
                            a.PriceID == selectedPrice
                        ).Qty
                      }{' '}
                      in cart
                    </h3>
                  )}
                </div>

                {carts.find(
                  (a) => a.ProductID == product.id && a.PriceID == selectedPrice
                ) && (
                  <div style={{ marginTop: 10 }}>
                    <Button
                      variant="outlined"
                      size="large"
                      color="primary"
                      style={{ minWidth: 0 }}
                      onClick={() => {
                        history.push('/checkout')
                      }}
                    >
                      View Cart
                    </Button>
                  </div>
                )}

                {/* <div style={{ marginTop: 20 }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: product.Youtube,
                    }}
                  />
                </div> */}

                {/* <div style={{ marginTop: 20 }}>
                  <FacebookShareButton
                    url={window.location.href}
                    quote={product.ProductName}
                  >
                    <FacebookIcon size={32} round={false} />
                  </FacebookShareButton>{' '}
                  <WhatsappShareButton
                    url={window.location.href}
                    quote={product.ProductName}
                  >
                    <WhatsappIcon size={32} round={false} />
                  </WhatsappShareButton>
                </div> */}

                {/* <div>
                  <Divider style={{ marginTop: 20 }} />
                  {products.length > 0 && (
                    <List>
                      {products &&
                        products.map(
                          (a, i) =>
                            a.Category == 2 && (
                              <ListItem style={{ paddingLeft: 0 }} key={i}>
                                <Link2
                                  className={classes.menuItem}
                                  to={`/service/${a.id}/${a.PrettyUrl}`}
                                >
                                  <img
                                    src={`${restApi}/media/${dbhost}/uploads/invoice/${a.ProductImage}`}
                                    height={40}
                                  />{' '}
                                  {a.ProductName}
                                </Link2>
                              </ListItem>
                            )
                        )}
                    </List>
                  )}
                </div> */}
                <div style={{ display: showDesc == 0 ? 'block' : 'none' }}>
                  <Fade in={showDesc == 0}>
                    <div>
                      <h2>936 LAE Room Treatment</h2>
                      <h3>Urban Individuals, Are You Sweating Enough?</h3>
                      <p>
                        Wavelength of human organs and tissues are about 9.36
                        microns, By harnessing an LAE wavelength of 8 ~ 12
                        microns, 936 LAE Energy Room is able to generate
                        resonance with our cells, breaking the hydrogen
                        bonds.Water molecules in the body transform into smaller
                        clusters, which is highly conducive for our metabolism
                        and ultimately, overall health.Direct heat exposure
                        increases the penetration strength of this LAE Wave,
                        reaching deep into the tissues to burn excess fat and
                        promote the excretion of heavy metals, toxins and
                        fat-soluble waste matter through the sebaceous glands.
                        Rebalance the endocrine system and improve autoimmunity.{' '}
                      </p>
                    </div>
                  </Fade>
                </div>
                <div style={{ display: showDesc == 1 ? 'block' : 'none' }}>
                  <Fade in={showDesc == 1}>
                    <div>
                      <h2>936 Active Water Treatment</h2>
                      <p>
                        Active Water LAE wave can easily penetrate into the body
                        and absorbed by cells. “LAE Active Water” can stimulate
                        blood circulation, bring sufficient oxygen to the blood,
                        improve the skin's metabolic capacity, moisturize skin
                        and eliminate fatigue; Prevent and improves scalp
                        pruritus, dandruff and hair-fall problems.
                      </p>
                    </div>
                  </Fade>
                </div>
              </Grid>
            </Grid>

            <br />
            <br />
            <br />
          </Container>
        </div>
      </Fade>
    </React.Fragment>
  )
}
