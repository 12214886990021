import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Redirect,
  Route,
  BrowserRouter,
  Switch,
  withRouter,
  Link,
  Link as Link2,
  NavLink,
} from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'

const restApi = process.env.REACT_APP_API

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  listRoot: {
    width: '100%',
    maxWidth: '36ch',
    padding: 0,
    overflowY: 'scroll',
    flexGrow: 1,
    backgroundColor: '#ebebeb',
  },
  media: {
    height: 150,
    margin: theme.spacing(1),
  },
  card: {},
  buttonRoot: {
    minWidth: 0,
    marginRight: 5,
    padding: 7,
  },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  containerroot: {
    paddingTop: 20,
    paddingBottom: 50,
    paddingLeft: 0,
    paddingRight: 0,
  },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  rootBootstrapHelpertext: {
    color: 'white',
    marginTop: theme.spacing(2),
  },
  inputBootstrap: {
    borderRadius: 6,
    color: 'white',
    position: 'relative',
    backgroundColor: 'transparent',
    border: '3px solid #ced4da',
    width: '100%',
    padding: '13px 10px 10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderColor: (props) => `${props.COLORPRIMARY}`,
    },
  },
  productImages: {
    height: '100%',
    maxHeight: 80,

    [theme.breakpoints.up('md')]: {
      height: '100%',
      maxHeight: 220,
    },
  },
}))

export default function ServicesQuantum(props) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div
        style={{
          background: 'url(/images/bg/4.jpg) no-repeat center center',
          backgroundSize: 'cover',
          display: 'flex',
          alignItems: 'center',
          margin: 0,
        }}
      >
        <Container
          style={{
            marginTop: 50,
            paddingTop: 0,
            paddingBottom: 0,
            marginBottom: 0,
          }}
          maxWidth="md"
        >
          <Grid style={{ marginTop: 0 }} container spacing={0}>
            <Grid
              item
              xs={12}
              md={12}
              style={{ color: 'white', textAlign: 'center' }}
            >
              <h1
                style={{ fontWeight: 'normal', marginBottom: 0, marginTop: 20 }}
              >
                Services
              </h1>
            </Grid>
          </Grid>
          <Grid
            style={{ marginTop: 20, paddingBottom: 0 }}
            container
            spacing={2}
          >
            <Grid
              item
              xs={4}
              md={4}
              style={{
                color: 'white',
                textAlign: 'center',
              }}
            >
              <div>
                <Link2 to="/936-lae-energy-room-treatment">
                  <img
                    src="/images/icon/LAEroom.png"
                    className={classes.productImages}
                  />
                </Link2>
                <br />

                <Hidden only={['md', 'lg', 'xl']}>
                  <div>
                    <h3
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 20,
                        marginTop: 20,
                      }}
                    >
                      936 LAE Energy
                      <br />
                      Room Treatment
                    </h3>
                    <Button
                      component={Link2}
                      to="/booking"
                      variant="contained"
                      size="large"
                      color="primary"
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                      Book Now
                    </Button>
                  </div>
                </Hidden>

                <Hidden only={['sm', 'xs']}>
                  <div>
                    <h2
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 20,
                        marginTop: 20,
                      }}
                    >
                      936 LAE Energy
                      <br />
                      Room Treatment
                    </h2>
                    <p style={{ marginBottom: 20 }}>
                      The convenience of moderm living has greatly improved our
                      lives, but these creature comfort might not necessarily be
                      all that great to our health.
                    </p>
                    {/* <Button
                      component={Link2}
                      to="/booking"
                      variant="contained"
                      size="large"
                      color="primary"
                    >
                      Booking Now
                    </Button> */}
                  </div>
                </Hidden>
              </div>
            </Grid>

            <Grid
              item
              xs={4}
              md={4}
              style={{
                color: 'white',
                textAlign: 'center',
              }}
            >
              <div>
                <Link2 to="/936-lae-active-water-treatment">
                  <img
                    src="/images/icon/LAEwater.png"
                    className={classes.productImages}
                  />
                </Link2>
                <br />

                <Hidden only={['md', 'lg', 'xl']}>
                  <div>
                    <h3
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 20,
                        marginTop: 20,
                      }}
                    >
                      936 LAE Active
                      <br />
                      Water Treatment
                    </h3>
                    <Button
                      component={Link2}
                      to="/booking"
                      variant="contained"
                      size="large"
                      color="primary"
                    >
                      Book Now
                    </Button>
                  </div>
                </Hidden>

                <Hidden only={['sm', 'xs']}>
                  <div>
                    <h2
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 20,
                        marginTop: 20,
                      }}
                    >
                      936 LAE Active
                      <br />
                      Water Treatment
                    </h2>
                    <p style={{ marginBottom: 20 }}>
                      "LAE ACTIVE WATER" can stimulate blood circulation, bring
                      sufficient oxygen to the blood, improve the skin's
                      metabolic capacity, moisturize skin and eliminate fatigue;
                      Prevent and improves scalp pruritus, dandruff and
                      hair-fall problems.
                    </p>
                    <Button
                      component={Link2}
                      to="/booking"
                      variant="contained"
                      size="large"
                      color="primary"
                    >
                      Booking Now
                    </Button>
                  </div>
                </Hidden>
              </div>
            </Grid>

            <Grid
              item
              xs={4}
              md={4}
              style={{
                color: 'white',
                textAlign: 'center',
              }}
            >
              <div>
                <img
                  src="/images/icon/quantummagnetic.png"
                  className={classes.productImages}
                />
                <br />

                <Hidden only={['md', 'lg', 'xl']}>
                  <div>
                    <h3
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 20,
                        marginTop: 20,
                      }}
                    >
                      Quantum Magnetic
                      <br />
                      Resonance Analyser
                    </h3>
                    {/* <Button
                      component={Link2}
                      to="/booking"
                      variant="contained"
                      size="large"
                      color="primary"
                    >
                      Book Now
                    </Button> */}
                  </div>
                </Hidden>

                <Hidden only={['sm', 'xs']}>
                  <div>
                    <h2
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 20,
                        marginTop: 20,
                      }}
                    >
                      Quantum Magnetic
                      <br />
                      Resoncance Analyser
                    </h2>
                    <p style={{ marginBottom: 20 }}>
                      We help clients deliver more effective and efficient 40
                      types general health examination with quantum magnetic
                      resonance health analyzer.
                    </p>
                    <Button
                      component={Link2}
                      to="/booking"
                      variant="contained"
                      size="large"
                      color="primary"
                    >
                      Booking Now
                    </Button>
                  </div>
                </Hidden>
              </div>
            </Grid>
          </Grid>

          <Grid style={{ marginTop: 20 }} container spacing={0}>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Button
                component={Link2}
                to="/booking"
                variant="contained"
                size="large"
                color="primary"
              >
                Booking Now
              </Button>
            </Grid>
          </Grid>

          <Grid style={{ marginTop: 40 }} container spacing={0}>
            <Grid
              item
              xs={4}
              md={4}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            ></Grid>
            <Grid
              item
              xs={4}
              md={4}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            ></Grid>
            <Grid
              item
              xs={4}
              md={4}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  width: 0,
                  height: 0,
                  borderLeft: '20px solid transparent',
                  borderRight: '20px solid transparent',
                  borderBottom: '30px solid white',
                }}
              ></div>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          background:
            'url(/images/service/bg/smallbar.jpg) no-repeat center center',
          backgroundSize: 'cover',
          height: 200,
          display: 'flex',
          alignItems: 'center',
          color: 'white',
        }}
      >
        <h1
          style={{
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
          }}
        >
          What is 936 LAE Active Water Treatment?
        </h1>
      </div>

      <div
        style={{
          background:
            'url(/images/service/quantum/2bg.jpg) no-repeat bottom  right',
          backgroundSize: 'cover',
          color: 'white',
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div>
                <Hidden only={['sm', 'xs']}>
                  <p
                    style={{
                      paddingTop: 10,
                      paddingLeft: 150,
                      paddingRight: 150,
                      textAlign: 'center',
                    }}
                  >
                    We help clients deliver more effective and efficient 40
                    types general health examination with quantum magnetic
                    resonance health analyzer.
                  </p>
                </Hidden>
                <Hidden only={['md', 'lg', 'xl']}>
                  <p
                    style={{
                      paddingTop: 10,
                      paddingLeft: 30,
                      paddingRight: 30,
                      textAlign: 'center',
                    }}
                  >
                    We help clients deliver more effective and efficient 40
                    types general health examination with quantum magnetic
                    resonance health analyzer.
                  </p>
                </Hidden>
              </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ display: 'flex' }}>
              <img
                src="/images/service/quantum/2.png"
                style={{
                  width: '100%',
                  alignSelf: 'flex-end',
                  marginTop: 'auto',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  )
}
