import { gql } from 'apollo-boost'

export const GET_HEALTHREPORTS = gql`
  {
    healthreports {
      id
      BuyerID
      Filename
      Title
      Gender
      Name
      Age
      Height
      Weight
      TestingTime
    }
  }
`

export const GET_HEALTHREPORTDETAILS = gql`
  query healthreportDetails($id: ID!) {
    healthreportdetails(id: $id) {
      id
      BuyerID
      Filename
      Title
      Gender
      Name
      Age
      Height
      Weight
      TestingTime
      HealthReportDetails {
        id
        ReportID
        TestingItem
        NormalRange
        ActualMeasurement
        TestingResult
      }
    }
  }
`

export const GET_HEALTHREPORTDETAIL = gql`
  query healthreportDetail($id: ID!) {
    healthreportdetail(id: $id) {
      id
      ReportID
      TestingItem
      NormalRange
      ActualMeasurement
      TestingResult
    }
  }
`
