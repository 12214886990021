import React, { useState, useRef } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Grid,
  Card,
  CardContent,
  CardActionArea,
  CardActions,
  Button,
  CardMedia,
  List,
  Link,
  Paper,
  Typography,
} from '@material-ui/core/'

import { Link as Link2, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import { green, purple } from '@material-ui/core/colors'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'
import Pagination from '@material-ui/lab/Pagination'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import Tabs from '@material-ui/core/Tabs'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Tab from '@material-ui/core/Tab'
import CssBaseline from '@material-ui/core/CssBaseline'

import { GET_PRODUCTS, TOTAL_PRODUCTS } from '../graphql/product'
import {
  GET_CARTS,
  INSERT_CART,
  UPDATE_CART,
  REMOVE_CART,
} from '../graphql/cart'
import { GET_MYORDERS } from '../graphql/order'
import { GET_CATEGORIES } from '../graphql/category'
import { GET_SHOPCONFIG } from '../graphql/config'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useEffect } from 'react'
import Cart from './cart'
import ShopDetails from './shopDetails'
import Loading from '../common/loading'
import DesktopTopMenu from '../pages/desktoptopmenu'

import Menu from './menu'
import MenuCategory from './menuCategory'

const restApi = process.env.REACT_APP_API

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  padding: {
    padding: theme.spacing(3),
  },
  border: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },

  root: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  listRoot: {
    width: '100%',
    maxWidth: '36ch',
    padding: 0,
    overflowY: 'scroll',
    flexGrow: 1,
    backgroundColor: '#ebebeb',
  },
  media: {
    height: 150,
    margin: theme.spacing(1),
  },
  card: {},
  paperroot: {
    padding: theme.spacing(1),
    border: '1px solid #b4b4b4',
    marginBottom: theme.spacing(1),
  },
  dividerroot: {
    marginBottom: theme.spacing(2),
  },
  containerroot: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
}))

const StyledTabs = withStyles((theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />)

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />)

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export default function Shop(props) {
  const classes = useStyles()
  const history = useHistory()

  const [dbhost, setDbhost] = useState('spa1')
  /*const [dbhost, setDbhost] = useState('shop')*/

  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function tabProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }

  const {
    loading: loadingTotal,
    data: { myorders } = { myorders: [] },
    refetch: refetchOrder,
  } = useQuery(GET_MYORDERS)

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!Cookies.get('signedin')) {
      history.push('/login')
    }

    refetchOrder()
  }, [props])

  return (
    <React.Fragment>
      <CssBaseline />
      <Hidden only={['sm', 'xs']}>
        {/* <Container classes={{ root: classes.containerroot }} maxWidth="md">
          <DesktopTopMenu />
        </Container> */}
        <br />
      </Hidden>
      <Container classes={{ root: classes.containerroot }} maxWidth="md">
        <StyledTabs
          className={classes.border}
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab label="Order Pending for Collection" {...tabProps(0)} />
          {/*  <StyledTab label="Collected" {...tabProps(1)} /> */}
          <StyledTab label="Completed Orders" {...tabProps(1)} />
        </StyledTabs>
        <TabPanel value={value} index={0}>
          <div>
            {/* {<pre>{JSON.stringify(myorders, 0, 4)}</pre>} */}
            {myorders.map(
              (order, i) =>
                order.OrderDetails &&
                order.OrderDetails.find((a) => a.Category == '1') &&
                order.OrderDetails.find((a) => a.Status == 'Not Collected') && (
                  <React.Fragment key={i}>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item xs={12} md={2}>
                        Paid on : <br />
                        <strong style={{ fontSize: 18 }}>
                          {new Intl.DateTimeFormat('en-GB', {
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(order.CreatedOn))}
                        </strong>
                      </Grid>

                      <Grid item xs={12} md={5}>
                        Order no : <strong>{order.OrderNo}</strong>
                        <br />
                        <Button
                          style={{ marginTop: 10 }}
                          size="large"
                          variant="contained"
                          disableElevation
                          color="primary"
                          component={Link2}
                          to={`/order/detail/${order.OrderNo}`}
                        >
                          Collect
                        </Button>
                      </Grid>

                      <Hidden only={['sm', 'xs']}>
                        <Grid item xs={12} md={5}>
                          Item(s) : <strong>{order.TotalItem}</strong>
                          <br />
                          Total Amount :
                          <strong>
                            {' '}
                            RM{parseFloat(order.TotalAmount).toFixed(2)}
                          </strong>
                          {/*  <br />
                          <strong>Delivery Charges: </strong>
                          RM{parseFloat(order.DeliveryCharges).toFixed(2)} */}
                        </Grid>
                      </Hidden>
                    </Grid>

                    {i < myorders.length - 1 && (
                      <Divider style={{ marginTop: 20, marginBottom: 23 }} />
                    )}
                  </React.Fragment>
                )
            )}
          </div>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <div>
            {myorders.map(
              (order, i) =>
                order.OrderDetails &&
                !order.OrderDetails.find(
                  (a) => a.Status == 'Not Collected'
                ) && (
                  <React.Fragment key={i}>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item xs={12} md={2}>
                        Paid on : <br />
                        <strong style={{ fontSize: 18 }}>
                          {new Intl.DateTimeFormat('en-GB', {
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(order.CreatedOn))}
                        </strong>
                      </Grid>

                      <Grid item xs={12} md={5}>
                        Order no : <strong>{order.OrderNo}</strong>
                        <br />
                        <Button
                          style={{ marginTop: 10 }}
                          size="large"
                          variant="contained"
                          disableElevation
                          color="primary"
                          component={Link2}
                          to={`/order/detail/${order.OrderNo}`}
                        >
                          Order details
                        </Button>
                      </Grid>

                      <Hidden only={['sm', 'xs']}>
                        <Grid item xs={12} md={5}>
                          Item(s) : <strong>{order.TotalItem}</strong>
                          <br />
                          Total Amount :{' '}
                          <strong>
                            RM{parseFloat(order.TotalAmount).toFixed(2)}
                          </strong>
                          {/* <br />
                          <strong>Delivery Charges: </strong>
                          RM{parseFloat(order.DeliveryCharges).toFixed(2)} */}
                        </Grid>
                      </Hidden>
                    </Grid>
                    {i < myorders.length - 1 && (
                      <Divider style={{ marginTop: 20, marginBottom: 23 }} />
                    )}
                  </React.Fragment>
                )
            )}
          </div>
        </TabPanel>

        {/* <div className={classes.toolbar}>
          <div className={classes.root}>
            <div style={{ display: 'flex' }}>
              <div style={{ flexGrow: 1, padding: 10 }}>

                <div style={{ fontSize: 17, fontWeight: 'bold' }}>
                  Open Order
                </div>

                <Divider classes={{ root: classes.dividerroot }} />
                {myorders.map(
                  (order) =>
                    order.StatusCode == 'ORDERCREATED' && (
                      <Paper
                        classes={{ root: classes.paperroot }}
                        variant="outlined"
                      >
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-start"
                        >
                          <Grid item xs={12} md={2}>
                            <strong style={{ fontSize: 18 }}>
                              {new Intl.DateTimeFormat('en-GB', {
                                month: 'short',
                                day: 'numeric',
                              }).format(new Date(order.CreatedOn))}
                            </strong>
                          </Grid>

                          <Grid item xs={12} md={5}>
                            <strong>Order no: </strong>
                            {order.OrderNo}
                            <br />
                            <br />
                            <Button
                              variant="contained"
                              disableElevation
                              color="primary"
                              component={Link2}
                              to={`/order/detail/${order.OrderNo}`}
                            >
                              Make payment
                            </Button>
                          </Grid>

                          <Hidden only={['sm', 'xs']}>
                            <Grid item xs={12} md={5}>
                              <strong>Items: </strong>
                              {order.TotalItem}
                              <br />
                              <strong>Total Amount: </strong>
                              RM{parseFloat(order.TotalAmount).toFixed(2)}
                              <br />
                              <strong>Delivery Charges: </strong>
                              RM{parseFloat(order.DeliveryCharges).toFixed(2)}
                            </Grid>
                          </Hidden>
                        </Grid>
                      </Paper>
                    )
                )}

                <br />

                <div style={{ fontSize: 17, fontWeight: 'bold' }}>
                  Pending Delivery
                </div>

                <Divider classes={{ root: classes.dividerroot }} />
                {myorders.map(
                  (order) =>
                    order.StatusCode != 'ORDERCREATED' &&
                    order.Status != 'Completed' && (
                      <Paper
                        classes={{ root: classes.paperroot }}
                        variant="outlined"
                      >
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-start"
                        >
                          <Grid item xs={12} md={2}>
                            <strong style={{ fontSize: 18 }}>
                              {new Intl.DateTimeFormat('en-GB', {
                                month: 'short',
                                day: 'numeric',
                              }).format(new Date(order.CreatedOn))}
                            </strong>
                            <h3
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                                color:
                                  order.StatusCode == 'ORDERPAID'
                                    ? 'red'
                                    : 'green',
                              }}
                            >
                              {order.StatusCode == 'ORDERPAID'
                                ? order.Status
                                : order.Status}
                            </h3>
                          </Grid>

                          <Grid item xs={12} md={5}>
                            <strong>Order no: </strong>
                            {order.OrderNo}
                            <br />
                            <br />
                            <Button
                              variant="outlined"
                              disableElevation
                              color="primary"
                              component={Link2}
                              to={`/order/detail/${order.OrderNo}`}
                            >
                              Order details
                            </Button>
                          </Grid>

                          <Hidden only={['sm', 'xs']}>
                            <Grid item xs={12} md={5}>
                              <strong>Items: </strong>
                              {order.TotalItem}
                              <br />
                              <strong>Total Amount: </strong>
                              RM{parseFloat(order.TotalAmount).toFixed(2)}
                              <br />
                              <strong>Delivery Charges: </strong>
                              RM{parseFloat(order.DeliveryCharges).toFixed(2)}
                              <br />
                            </Grid>
                          </Hidden>
                        </Grid>
                      </Paper>
                    )
                )}

                <br />

                <div style={{ fontSize: 17, fontWeight: 'bold' }}>
                  Previous Orders
                </div>

                <Divider classes={{ root: classes.dividerroot }} />
                {myorders.map(
                  (order) =>
                    order.Status == 'Completed' && (
                      <Paper
                        classes={{ root: classes.paperroot }}
                        variant="outlined"
                      >
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-start"
                        >
                          <Grid item xs={12} md={2}>
                            <strong style={{ fontSize: 18 }}>
                              {new Intl.DateTimeFormat('en-GB', {
                                month: 'short',
                                day: 'numeric',
                              }).format(new Date(order.CreatedOn))}
                            </strong>
                            <h3
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                                color: 'green',
                              }}
                            >
                              Completed
                            </h3>
                          </Grid>

                          <Grid item xs={12} md={5}>
                            <strong>Order no: </strong>
                            {order.OrderNo}
                            <br />
                            <br />
                            <Button
                              variant="outlined"
                              disableElevation
                              color="primary"
                              component={Link2}
                              to={`/order/detail/${order.OrderNo}`}
                            >
                              Order details
                            </Button>
                          </Grid>

                          <Hidden only={['sm', 'xs']}>
                            <Grid item xs={12} md={5}>
                              <strong>Items: </strong>
                              {order.TotalItem}
                              <br />
                              <strong>Total Amount: </strong>
                              RM{parseFloat(order.TotalAmount).toFixed(2)}
                              <br />
                              <strong>Delivery Charges: </strong>
                              RM{parseFloat(order.DeliveryCharges).toFixed(2)}
                              <br />
                            </Grid>
                          </Hidden>
                        </Grid>
                      </Paper>
                    )
                )}
              </div>

              <Hidden only={['sm', 'xs']}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexShrink: 0,
                    width: 240,
                    borderLeft: '1px solid #ccc',
                    borderRight: '1px solid #ccc',
                    transform: 'translateZ(0)',
                    position: '-webkit-sticky',
                    position: 'sticky',
                    height: '100vh',
                    margin: 0,
                    padding: 0,
                    top: 0,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#ffffff',
                      borderBottom: '1px solid #cccccc',
                      padding: 10,
                    }}
                  >
                    <Button
                      variant="contained"
                      disableElevation
                      fullWidth
                      size="medium"
                      color="primary"
                      component={Link2}
                      to="/checkout"
                      onClick={() => {
                        scrollToTop()
                      }}
                    >
                      Checkout
                    </Button>
                  </div>
                  <List className={classes.listRoot}>
                    <Cart data={carts} />
                  </List>
                </div>
              </Hidden>
            </div>
          </div>
        </div>
       */}
      </Container>
    </React.Fragment>
  )
}
