import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Grid from '@material-ui/core/Grid'
import DialogContent from '@material-ui/core/DialogContent'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import MobileCart from './mobiecart'
import { GET_CARTS } from '../graphql/cart'
import Cookies from 'js-cookie'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import ShopDetailsMobile from './shopDetailsMobile'
import { GET_SHOPCONFIG } from '../graphql/config'

import { useMutation, useQuery } from '@apollo/react-hooks'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  button: {
    color: (props) => `${props.COLORPRIMARY}`,
    [theme.breakpoints.up('md')]: {
      color: 'white',
    },
  },
  toolbarroot: {
    minHeight: 48,
    paddingRight: 0,
    paddingLeft: 10,
  },
  dialogContentRoot: {
    backgroundColor: '#ebebeb',
    padding: 0,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})

export default function FullScreenDialog(props) {
  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const classes = useStyles(shopconfig)

  const handleClose = () => {
    props.setOpenProduct(false)
  }

  return (
    <div>
      <Dialog
        fullScreen
        scroll="paper"
        open={props.openProduct}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar classes={{ root: classes.toolbarroot }}>
            <Grid
              container
              spacing={2}
              direction="row"
              alignItems="center"
            ></Grid>

            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent classes={{ root: classes.dialogContentRoot }}>
          <ShopDetailsMobile
            setSelectedProductID={props.setSelectedProductID}
            selectedProductID={props.selectedProductID}
            setProduct={props.setProduct}
            data={props.product}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}
