import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button, List } from '@material-ui/core/'

import Divider from '@material-ui/core/Divider'
import { Link as Link2 } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import { GET_CARTS } from '../graphql/cart'
import { GET_SHOPCONFIG } from '../graphql/config'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useEffect } from 'react'
import TrolleyItem from './trolleyItem'
import Cookies from 'js-cookie'

const restApi = process.env.REACT_APP_API

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  listRoot: {
    width: '100%',
    padding: 0,
  },
  media: {
    height: 400,
  },
  card: {
    borderRadius: 0,
  },
  paperroot: {
    marginBottom: 8,
    padding: 5,
    border: (props) => `1px solid ${props.COLORPRIMARY}`,
  },
  summaryRoot: {
    padding: theme.spacing(2),
    border: '1px solid #b4b4b4',
  },
  dividerRoot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

export default function Trolley(props) {
  const [totalAmount, setTotalAmount] = useState(0)

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const classes = useStyles(shopconfig)

  const { loading: loadingCarts, data: { carts } = { carts: [] } } = useQuery(
    GET_CARTS,
    { skip: !Cookies.get('signedin') }
  )

  useEffect(() => {
    if (carts.length == 0) return

    const totalAmount = carts.reduce((a, b) => a + b.InvoiceAmount, 0)
    setTotalAmount(totalAmount)
  }, [carts])

  return (
    <div style={{ padding: 8 }}>
      <Grid container spacing={0} style={{ margin: 0 }}>
        <Grid style={{ width: '100%' }} item sm={12} md={12}>
          <div
            style={{
              borderBottom: '1px solid #cccccc',
              paddingBottom: 10,
              paddingTop: 10,
            }}
          >
            <Grid container>
              <Grid
                item
                xs={6}
                style={{
                  fontSize: 17,
                }}
              >
                Total in Cart
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  textAlign: 'right',
                  fontWeight: 'bold',
                  fontSize: 17,
                }}
              >
                RM {parseFloat(totalAmount).toFixed(2)}{' '}
              </Grid>
            </Grid>
            <Divider classes={{ root: classes.dividerroot }} />
            <Button
              variant="contained"
              disableElevation
              fullWidth
              size="large"
              color="primary"
              component={Link2}
              to="/checkout"
              onClick={() => {
                props.setOpen(false)
              }}
            >
              Checkout
            </Button>
          </div>

          <List className={classes.listRoot}>
            {carts.map((cart, i) => (
              <React.Fragment key={i}>
                <Paper classes={{ root: classes.paperroot }} variant="outlined">
                  <TrolleyItem
                    setOpenProduct={props.setOpenProduct}
                    setProduct={props.setProduct}
                    data={cart}
                  />
                </Paper>
              </React.Fragment>
            ))}
          </List>
        </Grid>
      </Grid>
    </div>
  )
}
