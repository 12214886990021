import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import RestoreIcon from '@material-ui/icons/Restore'
import FavoriteIcon from '@material-ui/icons/Favorite'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
})

export default function SimpleBottomNavigation() {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const history = useHistory()
  const url = ['/', '/order', '/mobilecart']

  const handleChange = (event, newValue) => {
    //setValue(newValue)

    history.push(url[newValue])
  }

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels={false}
      className={classes.root}
    >
      <BottomNavigationAction label="Home" icon={<RestoreIcon />} />
      <BottomNavigationAction label="Orders" icon={<FavoriteIcon />} />
      <BottomNavigationAction label="Cart" icon={<LocationOnIcon />} />
    </BottomNavigation>
  )
}
