import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'
import Button from '@material-ui/core/Button'

import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import IconButton from '@material-ui/core/IconButton'

const restApi = process.env.REACT_APP_API

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  rootDiv: {
    padding: 20,
    [theme.breakpoints.up('sm')]: {
      padding: 80,
    },
  },
  listRoot: {
    width: '100%',
    maxWidth: '36ch',
    padding: 0,
    overflowY: 'scroll',
    flexGrow: 1,
    backgroundColor: '#ebebeb',
  },
  media: {
    height: 150,
    margin: theme.spacing(1),
  },
  card: {},
  buttonRoot: {
    minWidth: 0,
    marginRight: 5,
    padding: 7,
  },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  containerroot: {
    paddingTop: 20,
    paddingBottom: 50,
    paddingLeft: 0,
    paddingRight: 0,
  },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  rootBootstrapHelpertext: {
    color: 'white',
    marginTop: theme.spacing(2),
  },
  inputBootstrap: {
    borderRadius: 6,
    color: 'white',
    position: 'relative',
    backgroundColor: 'transparent',
    border: '3px solid #ced4da',
    width: '100%',
    padding: '13px 10px 10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderColor: (props) => `${props.COLORPRIMARY}`,
    },
  },
}))

export default function ServicesWaterBenefits(props) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div
        style={{
          background:
            'url(/images/service/waterbenefit/1bg.jpg) no-repeat center center',
          backgroundSize: 'cover',
          minHeight: 700,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Container classes={{ root: classes.containerroot }} maxWidth="md">
          <Grid style={{ marginTop: 0 }} container spacing={0}>
            <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
              <img
                src="/images/service/waterbenefit/1water.png"
                style={{ width: '90%' }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'center',
              }}
            >
              <div>
                <h1 style={{ fontWeight: 'normal', marginBottom: 0 }}>
                  936 LAE
                </h1>
                <h1
                  style={{
                    fontWeight: 'normal',
                    paddingTop: 0,
                    marginTop: 0,
                    marginBottom: 50,
                  }}
                >
                  Active Water Treatment
                </h1>

                <h2 style={{ fontWeight: 'normal', marginBottom: 20 }}>
                  Treatment Benefits
                </h2>

                <Grid
                  style={{ marginTop: 40, marginBottom: 40 }}
                  container
                  spacing={0}
                >
                  <Grid item xs={6} md={4}>
                    <img
                      src="/images/icon/laewater/1.png"
                      style={{ height: 70 }}
                    />
                    <br />
                    Enhances Body Metabolism & Blood Circulation
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <img
                      src="/images/icon/laewater/2.png"
                      style={{ height: 70 }}
                    />
                    <br />
                    Experience Bliss & Reduce Stress
                  </Grid>
                  <Hidden only={['sm', 'xs']}>
                    <Grid item xs={12} md={4}>
                      <img
                        src="/images/icon/laewater/3.png"
                        style={{ height: 70 }}
                      />
                      <br />
                      Slow Down & Reverse Aging
                    </Grid>
                  </Hidden>
                </Grid>
                <Hidden only={['md', 'lg', 'xl']}>
                  <Grid
                    style={{ marginTop: 40, marginBottom: 40 }}
                    container
                    spacing={0}
                  >
                    <Grid item xs={12} md={4}>
                      <img
                        src="/images/icon/laewater/3.png"
                        style={{ height: 70 }}
                      />
                      <br />
                      Slow Down & Reverse Aging
                    </Grid>
                  </Grid>
                </Hidden>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          background:
            'url(/images/service/waterbenefit/2bg.jpg) no-repeat center  center',
          backgroundSize: 'cover',
          color: 'white',
        }}
      >
        <Container style={{ paddingLeft: 0, paddingRight: 0 }} maxWidth="md">
          <Grid
            container
            spacing={0}
            style={{
              minHeight: 500,
              padding: 0,
            }}
          >
            <Grid item xs={12} md={5}>
              <Hidden only={['md', 'lg', 'xl']}>
                <img
                  src="/images/service/waterbenefit/2human.png"
                  style={{ height: 500, display: 'block' }}
                />
              </Hidden>
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              style={{
                background: 'rgba(0, 0, 0, 0.3)',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <div className={classes.rootDiv}>
                  <img
                    src="/images/icon/laeroom/BoostMetabolism.png"
                    style={{
                      height: 60,
                      verticalAlign: 'bottom',
                      lineHeight: 1,
                      paddingRight: 10,
                      marginTop: 30,
                      float: 'left',
                    }}
                  />
                  <h1 style={{ fontWeight: 'normal' }}>
                    <span>Enhances Body Metabolism & Blood Circulation</span>
                  </h1>
                  <p>
                    LAE Active Water uses resonance to instantaneously penetrate
                    through the deepest layer of the skin and break off the
                    hydrogen bonds of the blocked cell fluid, big water
                    molecular cluster are now broken down into small water
                    molecular cluster with a larger surface area.
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          background:
            'url(/images/service/waterbenefit/3bg.jpg) no-repeat bottom  right',
          backgroundSize: 'cover',
          color: 'white',
        }}
      >
        <Container style={{ paddingLeft: 0, paddingRight: 0 }} maxWidth="md">
          <Grid
            container
            spacing={0}
            style={{
              minHeight: 500,
            }}
          >
            <Grid
              item
              xs={12}
              md={7}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div className={classes.rootDiv}>
                <img
                  src="/images/icon/laewater/2.png"
                  style={{
                    height: 60,
                    verticalAlign: 'bottom',
                    lineHeight: 1,
                    paddingRight: 10,
                    marginTop: 30,
                    float: 'left',
                  }}
                />
                <h1 style={{ fontWeight: 'normal' }}>
                  <span>
                    Experience Bliss &<br />
                    Reduced Stress
                  </span>
                </h1>
                <p>
                  On top of receiving treatment from psychologists and
                  psychiatrists, individuals can also use LAE Active Water to
                  relax the mind, reduce nervous tension, and alleviate negative
                  emotions like stress, depression, irritability and anxiety.
                  When the mental condition is stabilized, the chances of
                  psychological distress can be decreased, which is beneficial
                  towards the long-term treatment process.
                </p>
                <p>
                  Side effects of taking psychiatric medication can also be
                  managed using LAE Active Water Diseases take time to manifest
                  — they do not happen overnight. Likewise, in terms of
                  treatment, the key lies in patience and perseverance to
                  achieve your desired outcome.
                </p>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              style={{
                background:
                  'url(/images/service/waterbenefit/3pic.jpg) no-repeat center  center',
                backgroundSize: 'cover',
                minHeight: 500,
              }}
            ></Grid>
          </Grid>
        </Container>
      </div>
      <div
        style={{
          background:
            'url(/images/service/waterbenefit/4bg.png) no-repeat center  center',
          backgroundSize: 'cover',
          color: 'white',
        }}
      >
        <Container style={{ paddingLeft: 0, paddingRight: 0 }} maxWidth="md">
          <Grid
            container
            spacing={0}
            style={{
              minHeight: 500,
            }}
          >
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12} md={6} style={{}}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <div className={classes.rootDiv}>
                  <img
                    src="/images/icon/laewater/3.png"
                    style={{
                      height: 60,
                      verticalAlign: 'bottom',
                      lineHeight: 1,
                      paddingRight: 10,
                      marginTop: 30,
                      float: 'left',
                    }}
                  />
                  <h1 style={{ fontWeight: 'normal' }}>
                    <span>
                      Slow Down &<br />
                      Reverse Aging
                    </span>
                  </h1>
                  <p>
                    Detoxifies and lowers lipid levels, making skin softer,
                    smoother, more elastic and more radiant.
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  )
}
