import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'

import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import IconButton from '@material-ui/core/IconButton'

const restApi = process.env.REACT_APP_API

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  listRoot: {
    width: '100%',
    maxWidth: '36ch',
    padding: 0,
    overflowY: 'scroll',
    flexGrow: 1,
    backgroundColor: '#ebebeb',
  },
  media: {
    height: 150,
    margin: theme.spacing(1),
  },
  card: {},
  buttonRoot: {
    minWidth: 0,
    marginRight: 5,
    padding: 7,
  },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  containerroot: {
    paddingTop: 20,
    paddingBottom: 50,
    paddingLeft: 0,
    paddingRight: 0,
  },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  rootBootstrapHelpertext: {
    color: 'white',
    marginTop: theme.spacing(2),
  },
  inputBootstrap: {
    borderRadius: 6,
    color: 'white',
    position: 'relative',
    backgroundColor: 'transparent',
    border: '3px solid #ced4da',
    width: '100%',
    padding: '13px 10px 10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderColor: (props) => `${props.COLORPRIMARY}`,
    },
  },
}))

export default function Promotion(props) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div style={{ textAlign: 'center' }}>
        <h1 style={{ marginBottom: 0, marginTop: 100 }}>Coming Soon</h1>
      </div>
      {/* <img src="/images/promotion/1.jpg" style={{ width: '100%' }} /> */}
      {/* <div style={{ paddingTop: 50, paddingBottom: 50 }}>
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div>
                <img src="/images/promotion/1.jpg" style={{ width: '100%' }} />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div> */}
    </React.Fragment>
  )
}
