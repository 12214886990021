import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import { Link as Link2 } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/react-hooks'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Hidden from '@material-ui/core/Hidden'
import Container from '@material-ui/core/Container'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Link from '@material-ui/core/Link'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import SingleTitle from '../common/singleTitle'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import Fade from '@material-ui/core/Fade'

import ReportList from './reportList'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  root: {
    padding: 20,
  },
  chiproot: {
    display: 'flex',
    justifyContent: 'left',
    paddingLeft: 20,
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  menuItem: {
    paddingLeft: 0,
    paddingRight: 0,
    textDecoration: 'none',
    fontWeight: 'normal',
    color: 'rgba(0, 0, 0, 0.87)',
  },
}))

export default function DesktopTopMenu(props) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            paddingTop: 0,
          }}
        >
          <Link2 to="/">
            <img
              style={{ height: 70, verticalAlign: 'middle' }}
              src={`/images/logo.png`}
            />
          </Link2>
        </div>
        <div
          style={{
            marginLeft: 'auto',
            paddingTop: 8,
          }}
        >
          <List
            style={{
              display: 'flex',
              flexDirection: 'row',
              whiteSpace: 'nowrap',
              padding: 0,
            }}
          >
            <ListItem style={{ paddingRight: 0, paddingLeft: 20 }}>
              <Link2
                className={classes.menuItem}
                to="/service/38/936-lae-energy-room-treatment"
              >
                <img
                  style={{ height: 48, verticalAlign: 'middle' }}
                  src={`/images/LAElogo.png`}
                />{' '}
                ENERGY TREATMENT
              </Link2>
            </ListItem>
            <ListItem style={{ paddingRight: 0, paddingLeft: 20 }}>
              <Link2 className={classes.menuItem} to="/booking">
                <i
                  className="fal fa-calendar-check"
                  style={{ fontSize: 18, marginRight: 5 }}
                ></i>
                ENERGY ROOM BOOKING
              </Link2>
            </ListItem>
            <ListItem style={{ paddingRight: 0, paddingLeft: 20 }}>
              <Link2 className={classes.menuItem} to="/myhealth">
                <i
                  className="fal fa-heartbeat"
                  style={{ fontSize: 18, marginRight: 5 }}
                ></i>
                MY HEALTH
              </Link2>
            </ListItem>
          </List>
        </div>
      </div>
    </React.Fragment>
  )
}
