import { gql } from 'apollo-boost'

export const GET_BOOKINGS = gql`
  {
    bookings {
      id
      BranchID
      BranchName
      BranchCode
      BookFrom
      BookTo
      TotalHours
      TotalPerson
      Status
      CreatedBy
      CreatedAt
      UpdatedBy
      UpdatedAt
      ReceivedBy
      ReceivedOn
      Client
      Token
      Rating
      BookingNo
    }
  }
`
export const GET_BOOKINGORDERS = gql`
  {
    bookingorders {
      id
      title
      value
      MinBooking
    }
  }
`

export const GET_BOOKING = gql`
  query Booking($id: ID) {
    booking(id: $id) {
      id
      BranchID
      BranchName
      BranchCode
      BookFrom
      BookTo
      TotalHours
      TotalPerson
      Status
      CreatedBy
      CreatedAt
      UpdatedBy
      UpdatedAt
      ReceivedBy
      ReceivedOn
      Client
      Token
      Rating
      BookingNo
    }
  }
`

export const INSERT_BOOKING = gql`
  mutation InsertBooking(
    $BranchCode: String
    $BookDate: Date
    $BookTime: String
    $TotalHours: Float
    $TotalPerson: Int
    $PackagePriceID: ID
  ) {
    insertbooking(
      BranchCode: $BranchCode
      BookDate: $BookDate
      BookTime: $BookTime
      TotalHours: $TotalHours
      TotalPerson: $TotalPerson
      PackagePriceID: $PackagePriceID
    ) {
      id
      Error
    }
  }
`

export const UPDATE_RATING = gql`
  mutation UpdateRating($id: ID, $Rating: Int) {
    updaterating(id: $id, Rating: $Rating) {
      id
      BranchID
      BranchName
      BranchCode
      BookFrom
      BookTo
      TotalHours
      TotalPerson
      Status
      CreatedBy
      CreatedAt
      UpdatedBy
      UpdatedAt
      ReceivedBy
      ReceivedOn
      Client
      Token
      Rating
      BookingNo
    }
  }
`

export const REMOVE_BOOKING = gql`
  mutation RemoveBooking($id: ID) {
    removebooking(id: $id)
  }
`

export const BOOKING_SCHEDULE = gql`
  query BookingSchedules($BookingDate: Date, $BranchCode: String) {
    bookingschedules(BookingDate: $BookingDate, BranchCode: $BranchCode) {
      id
      title
      value
    }
  }
`
