import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'

import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import IconButton from '@material-ui/core/IconButton'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { SUBSCRIBEMAIL } from '../graphql/staff'

import { useSnackbar } from 'notistack'
const restApi = process.env.REACT_APP_API

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  listRoot: {
    width: '100%',
    maxWidth: '36ch',
    padding: 0,
    overflowY: 'scroll',
    flexGrow: 1,
    backgroundColor: '#ebebeb',
  },
  media: {
    height: 150,
    margin: theme.spacing(1),
  },
  card: {},
  buttonRoot: {
    minWidth: 0,
    marginRight: 5,
    padding: 7,
  },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  containerroot: {
    paddingTop: 20,
    paddingBottom: 50,
    paddingLeft: 0,
    paddingRight: 0,
  },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  rootBootstrapHelpertext: {
    color: 'white',
    marginTop: theme.spacing(2),
  },
  inputBootstrap: {
    borderRadius: 6,
    color: 'white',
    position: 'relative',
    backgroundColor: 'transparent',
    border: '3px solid #ced4da',
    width: '100%',
    padding: '13px 10px 10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderColor: (props) => `${props.COLORPRIMARY}`,
    },
  },
}))

export default function Contact(props) {
  const classes = useStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [subEmail, setSubEmail] = useState()
  const addCache = (cache, { data }) => {
    if (data.subscribemail?.Error) {
      enqueueSnackbar(data.subscribemail?.Error, {
        variant: 'error',
      })
    } else {
      enqueueSnackbar('Added to Subscription', {
        variant: 'success',
      })
    }

    setSubEmail('')
  }

  const [subscribeMail] = useMutation(SUBSCRIBEMAIL, {
    update: addCache,
  })

  return (
    <React.Fragment>
      <div>
        <img src="/images/about/bg/20.jpg" style={{ width: '100%' }} />
      </div>
      <div
        style={{
          background: 'url(/images/bg/brandstory.jpg) no-repeat bottom  right',
          backgroundSize: 'cover',
          minHeight: 150,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <h1 style={{ marginBottom: 0, marginTop: 0 }}>Contact Us</h1>
          <div>
            Operating Hours
            <br />
            Mon - Sun: 10am to 10pm
          </div>
        </div>
      </div>

      <div style={{ paddingTop: 50, paddingBottom: 50 }}>
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <h2>936 All Branches</h2>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div>
                <h3 style={{ marginTop: 40 }}>Mid Valley </h3>
                <b>Wellness Managment Sdn Bhd （1384424-T)</b>
                <br />
                Lot T 067, 067A, 068 & 069, <br />
                3rd Floor, Mid Valley Megamall,
                <br />
                Lingkaran Syed Putra, <br />
                59000 Kuala Lumpur,
                <br />
                <br />
                <b>Email:</b> marketing@siamoasis.com.my
                <br />
                <b>Tel:</b> 012-9828211
                <h3 style={{ marginTop: 40 }}>Empire Shopping Gallery</h3>
                <b>Wellness Managment Sdn Bhd （1384424-T)</b>
                <br />
                Lot F 23 & 25, 1st Floor, <br />
                Empire Shopping Gallery, <br />
                Jalan SS16/1, <br />
                47500 Subang Jaya
                <br />
                <br />
                <b>Email:</b> marketing@siamoasis.com.my
                <br />
                <b>Tel:</b> 012-9828211
                <h3 style={{ marginTop: 40 }}>Bangsar Village </h3>
                <b>Wellness Managment Sdn Bhd （1384424-T)</b>
                <br />
                Lot 3F-17 & 18, 3rd Floor, Bangsar Village II, <br />
                Jalan Telawi 1, <br />
                Bangsar Baru,
                <br />
                59100 Kuala Lumpur
                <br />
                <br />
                <b>Email:</b> marketing@siamoasis.com.my
                <br />
                <b>Tel:</b> 012-9828211
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.404079137888!2d103.70783101475428!3d1.5523178988679793!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da73a862ddb017%3A0x18ca51ee3a3b623e!2sEZON%20Berhad!5e0!3m2!1sen!2smy!4v1602465202710!5m2!1sen!2smy"
                width="100%"
                height="350"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe> */}
              <h3 style={{ marginTop: 35 }}>1 Utama Shopping Centre</h3>
              <b>Wellness Managment Sdn Bhd （1384424-T)</b>
              <br />
              Lot S 330A, 2nd floor
              <br />
              1 Utama Shopping Centre (New Wing)
              <br />
              1 Lebuh Bandar Utama,
              <br />
              Bandar Utama,
              <br />
              47800 Petaling Jaya
              <br />
              <br />
              <b>Email:</b> marketing@siamoasis.com.my
              <br />
              <b>Tel:</b> 012-9828211
              <h3 style={{ marginTop: 40 }}>Setia Alam</h3>
              <b>Wellness Managment Sdn Bhd （1384424-T)</b>
              <br />
              Lot FT 02 & FT 02A, Ground Floor
              <br />
              Jalan Setia Nusantara U13/17,
              <br />
              Setia Alam,
              <br />
              40170 Shah Alam, Selangor
              <br />
              <br />
              <b>Email:</b> marketing@siamoasis.com.my
              <br />
              <b>Tel:</b> 012-9828211
              <br />
              {/* <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.404079137888!2d103.70783101475428!3d1.5523178988679793!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da73a862ddb017%3A0x18ca51ee3a3b623e!2sEZON%20Berhad!5e0!3m2!1sen!2smy!4v1602465202710!5m2!1sen!2smy"
                width="100%"
                height="400"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe> */}
              <h3 style={{ marginTop: 40 }}>HQ - Ezon Berhad （1305909-A)</h3>
              18，Jalan Kempas Lama 1
              <br />
              Off Jalan Kempas Lama
              <br />
              81300 Johor Bahru
              <br />
              Johor
              <br />
              <br />
              <b>Email:</b> ezonberhadacc@gmail.com
              <br />
              <b>Tel:</b> 07-550 6341
              <br />
            </Grid>
          </Grid>
        </Container>
      </div>
      <div
        style={{
          background: 'url(/images/bg/8.jpg) no-repeat center center',
          backgroundSize: 'cover',
          minHeight: 700,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Container classes={{ root: classes.containerroot }} maxWidth="md">
          <Grid style={{ marginTop: 0 }} container spacing={10}>
            <Grid item xs={12} style={{ textAlign: 'center' }} md={6}>
              <Hidden only={['sm', 'xs']}>
                <img
                  src="/images/icon/wellnessscientific.png"
                  style={{ maxHeight: 400 }}
                />
              </Hidden>

              <Hidden only={['md', 'lg', 'xl']}>
                <img
                  src="/images/icon/wellnessscientific.png"
                  style={{ maxHeight: 300 }}
                />
              </Hidden>

              <Grid style={{ marginTop: 0 }} container spacing={0}>
                <Grid item xs={12} md={4}>
                  <h3 style={{ marginTop: 0, color: 'white' }}>
                    Detoxification
                  </h3>
                </Grid>
                <Grid item xs={12} md={4}>
                  <h3 style={{ marginTop: 0, color: 'white' }}>
                    Deep Relaxation
                  </h3>
                </Grid>
                <Grid item xs={12} md={4}>
                  <h3 style={{ marginTop: 0, color: 'white' }}>Healthy Life</h3>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
              <div style={{ padding: 20 }}>
                <img
                  src="/images/icon/logowhite.png"
                  style={{ maxHeight: 100 }}
                />
                <h3
                  style={{
                    marginTop: 50,
                    letterSpacing: 10,
                    textTransform: 'uppercase',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  Subscribe us today
                </h3>
                <h1
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  Stay Up to Date
                </h1>

                <br />
                <br />
                <FormControl fullWidth>
                  <InputLabel shrink htmlFor="SubscribeEmail"></InputLabel>
                  <InputBase
                    classes={{
                      root: classes.rootBootstrap,
                      input: classes.inputBootstrap,
                    }}
                    id="SubscribeEmail"
                    name="SubscribeEmail"
                    label="SubscribeEmail"
                    fullWidth
                    value={subEmail}
                    margin="dense"
                    onChange={(e) => {
                      setSubEmail(e.target.value)
                    }}
                    autoComplete="off"
                    inputProps={{
                      maxLength: 200,
                      type: 'email',
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        {' '}
                        <IconButton
                          style={{
                            position: 'absolute',
                            right: 0,
                            marginRight: 10,
                          }}
                          aria-label="toggle password visibility"
                          onClick={() => {
                            const re =
                              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            if (re.test(subEmail)) {
                              subscribeMail({
                                variables: {
                                  Email: subEmail,
                                },
                              })
                            } else {
                              enqueueSnackbar(
                                'Please provide a valid email address',
                                {
                                  variant: 'error',
                                }
                              )
                            }
                          }}
                          /* onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword} */
                          edge="end"
                        >
                          <ArrowForwardIcon style={{ color: 'white' }} />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText
                    classes={{
                      root: classes.rootBootstrapHelpertext,
                    }}
                  >
                    <span>Get the latest news directly to your inbox</span>
                  </FormHelperText>
                </FormControl>
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  )
}
