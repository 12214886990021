import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Redirect,
  Route,
  BrowserRouter,
  Switch,
  withRouter,
  Link,
  Link as Link2,
  NavLink,
} from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'

import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import IconButton from '@material-ui/core/IconButton'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { SUBSCRIBEMAIL } from '../graphql/staff'

import { useSnackbar } from 'notistack'
const restApi = process.env.REACT_APP_API

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  listRoot: {
    width: '100%',
    maxWidth: '36ch',
    padding: 0,
    overflowY: 'scroll',
    flexGrow: 1,
    backgroundColor: '#ebebeb',
  },
  media: {
    height: 150,
    margin: theme.spacing(1),
  },
  card: {},
  buttonRoot: {
    minWidth: 0,
    marginRight: 5,
    padding: 7,
  },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  containerroot: {
    paddingTop: 20,
    paddingBottom: 50,
    paddingLeft: 0,
    paddingRight: 0,
  },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  rootBootstrapHelpertext: {
    color: 'white',
    marginTop: theme.spacing(2),
  },
  inputBootstrap: {
    borderRadius: 6,
    color: 'white',
    position: 'relative',
    backgroundColor: 'transparent',
    border: '3px solid #ced4da',
    width: '100%',
    padding: '13px 10px 10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderColor: (props) => `${props.COLORPRIMARY}`,
    },
  },
  productImages: {
    height: '100%',
    maxHeight: 180,

    [theme.breakpoints.up('md')]: {
      height: '100%',
      maxHeight: 320,
    },
  },
}))

export default function ServicesEnergyRoom(props) {
  const classes = useStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [subEmail, setSubEmail] = useState()
  const addCache = (cache, { data }) => {
    if (data.subscribemail?.Error) {
      enqueueSnackbar(data.subscribemail?.Error, {
        variant: 'error',
      })
    } else {
      enqueueSnackbar('Added to Subscription', {
        variant: 'success',
      })
    }

    setSubEmail('')
  }

  const [subscribeMail] = useMutation(SUBSCRIBEMAIL, {
    update: addCache,
  })

  return (
    <React.Fragment>
      <div
        style={{
          background: 'url(/images/bg/4.jpg) no-repeat center center',
          backgroundSize: 'cover',
          display: 'flex',
          alignItems: 'center',
          margin: 0,
        }}
      >
        <Container
          style={{
            marginTop: 50,
            paddingTop: 0,
            paddingBottom: 0,
            marginBottom: 0,
          }}
          maxWidth="md"
        >
          <Grid style={{ marginTop: 0 }} container spacing={0}>
            <Grid
              item
              xs={12}
              md={12}
              style={{ color: 'white', textAlign: 'center' }}
            >
              <h1
                style={{ fontWeight: 'normal', marginBottom: 0, marginTop: 0 }}
              >
                Services
              </h1>
            </Grid>
          </Grid>
          <Grid
            style={{ marginTop: 5, paddingBottom: 0 }}
            container
            spacing={4}
          >
            <Grid
              item
              xs={6}
              md={6}
              style={{
                color: 'white',
                textAlign: 'center',
              }}
            >
              <div>
                <Link2 to="/936-lae-energy-room-treatment">
                  <img
                    src="/images/icon/LAEroom.png"
                    className={classes.productImages}
                  />
                </Link2>
                <br />

                <Hidden only={['md', 'lg', 'xl']}>
                  <div>
                    <h3
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 20,
                        marginTop: 20,
                      }}
                    >
                      936 LAE Energy Room Treatment
                    </h3>
                    <Button
                      component={Link2}
                      to="/booking"
                      variant="contained"
                      size="large"
                      color="primary"
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                      Book Now
                    </Button>
                  </div>
                </Hidden>

                <Hidden only={['sm', 'xs']}>
                  <div>
                    <h2
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 20,
                        marginTop: 20,
                      }}
                    >
                      936 LAE Energy Room Treatment
                    </h2>
                    <p style={{ marginBottom: 20 }}>
                      The convenience of moderm living has greatly improved our
                      lives, but these creature comfort might not necessarily be
                      all that great to our health.
                    </p>
                    {/* <Button
                      component={Link2}
                      to="/booking"
                      variant="contained"
                      size="large"
                      color="primary"
                    >
                      Booking Now
                    </Button> */}
                  </div>
                </Hidden>
              </div>
            </Grid>

            <Grid
              item
              xs={6}
              md={6}
              style={{
                color: 'white',
                textAlign: 'center',
              }}
            >
              <div>
                <Link2 to="/936-lae-active-water-treatment">
                  <img
                    src="/images/icon/LAEwater.png"
                    className={classes.productImages}
                  />
                </Link2>
                <br />

                <Hidden only={['md', 'lg', 'xl']}>
                  <div>
                    <h3
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 20,
                        marginTop: 20,
                      }}
                    >
                      936 LAE Active Water Treatment
                    </h3>
                    <Button
                      component={Link2}
                      to="/booking"
                      variant="contained"
                      size="large"
                      color="primary"
                    >
                      Book Now
                    </Button>
                  </div>
                </Hidden>

                <Hidden only={['sm', 'xs']}>
                  <div>
                    <h2
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 20,
                        marginTop: 20,
                      }}
                    >
                      936 LAE Active Water Treatment
                    </h2>
                    <p style={{ marginBottom: 20 }}>
                      "LAE ACTIVE WATER" can stimulate blood circulation, bring
                      sufficient oxygen to the blood, improve the skin's
                      metabolic capacity, moisturize skin and eliminate fatigue;
                      Prevent and improves scalp pruritus, dandruff and
                      hair-fall problems.
                    </p>
                    {/* <Button
                      component={Link2}
                      to="/booking"
                      variant="contained"
                      size="large"
                      color="primary"
                    >
                      Booking Now
                    </Button> */}
                  </div>
                </Hidden>
              </div>
            </Grid>

            {/* <Grid
              item
              xs={6}
              md={6}
              style={{
                color: 'white',
                textAlign: 'center',
              }}
            >
              <div>
                <img
                  src="/images/icon/quantummagnetic.png"
                  className={classes.productImages}
                />
                <br />

                <Hidden only={['md', 'lg', 'xl']}>
                  <div>
                    <h3
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 20,
                        marginTop: 20,
                      }}
                    >
                      Quantum Magnetic
                      <br />
                      Resonance Analyser
                    </h3>
                    <Button
                      component={Link2}
                      to="/booking"
                      variant="contained"
                      size="large"
                      color="primary"
                    >
                      Book Now
                    </Button>
                  </div>
                </Hidden>

                <Hidden only={['sm', 'xs']}>
                  <div>
                    <h2
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 20,
                        marginTop: 20,
                      }}
                    >
                      Quantum Magnetic
                      <br />
                      Resonance Analyser
                    </h2>
                    <p style={{ marginBottom: 20 }}>
                      We help clients deliver more effective and efficient 40
                      types general health examination with quantum magnetic
                      resonance health analyzer.
                    </p>
                    <Button
                      component={Link2}
                      to="/booking"
                      variant="contained"
                      size="large"
                      color="primary"
                    >
                      Booking Now
                    </Button>
                  </div>
                </Hidden>
              </div>
            </Grid> */}
          </Grid>

          <Grid style={{ marginTop: 20 }} container spacing={0}>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Button
                component={Link2}
                to="/booking"
                variant="contained"
                size="large"
                color="primary"
              >
                Booking Now
              </Button>
            </Grid>
          </Grid>

          <Grid style={{ marginTop: 40 }} container spacing={0}>
            <Grid
              item
              xs={6}
              md={6}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  width: 0,
                  height: 0,
                  borderLeft: '20px solid transparent',
                  borderRight: '20px solid transparent',
                  borderBottom: '30px solid white',
                }}
              ></div>
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            ></Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          background:
            'url(/images/service/bg/smallbar.jpg) no-repeat center center',
          backgroundSize: 'cover',
          height: 200,
          display: 'flex',
          alignItems: 'center',
          color: 'white',
        }}
      >
        <h1
          style={{
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
          }}
        >
          What is 936 LAE Energy Room Treatment?
        </h1>
      </div>

      <div
        style={{
          background: 'url(/images/service/bg/2bg.jpg) no-repeat center center',
          backgroundSize: 'cover',
          color: 'white',
          position: 'relative',
        }}
      >
        <img
          src="/images/service/bg/2bg.jpg"
          style={{ visibility: 'hidden', width: '100%', height: '100%' }}
        />
        <Hidden only={['md', 'lg', 'xl']}>
          <h3
            style={{
              position: 'absolute',
              top: 0,
              fontWeight: 'bold',
              width: '100%',
              textAlign: 'center',
            }}
          >
            LAE Deep Penetration.
            <br />
            Detoxification
          </h3>
        </Hidden>

        <Hidden only={['sm', 'xs']}>
          <h2
            style={{
              position: 'absolute',
              top: 30,
              fontWeight: 'bold',
              width: '100%',
              textAlign: 'center',
            }}
          >
            LAE Deep Penetration.
            <br />
            Detoxification
          </h2>
        </Hidden>
      </div>

      <div
        style={{
          background: 'url(/images/service/bg/3bg.jpg) ',
          backgroundSize: 'cover',
          color: 'white',
          paddingTop: 50,
          marginTop: 0,
          paddingBottom: 50,
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={5} style={{}}>
            <Grid item xs={12} md={1}></Grid>
            <Grid item xs={12} md={5} style={{ position: 'relative' }}>
              <img
                src="/images/service/bg/3.png"
                style={{
                  width: '100%',
                  alignSelf: 'flex-end',
                  marginTop: 'auto',
                  height: '100%',
                }}
              />
              <Hidden only={['md', 'lg', 'xl']}>
                <div
                  style={{
                    position: 'absolute',
                    top: 100,
                    padding: 20,
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'block',
                    }}
                  >
                    <h3
                      style={{
                        textAlign: 'center',
                        color: 'black',
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      Driving the future of Health
                    </h3>
                  </div>
                </div>
              </Hidden>

              <Hidden only={['sm', 'xs']}>
                <div
                  style={{
                    position: 'absolute',
                    top: 100,
                    padding: 20,
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'block',
                    }}
                  >
                    <h2
                      style={{
                        textAlign: 'center',
                        color: 'black',
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      Driving the future of Health
                    </h2>
                  </div>
                </div>
              </Hidden>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div>
                <h1>
                  Urban Individuals,
                  <br />
                  Are You Sweating Enough?{' '}
                </h1>
                <p>
                  The convenience of modern living has greatly improved our
                  lives, but these creature comforts might not necessarily be
                  all that great for our health. From our air-conditioned homes,
                  we commute to our air-conditioned offices in similarly
                  air-conditioned cars, buses and trains, without any change for
                  us to break out in a sweat!
                </p>
                <p>
                  Yes, "comfort" and "convenience" are important aspects of our
                  daily lives, but because of this, we have become less healthy.
                </p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          background: 'url(/images/service/bg/6bg.jpg) no-repeat center center',
          backgroundSize: 'cover',
          color: 'white',
          position: 'relative',
        }}
      >
        <Container maxWidth="md">
          <img
            src="/images/service/bg/6.png"
            style={{ width: '100%', display: 'block' }}
          />
        </Container>

        <Hidden only={['md', 'lg', 'xl']}>
          <h3
            style={{
              position: 'absolute',
              top: 0,
              fontWeight: 'bold',
              width: '100%',
              textAlign: 'center',
            }}
          >
            936 LAE Energy Room Addresses
            <br />
            Liver Problems At The Root Cause
          </h3>
        </Hidden>

        <Hidden only={['sm', 'xs']}>
          <h2
            style={{
              position: 'absolute',
              top: 30,
              fontWeight: 'bold',
              width: '100%',
              textAlign: 'center',
            }}
          >
            936 LAE Energy Room Addresses
            <br />
            Liver Problems At The Root Cause
          </h2>
        </Hidden>
      </div>

      <div
        style={{
          background: 'url(/images/service/bg/7bg.jpg) no-repeat center center',
          backgroundSize: 'cover',
          color: 'white',
          position: 'relative',
        }}
      >
        <Container maxWidth="md">
          <img
            src="/images/service/bg/7.png"
            style={{ width: '100%', display: 'block' }}
          />
          <Grid style={{ marginTop: 0 }} container spacing={10}>
            <Grid item xs={12} md={1}></Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <p>
                When the body's temperature increases, it burns fat as fuel.
                Therefore, if you wish to lose weight naturally and safely, you
                need to reduce your body fat. If your goal is to put on weight,
                your digestive system will work effectively if its energy
                balance is restored. No matter for weight loss or weight gain,
                936 LAE Energy Room helps to improve the body's condition so
                that it can perform to the best of its potential. As energy
                therapy is a type of holistic treatment, it should be emphasized
                that result will become more apparent with time, patience and
                diligence.
              </p>
            </Grid>

            <Grid item xs={12} md={4}>
              <img src="/images/service/bg/7room.png" />
            </Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          background: 'url(/images/service/bg/8bg.jpg) no-repeat center center',
          backgroundSize: 'cover',
          color: 'white',
          position: 'relative',
          paddingBottom: 50,
        }}
      >
        <Container
          maxWidth="md"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <img src="/images/service/bg/8.png" style={{ display: 'block' }} />

          <div>
            <Hidden only={['md', 'lg', 'xl']}>
              <div
                style={{
                  position: 'absolute',
                  bottom: 30,
                  left: 0,
                  textAlign: 'center',
                }}
              >
                <h3
                  style={{
                    fontWeight: 'bold',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  936 LAE Energy Strengthens Your Body's Resistance Towards
                  Viruses
                </h3>
                <p style={{ paddingLeft: 10, paddingRight: 10 }}>
                  From the explanation above, we know that intestine and stomach
                  related deseases are mostly caused by bacterial or viral
                  infections. The best way to prevent this is to pay attention
                  to the hygiene level of what you consume, and also to improve
                  your body's resistance towards viruses. However, this can only
                  be achieved with regular exercise and constant care of your
                  nutritional intake. For working individuals, this can pose as
                  a challenge due to irregular dietary habits and busy
                  lifestyles.
                </p>
                <p style={{ paddingLeft: 10, paddingRight: 10 }}>
                  936 LAE Energy Room helps you address this issue - try it for
                  yourself to feel how effective it is!
                </p>
              </div>
            </Hidden>

            <Hidden only={['sm', 'xs']}>
              <div
                style={{
                  position: 'absolute',
                  bottom: 30,
                  left: 0,
                  textAlign: 'center',
                }}
              >
                <h2
                  style={{
                    fontWeight: 'bold',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  936 LAE Energy Strengthens Your Body's Resistance Towards
                  Viruses
                </h2>
                <p style={{ paddingLeft: 100, paddingRight: 100 }}>
                  From the explanation above, we know that intestine and stomach
                  related diseases are mostly caused by bacterial or viral
                  infections. The best way to prevent this is to pay attention
                  to the hygiene level of what you consume, and also to improve
                  your body's resistance towards viruses. However, this can only
                  be achieved with regular exercise and constant care of your
                  nutritional intake. For working individuals, this can pose as
                  a challenge due to irregular dietary habits and busy
                  lifestyles.
                </p>
                <p style={{ paddingLeft: 100, paddingRight: 100 }}>
                  936 LAE Energy Room helps you address this issue - try it for
                  yourself to feel how effective it is!
                </p>
              </div>
            </Hidden>
          </div>
        </Container>
      </div>

      <div
        style={{
          background: 'url(/images/service/bg/9bg.jpg) no-repeat bottom  right',
          backgroundSize: 'cover',
          color: 'white',
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <img src="/images/service/bg/9.jpg" />
              <div>
                <p style={{ paddingTop: 10, textAlign: 'center' }}>
                  Treat yourself, your loved one, friend or family member to the
                  ultimate pampering experience in the heart of 936 wellness
                  scientific
                </p>
              </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ display: 'flex' }}>
              <img
                src="/images/service/bg/9.png"
                style={{
                  width: '100%',
                  alignSelf: 'flex-end',
                  marginTop: 'auto',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          background: 'url(/images/bg/2.jpg) no-repeat center center',
          backgroundSize: 'cover',
          minHeight: 700,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Container classes={{ root: classes.containerroot }} maxWidth="md">
          <Grid style={{ marginTop: 50 }} container spacing={0}>
            <Grid item xs={12} md={6}></Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ color: 'white', textAlign: 'center' }}
            >
              <h1
                style={{
                  fontWeight: 'normal',
                  marginBottom: 0,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                936 LAE
                <br />
                Energy Room Treatment
              </h1>

              <h2 style={{ fontWeight: 'normal', marginBottom: 20 }}>
                Treatment Benefits
              </h2>
              <Grid style={{ marginTop: 40 }} container spacing={0}>
                <Grid item xs={6} md={3}>
                  <img
                    src="/images/icon/laeroom/BoostMetabolism.png"
                    style={{ height: 70 }}
                  />
                  <br />
                  Boost Metabolism
                </Grid>
                <Grid item xs={6} md={3}>
                  <img
                    src="/images/icon/laeroom/Detoxification.png"
                    style={{ height: 70 }}
                  />
                  <br />
                  Detoxification
                </Grid>
                <Grid item xs={6} md={3}>
                  <img
                    src="/images/icon/laeroom/Refreshing.png"
                    style={{ height: 70 }}
                  />
                  <br />
                  Refreshing
                </Grid>
                <Grid item xs={6} md={3}>
                  <img
                    src="/images/icon/laeroom/LoseFat.png"
                    style={{ height: 70 }}
                  />
                  <br />
                  Lose Fat
                </Grid>
              </Grid>
              <Grid
                style={{ marginTop: 40, marginBottom: 40 }}
                container
                spacing={0}
              >
                <Grid item xs={6} md={4}>
                  <img
                    src="/images/icon/laeroom/BoostImmuneSystem.png"
                    style={{ height: 70 }}
                  />
                  <br />
                  Boost Immune System
                </Grid>
                <Grid item xs={6} md={4}>
                  <img
                    src="/images/icon/laeroom/ReverseAging.png"
                    style={{ height: 70 }}
                  />
                  <br />
                  Reverse Aging
                </Grid>{' '}
                <Hidden only={['sm', 'xs']}>
                  <Grid item xs={12} md={4}>
                    <img
                      src="/images/icon/laeroom/ImproveHeartHealth.png"
                      style={{ height: 70 }}
                    />
                    <br />
                    Improve Heart Health
                  </Grid>
                </Hidden>
              </Grid>
              <Hidden only={['md', 'lg', 'xl']}>
                <Grid
                  style={{ marginTop: 40, marginBottom: 40 }}
                  container
                  spacing={0}
                >
                  <Grid item xs={12} md={4}>
                    <img
                      src="/images/icon/laeroom/ImproveHeartHealth.png"
                      style={{ height: 70 }}
                    />
                    <br />
                    Improve Heart Health
                  </Grid>
                </Grid>
              </Hidden>
              <Button
                component={Link2}
                style={{ marginBottom: 50 }}
                variant="contained"
                size="large"
                color="primary"
                to="/936-lae-energy-room-treatment-benefits"
              >
                Learn More
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          background: 'url(/images/bg/8.jpg) no-repeat center center',
          backgroundSize: 'cover',
          minHeight: 700,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Container classes={{ root: classes.containerroot }} maxWidth="md">
          <Grid style={{ marginTop: 0 }} container spacing={10}>
            <Grid item xs={12} style={{ textAlign: 'center' }} md={6}>
              <Hidden only={['sm', 'xs']}>
                <img
                  src="/images/icon/wellnessscientific.png"
                  style={{ maxHeight: 400 }}
                />
              </Hidden>

              <Hidden only={['md', 'lg', 'xl']}>
                <img
                  src="/images/icon/wellnessscientific.png"
                  style={{ maxHeight: 300 }}
                />
              </Hidden>

              <Grid style={{ marginTop: 0 }} container spacing={0}>
                <Grid item xs={12} md={4}>
                  <h3 style={{ marginTop: 0, color: 'white' }}>
                    Detoxification
                  </h3>
                </Grid>
                <Grid item xs={12} md={4}>
                  <h3 style={{ marginTop: 0, color: 'white' }}>
                    Deep Relaxation
                  </h3>
                </Grid>
                <Grid item xs={12} md={4}>
                  <h3 style={{ marginTop: 0, color: 'white' }}>Healthy Life</h3>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
              <div style={{ padding: 20 }}>
                <img
                  src="/images/icon/logowhite.png"
                  style={{ maxHeight: 100 }}
                />
                <h3
                  style={{
                    marginTop: 50,
                    letterSpacing: 10,
                    textTransform: 'uppercase',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  Subscribe us today
                </h3>
                <h1
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  Stay Up to Date
                </h1>

                <br />
                <br />
                <FormControl fullWidth>
                  <InputLabel shrink htmlFor="SubscribeEmail"></InputLabel>
                  <InputBase
                    classes={{
                      root: classes.rootBootstrap,
                      input: classes.inputBootstrap,
                    }}
                    id="SubscribeEmail"
                    name="SubscribeEmail"
                    label="SubscribeEmail"
                    fullWidth
                    value={subEmail}
                    margin="dense"
                    onChange={(e) => {
                      setSubEmail(e.target.value)
                    }}
                    autoComplete="off"
                    inputProps={{
                      maxLength: 200,
                      type: 'email',
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        {' '}
                        <IconButton
                          style={{
                            position: 'absolute',
                            right: 0,
                            marginRight: 10,
                          }}
                          aria-label="toggle password visibility"
                          onClick={() => {
                            const re =
                              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            if (re.test(subEmail)) {
                              subscribeMail({
                                variables: {
                                  Email: subEmail,
                                },
                              })
                            } else {
                              enqueueSnackbar(
                                'Please provide a valid email address',
                                {
                                  variant: 'error',
                                }
                              )
                            }
                          }}
                          /* onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword} */
                          edge="end"
                        >
                          <ArrowForwardIcon style={{ color: 'white' }} />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText
                    classes={{
                      root: classes.rootBootstrapHelpertext,
                    }}
                  >
                    <span>Get the latest news directly to your inbox</span>
                  </FormHelperText>
                </FormControl>
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  )
}
