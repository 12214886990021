import { gql } from 'apollo-boost'

export const GET_TOWNS = gql`
  {
    towns {
      Town
      State
    }
  }
`

export const GET_POSTCODEPRICE = gql`
  {
    postcodeprice {
      id
      State
      Town
      PostCode
      Price
    }
  }
`

export const REMOVE_POSTCODE = gql`
  mutation RemovePostcode($id: ID!) {
    removepostcode(id: $id)
  }
`

export const GET_POSTCODES = gql`
  {
    postcodes {
      id
      Town
      State
      PostCode
      Price
    }
  }
`

export const GET_POSTCODE = gql`
  query Postcode($id: ID!) {
    postcode(id: $id) {
      id
      Town
      State
      PostCode
      Price
    }
  }
`

export const INSERT_POSTCODE = gql`
  mutation InsertPostcode(
    $Town: String
    $State: String
    $PostCode: String
    $Price: Float
  ) {
    insertpostcode(
      Town: $Town
      State: $State
      PostCode: $PostCode
      Price: $Price
    ) {
      id
      Town
      State
      PostCode
      Price
    }
  }
`

export const UPDATE_POSTCODE = gql`
  mutation UpdatePostcode(
    $id: ID!
    $Town: String
    $State: String
    $PostCode: String
    $Price: Float
  ) {
    updatepostcode(
      id: $id
      Town: $Town
      State: $State
      PostCode: $PostCode
      Price: $Price
    ) {
      id
      Town
      State
      PostCode
      Price
    }
  }
`
