import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button, Divider } from '@material-ui/core/'

import Hidden from '@material-ui/core/Hidden'
import Paper from '@material-ui/core/Paper'
import { GET_CARTS } from '../graphql/cart'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useEffect } from 'react'
import { Link as Link2, useHistory } from 'react-router-dom'
import MuiAlert from '@material-ui/lab/Alert'
import { GET_SHOPCONFIG } from '../graphql/config'

import axios from 'axios'
import { DropzoneArea } from 'material-ui-dropzone'
import { useSnackbar } from 'notistack'

const restApi = process.env.REACT_APP_API

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  paperroot: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    border: (props) => `1px solid ${props.COLORPRIMARY}`,
    borderBottom: (props) => `5px solid ${props.COLORPRIMARY}`,
  },
  summaryRoot: {
    padding: theme.spacing(3),
    border: '1px solid #b4b4b4',
  },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
  },
  dropZone: {
    background: theme.palette.primary.paper,
  },
}))

export default function OrderConfirm(props) {
  const history = useHistory()
  const [totalAmount, setTotalAmount] = useState(0)
  const [finalAmount, setFinalAmount] = useState(0)

  const [dbhost, setDbhost] = useState('spa1')

  /*const [dbhost, setDbhost] = useState('shop')*/

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const classes = useStyles(shopconfig)

  const [file, setFile] = useState()
  const [disable, setDisable] = useState(true)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    if (file) setDisable(false)
  }, [file])

  const handleUpload = (e) => {
    e.preventDefault()

    if (!file) return
    const formData = new FormData()
    //formData.append('uploads[]', file)
    //console.log(file)
    formData.append('uploads[]', file.file[0], file.file[0]['name'])
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    //formData.append("uploads[]", files[0], files[0]['name']);
    axios
      .put(restApi + `/upload/order/${props.orderNo}`, formData, config)
      .then((response) => {
        enqueueSnackbar('The file is successfully uploaded', {
          variant: 'success',
        })

        setFile()

        history.push('/order')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onChange = (e) => {
    console.log(e)
    setFile({ file: e })
  }

  const {
    loading: loadingCarts,
    data: { carts } = { carts: [] },
    refetch,
  } = useQuery(GET_CARTS)

  useEffect(() => {
    refetch()
  }, [props])

  useEffect(() => {
    if (carts.length == 0) return

    const totalAmount = carts.reduce((a, b) => a + b.InvoiceAmount, 0)
    setTotalAmount(totalAmount)
  }, [carts])

  useEffect(() => {
    setFinalAmount(totalAmount)
  }, [totalAmount])

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Hidden only={['sm', 'xs']}>
          <Grid item xs={12} md={2}></Grid>
        </Hidden>
        <Grid item xs={12} md={8}>
          <MuiAlert elevation={0} variant="filled" severity="success">
            Created Order <b>{props.orderNo}</b>
          </MuiAlert>
          <br />

          {/* {props.payment == 'touchngo' && (
              <React.Fragment>
                <div>
                  <div style={{ paddingBottom: 10 }}>
                    As you have chosen to pay with Touch N Go eWallet, please
                    finish the transaction in 2 days to secure your order.
                  </div>
                  <ol>
                    <li>
                      Transfer{' '}
                      <b>{`RM ${parseFloat(finalAmount).toFixed(2)}`}</b> to the
                      Touch N Go eWallet QRCode account below.
                    </li>
                    <li>Upload a snapshot of payment receipt below.</li>
                  </ol>

                  <Divider classes={{ root: classes.dividerroot }} />
                  <img
                    style={{ width: '100%', maxWidth: 300 }}
                    src={`${restApi}/media/${dbhost}/uploads/logo/${shopconfig.TOUCHNGO}`}
                  />
                </div>
              </React.Fragment>
            )}*/}

          {props.payment == 'banktransfer' && (
            <React.Fragment>
              <div>
                <div style={{ paddingBottom: 10 }}>
                  As you have chosen to pay with Bank Transfer, please finish
                  the transaction in 2 days to secure your order.
                </div>

                <ol>
                  <li>
                    Transfer <b>{`RM ${parseFloat(finalAmount).toFixed(2)}`}</b>{' '}
                    to the bank account listed below.
                  </li>
                  <li>Upload a snapshot of payment receipt below.</li>
                </ol>

                <Divider classes={{ root: classes.dividerroot }} />
                <h3>
                  Bank Transfer
                  <br />
                  (Instant Transfer only)
                </h3>
                <div style={{ paddingBottom: 10 }}>
                  Bank
                  <br />
                  <strong>{shopconfig.BTBANKNAME}</strong>
                </div>
                <div style={{ paddingBottom: 10 }}>
                  Account Number
                  <br />
                  <strong>{shopconfig.BTACCNO}</strong>
                </div>
                <div style={{ paddingBottom: 10 }}>
                  Receiver Name
                  <br />
                  <strong>{shopconfig.BTACCNAME}</strong>
                </div>
              </div>
            </React.Fragment>
          )}

          <form onSubmit={handleUpload}>
            <Divider classes={{ root: classes.dividerroot }} />
            <DropzoneArea
              dropzoneClass={classes.dropZone}
              filesLimit={1}
              maxFileSize={300000000}
              acceptedFiles={['image/*', 'application/pdf']}
              showPreviewsInDropzone={false}
              showPreviews={true}
              dropzoneText="Upload Receipt"
              onChange={onChange}
            />
            <Button
              variant="contained"
              disableElevation
              color="primary"
              type="submit"
              style={{ marginTop: 10, marginBottom: 10 }}
              disabled={disable}
            >
              Confirm Upload
            </Button>
          </form>
          <p>
            *Order receipt will be verified by us and confirmation message will
            be sent to you via Email.
          </p>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            component={Link2}
            to="/order"
            onClick={() => {
              scrollToTop()
            }}
          >
            My Orders
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
