import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button, Box, Typography } from '@material-ui/core/'

import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'
import { Link as Link2, useHistory } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import { GET_BOOKING, UPDATE_RATING } from '../graphql/booking'
import { GET_SHOPCONFIG } from '../graphql/config'
import { GET_CATEGORIES } from '../graphql/category'
import { useMutation, useQuery } from '@apollo/react-hooks'
import QRCode from 'qrcode.react'
import TrolleyItem from './trolleyItem'
import Cookies from 'js-cookie'
import BookingDetails from './bookingDetails'
import Moment from 'moment-timezone'

import Menu from './menu'
import MenuCategory from './menuCategory'
import Cart from './cart'
import DesktopTopMenu from '../pages/desktoptopmenu'

import axios from 'axios'
import { DropzoneArea } from 'material-ui-dropzone'
import { useSnackbar } from 'notistack'
import TextFieldDisplay from '../common/textFieldDisplay'

import { Rating } from '@material-ui/lab'

import {
  GET_CARTS,
  INSERT_CART,
  UPDATE_CART,
  REMOVE_CART,
} from '../graphql/cart'

const restApi = process.env.REACT_APP_API

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  listRoot: {
    width: '100%',
    padding: 0,
  },
  media: {
    height: 400,
  },
  card: {
    borderRadius: 0,
  },
  paperroot: {
    marginBottom: 8,
    padding: theme.spacing(2),
    border: '1px solid #ccc',
  },
  summaryRoot: {
    padding: theme.spacing(2),
    border: '1px solid #b4b4b4',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  containerroot: {},
  dropZone: {
    background: theme.palette.primary.paper,
  },
}))

export default function OrderDetails(props) {
  const classes = useStyles()
  const history = useHistory()

  const [dbhost, setDbhost] = useState('spa1')
  const [rating, setRating] = useState(0)

  /*const [dbhost, setDbhost] = useState('shop')*/

  const BookingNo = props.match.params.BookingNo

  const {
    loading: loadingOrder,
    data: { booking } = { booking: {} },
    refetch,
  } = useQuery(GET_BOOKING, { variables: { id: BookingNo }, skip: !BookingNo })

  const updateCache = (cache, { data }) => {
    refetch({ id: BookingNo })
  }

  const [updateRating] = useMutation(UPDATE_RATING, {
    update: updateCache,
  })

  useEffect(() => {
    if (!booking.Rating) return
    setRating(booking.Rating)
  }, [booking])

  return (
    <React.Fragment>
      <Hidden only={['sm', 'xs']}>
        {/* <Container classes={{ root: classes.containerroot }} maxWidth="md">
          <DesktopTopMenu />
        </Container> */}
        <br />
      </Hidden>
      <Container classes={{ root: classes.containerroot }} maxWidth="md">
        <h2 style={{ marginTop: 10, marginBottom: 5 }}>
          Booking No #{booking.BookingNo}
        </h2>
        <Grid style={{ marginTop: 0 }} container spacing={10}>
          <Grid item sm={12} md={4}>
            {!booking.ReceivedBy && (
              <div style={{ marginBottom: 20 }}>
                <i
                  class="fas fa-map-marker-alt"
                  style={{ color: '#00acee' }}
                ></i>{' '}
                Present this QR code at our {booking.BranchName} Branch
                {/*  by{' '}
              {Moment(booking.BookFrom).tz('UTC').format('L LT')} */}
              </div>
            )}
            <Button
              size="small"
              variant="outlined"
              disableElevation
              color="primary"
              onClick={() => {
                history.push(booking.ReceivedBy ? '/booking/1' : '/booking/0')
              }}
            >
              Back
            </Button>
            <br />
            <br />
            {!booking.ReceivedBy && (
              <div
                style={{
                  backgroundColor: 'white',
                  padding: 80,
                }}
              >
                <QRCode
                  style={{
                    backgroundColor: 'white',
                    width: '100%',
                  }}
                  value={`https://${booking.Client}.936lae.com/bookingdetails/${booking.Token}`}
                  bgColor={'#ffffff'}
                  size={300}
                  fgColor={'#000000'}
                  level={'L'}
                  includeMargin={false}
                  renderAs={'svg'}
                  imageSettings={{
                    src: '/images/logo2.png',
                    x: null,
                    y: null,
                    height: 38,
                    width: 38,
                    excavate: true,
                  }}
                />
              </div>
            )}

            {booking.ReceivedBy && (
              <div>
                {!booking.Rating && (
                  <Typography component="legend">
                    Please rate our Customer Service during your visit.
                  </Typography>
                )}
                {booking.Rating && (
                  <Typography component="legend">
                    You have rated our Customer Service during this visit. Thank
                    You.
                  </Typography>
                )}

                <br />
                <Rating
                  readOnly={booking.Rating}
                  name="simple-controlled"
                  value={rating}
                  onChange={(event, newValue) => {
                    setRating(newValue)
                  }}
                />
                <br />
                <br />
                {!booking.Rating && (
                  <Button
                    size="small"
                    variant="outlined"
                    disableElevation
                    color="primary"
                    onClick={() => {
                      updateRating({
                        variables: {
                          id: BookingNo,
                          Rating: rating,
                        },
                      })
                    }}
                  >
                    Submit
                  </Button>
                )}
              </div>
            )}
          </Grid>
          <Grid item sm={12} md={8}>
            <Grid container spacing={3}>
              <Grid style={{ paddingTop: 0 }} item md={6} xs={12}>
                <TextFieldDisplay label="Branch" value={booking.BranchName} />
                <TextFieldDisplay
                  label="Booking Date & Time"
                  value={Moment(booking.BookFrom).tz('UTC').format('L - LT')}
                />
                <TextFieldDisplay
                  label="Duration"
                  value={`${booking.TotalHours} h`}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <div className={classes.toolbar}>
          <div className={classes.root}>
            <div style={{ display: 'flex' }}>
              <div style={{ flexGrow: 1, padding: 10 }}>
                <Grid container spacing={0} style={{ margin: 0 }}>
                  <Grid style={{ width: '100%' }} item sm={12} md={12}>
                    <div
                      style={{
                        paddingBottom: 5,
                        paddingTop: 5,
                      }}
                    >
                      <Button
                        variant="outlined"
                        disableElevation
                        color="primary"
                        onClick={() => {
                          history.goBack(-1)
                        }}
                      >
                        Back
                      </Button>
                    </div>
                    <h2 style={{ marginTop: 10, marginBottom: 5 }}>
                      Order {myorder.OrderNo}
                    </h2>

                    <Paper
                      classes={{ root: classes.paperroot }}
                      variant="outlined"
                    >
                      {myorder.StatusCode != 'ORDERCREATED' && (
                        <h3 style={{ marginTop: 0, color: 'green' }}>
                          {myorder.Status}
                        </h3>
                      )}
                      {myorder.StatusCode == 'ORDERCREATED' && (
                        <React.Fragment>
                          {myorder.PaymentMethod == 'touchngo' && (
                            <React.Fragment>
                              <div>
                                <div style={{ paddingBottom: 10 }}>
                                  As you have chosen to pay with Touch N Go
                                  eWallet, please finish the transaction in 2
                                  days to secure your order.
                                </div>
                                <ol>
                                  <li>
                                    Transfer{' '}
                                    <b>{`RM ${parseFloat(finalAmount).toFixed(
                                      2
                                    )}`}</b>{' '}
                                    to the Touch N Go eWallet QRCode account
                                    below.
                                  </li>
                                  <li>
                                    Upload a snapshot of payment receipt below.
                                  </li>
                                </ol>

                                <Divider
                                  classes={{ root: classes.dividerroot }}
                                />
                                <img
                                  style={{ width: '100%', maxWidth: 300 }}
                                  src={`${restApi}/media/${dbhost}/uploads/logo/${shopconfig.TOUCHNGO}`}
                                />
                              </div>
                            </React.Fragment>
                          )}

                          {myorder.PaymentMethod == 'banktransfer' && (
                            <React.Fragment>
                              <div>
                                <div style={{ paddingBottom: 10 }}>
                                  As you have chosen to pay with Bank Transfer,
                                  please finish the transaction in 2 days to
                                  secure your order.
                                </div>

                                <ol>
                                  <li>
                                    Transfer{' '}
                                    <b>{`RM ${parseFloat(finalAmount).toFixed(
                                      2
                                    )}`}</b>{' '}
                                    to the bank account listed below.
                                  </li>
                                  <li>
                                    Upload a snapshot of payment receipt below.
                                  </li>
                                </ol>

                                <Divider
                                  classes={{ root: classes.dividerroot }}
                                />
                                <h3>
                                  Bank Transfer
                                  <br />
                                  (INSTANT TRANSFER ONLY)
                                </h3>
                                <div style={{ paddingBottom: 10 }}>
                                  Bank
                                  <br />
                                  <strong>{shopconfig.BTBANKNAME}</strong>
                                </div>
                                <div style={{ paddingBottom: 10 }}>
                                  Account Number
                                  <br />
                                  <strong>{shopconfig.BTACCNO}</strong>
                                </div>
                                <div style={{ paddingBottom: 10 }}>
                                  Receiver Name
                                  <br />
                                  <strong>{shopconfig.BTACCNAME}</strong>
                                </div>
                              </div>
                            </React.Fragment>
                          )}

                          <Divider classes={{ root: classes.dividerroot }} />

                          <form onSubmit={handleUpload}>
                            <DropzoneArea
                              dropzoneClass={classes.dropZone}
                              filesLimit={1}
                              maxFileSize={300000000}
                              acceptedFiles={['image/*', 'application/pdf']}
                              showPreviewsInDropzone={false}
                              showPreviews={true}
                              dropzoneText="Upload Receipt"
                              onChange={onChange}
                            />
                            <Button
                              variant="contained"
                              disableElevation
                              color="primary"
                              type="submit"
                              style={{ marginTop: 10, marginBottom: 10 }}
                              disabled={disable}
                            >
                              Confirm Upload
                            </Button>
                          </form>
                          <Divider classes={{ root: classes.dividerroot }} />
                        </React.Fragment>
                      )}

                      <BookingDetails
                        data={myorder}
                        finalAmount={finalAmount}
                        totalAmount={totalAmount}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </div>

              <Hidden only={['sm', 'xs']}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexShrink: 0,
                    width: 240,
                    borderLeft: '1px solid #ccc',
                    borderRight: '1px solid #ccc',
                    transform: 'translateZ(0)',
                    position: '-webkit-sticky',
                    position: 'sticky',
                    height: '100vh',
                    margin: 0,
                    padding: 0,
                    top: 0,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#ffffff',
                      borderBottom: '1px solid #cccccc',
                      padding: 10,
                    }}
                  >
                    <Button
                      variant="contained"
                      disableElevation
                      fullWidth
                      size="medium"
                      color="primary"
                      component={Link2}
                      to="/checkout"
                      onClick={() => {
                        scrollToTop()
                      }}
                    >
                      Checkout
                    </Button>
                  </div>
                  <List className={classes.listRoot}>
                    <Cart data={carts} />
                  </List>
                </div>
              </Hidden>
            </div>
          </div>
        </div>
      */}{' '}
      </Container>
    </React.Fragment>
  )
}
