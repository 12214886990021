import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button } from '@material-ui/core/'
import InputLabel from '@material-ui/core/InputLabel'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'

import Hidden from '@material-ui/core/Hidden'
import Paper from '@material-ui/core/Paper'
import { GET_BRANCH, GET_GROUPBRANCHES } from '../graphql/branch'
import { GET_BUYER } from '../graphql/buyer'
import { GET_CARTS } from '../graphql/cart'
import { CART_PAYMENT } from '../graphql/order'
import { GET_SHOPCONFIG } from '../graphql/config'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useEffect } from 'react'
import Cookies from 'js-cookie'

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import SHA256 from '../common/sha256'

const restApi = process.env.REACT_APP_API

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  paperroot: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    border: (props) => `1px solid ${props.COLORPRIMARY}`,
    borderBottom: (props) => `5px solid ${props.COLORPRIMARY}`,
  },
  summaryRoot: {
    padding: theme.spacing(3),
    border: '1px solid #b4b4b4',
  },
  dividerRoot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
  rootContainer: { marginBottom: theme.spacing(2) },
  rootInputlabel: { fontWeight: 'bold' },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
    },
  },
}))

export default function PaymentOption(props) {
  const [totalAmount, setTotalAmount] = useState(0)
  const [finalAmount, setFinalAmount] = useState(0)
  const [openLoading, setOpenLoading] = useState(false)
  const [hash, setHash] = useState()
  const [orderNo, setOrderNo] = useState()
  const [branchCode, setBranchCode] = useState()

  const {
    data: { groupbranches } = { groupbranches: [] },
    refetch: refetchBranches,
  } = useQuery(GET_GROUPBRANCHES)

  const { loading: loadingBuyer, data: { buyer } = { buyer: { id: null } } } =
    useQuery(GET_BUYER, { skip: !Cookies.get('signedin') })

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const classes = useStyles(shopconfig)

  const {
    loading: loadingCarts,
    data: { carts } = { carts: [] },
    refetch,
  } = useQuery(GET_CARTS)

  const updateCache = (cache, { data }) => {
    //props.setOrderNo(data.cartorder)
    //console.log('data', data)
    setHash(data.cartpayment.Hash)
    setOrderNo(data.cartpayment.OrderNo)
  }

  const [cartPayment] = useMutation(CART_PAYMENT, { update: updateCache })

  useEffect(() => {
    if (carts.length == 0) return

    const totalAmount = carts.reduce((a, b) => a + b.InvoiceAmount, 0)
    setTotalAmount(totalAmount)
  }, [carts])

  useEffect(() => {
    setFinalAmount(totalAmount)
  }, [totalAmount])

  const hashString = () => {
    var merchantKey = '8hV8nqScYq'
    var merchantCode = 'M16120'
    var currency = 'MYR'

    var string = merchantKey + merchantCode + orderNo + totalAmount + currency
    if (string != '') {
      setHash(SHA256(string))
      console.log(hash)
    }
  }

  /*  useEffect(() => {
    cartPayment()
  }, [props]) */

  return (
    <React.Fragment>
      <Grid style={{ marginTop: 30 }} container spacing={1}>
        <Hidden only={['sm', 'xs']}>
          <Grid item xs={12} md={2}></Grid>
        </Hidden>
        <Grid item xs={12} md={8}>
          <h3 style={{ marginTop: 0, fontSize: 17 }}>
            Order Total: RM {parseFloat(finalAmount).toFixed(2)}
          </h3>
          <br />

          <Grid container>
            <Grid item md={7} xs={12}>
              <FormControl fullWidth>
                <InputLabel
                  classes={{ root: classes.rootInputlabel }}
                  shrink
                  htmlFor="Branch"
                >
                  Select the Branch for order collection
                </InputLabel>
                <Select
                  labelId="Branch"
                  id="Branch"
                  name="Branch"
                  value={branchCode}
                  onChange={(e) => {
                    setBranchCode(e.target.value)
                    cartPayment({ variables: { BranchCode: e.target.value } })
                  }}
                  input={
                    <InputBase
                      classes={{
                        root: classes.rootBootstrap,
                        input: classes.inputBootstrap,
                      }}
                    />
                  }
                >
                  {groupbranches.map((a, i) => (
                    <MenuItem key={i} value={a.BranchCode}>
                      {a.BranchName}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText></FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          {/* <FormControl component="fieldset">
            <FormLabel component="legend">
              Please select the payment method below :<br />
              <br />
            </FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={props.payment}
              onChange={(e) => {
                props.setPayment(e.target.value)
              }}
            >
              <FormControlLabel
                disabled
                value="cc"
                control={<Radio />}
                label="Credit Card - Offline"
              />
              <FormControlLabel
                disabled
                value="fpx"
                control={<Radio />}
                label="FPX Fund Transfer - Offline"
              />
              {/* <FormControlLabel
                value="banktransfer"
                control={<Radio />}
                label="Bank Transfer (Instant Transfer only)"
              /> 
            </RadioGroup>
          </FormControl> */}
          <br />
          <br />

          <form
            method="post"
            name="ePayment"
            action="https://payment.ipay88.com.my/ePayment/entry.asp"
          >
            <input type="hidden" name="MerchantCode" value="M16120" />
            <input type="hidden" name="PaymentId" value="" />
            <input type="hidden" name="RefNo" value={orderNo} />

            {/* <input
              type="hidden"
              name="Amount"
              value={parseFloat(finalAmount).toFixed(2)}
            /> */}

            <input
              type="hidden"
              name="Amount"
              value={parseFloat(1).toFixed(2)}
            />
            <input type="hidden" name="Currency" value="MYR" />
            <input type="hidden" name="ProdDesc" value="936LAE PURCHASES" />
            <input
              type="hidden"
              name="UserName"
              value={`${buyer.FirstName} ${buyer.LastName}`}
            />
            <input type="hidden" name="UserEmail" value={buyer.Email} />
            <input type="hidden" name="UserContact" value={buyer.Username} />
            <input type="hidden" name="Remark" value="" />
            <input type="hidden" name="Lang" value="UTF-8" />
            <input type="hidden" name="SignatureType" value="SHA256" />
            <input type="hidden" name="Signature" value={hash} />
            <input
              type="hidden"
              name="ResponseURL"
              value="https://new.936lae.com/payment/success/"
            />
            <input
              type="hidden"
              name="BackendURL"
              value="https://new.936lae.com/checkout/callback/"
            />
            <Button
              style={{ marginTop: 0 }}
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => {
                props.handleBack()
              }}
            >
              Back
            </Button>
            {hash && buyer && buyer.id && (
              <Button
                type="submit"
                style={{ marginLeft: 10, marginTop: 0 }}
                variant="contained"
                disableElevations
                color="primary"
                className={classes.button}
              >
                Proceed with Payment
              </Button>
            )}
          </form>
          <Backdrop
            className={classes.backdrop}
            open={openLoading}
            onClick={() => {
              setOpenLoading(true)
            }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          {/* <Button
            style={{ marginLeft: 10, marginTop: 0 }}
            variant="contained"
            disableElevations
            color="primary"
            onClick={() => {
              cartOrder({
                variables: {
                  PaymentMethod: props.payment,
                },
              })
              props.handleNext()
            }}
            className={classes.button}
          >
            Confirm payment
          </Button> */}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
