import { gql } from 'apollo-boost'

export const GET_CARTS = gql`
  {
    carts {
      id
      OrderID
      UserID
      ShipperID
      ProductID
      ProductNo
      OrderDate
      RequiredDate
      Freight
      SalesTax
      TransactStatus
      InvoiceAmount
      PaymentDate
      Qty
      ProductName
      UnitPrice
      ProductImage
      PriceID
      UnitPrice
      ProductPrices
      ProductDesc
    }
  }
`
export const INSERT_CART = gql`
  mutation InsertCart($ProductID: ID, $Qty: Int, $PriceID: ID) {
    insertcart(ProductID: $ProductID, Qty: $Qty, PriceID: $PriceID) {
      id
      OrderID
      UserID
      ShipperID
      ProductID
      ProductNo
      OrderDate
      RequiredDate
      Freight
      SalesTax
      TransactStatus
      InvoiceAmount
      PaymentDate
      Qty
      ProductName
      UnitPrice
      ProductImage
      PriceID
      UnitPrice
      ProductPrices
      ProductDesc
    }
  }
`
export const UPDATE_CART = gql`
  mutation UpdateCart($ProductID: ID, $Qty: Int, $PriceID: ID) {
    updatecart(ProductID: $ProductID, Qty: $Qty, PriceID: $PriceID) {
      id
      OrderID
      UserID
      ShipperID
      ProductID
      ProductNo
      OrderDate
      RequiredDate
      Freight
      SalesTax
      TransactStatus
      InvoiceAmount
      PaymentDate
      Qty
      ProductName
      UnitPrice
      ProductImage
      PriceID
      UnitPrice
      ProductPrices
      ProductDesc
    }
  }
`
export const REMOVE_CART = gql`
  mutation RemoveCart($id: ID, $PriceID: ID) {
    removecart(id: $id, PriceID: $PriceID) {
      id
      PriceID
    }
  }
`

export const RESET_CART = gql`
  mutation ResetCart {
    resetcart {
      id
      OrderID
      UserID
      ShipperID
      ProductID
      ProductNo
      OrderDate
      RequiredDate
      Freight
      SalesTax
      TransactStatus
      InvoiceAmount
      PaymentDate
      Qty
      ProductName
      UnitPrice
      ProductImage
      PriceID
      UnitPrice
      ProductPrices
      ProductDesc
    }
  }
`
